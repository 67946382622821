import { useNavigate, useParams } from 'react-router-dom';
import AvoidanceAssessmentForm from '../Components/AvoidanceAssessmentForm';
import AvoidanceAssessmentConditions from '../Components/AvoidanceAssessmentConditions';
import { getAvoidanceAssessment } from '../Utils/queries';
import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '../Context/AppContext';
import { Calendar, SkipForward } from 'react-feather';
import { getWeekNumber } from '../Utils/helpers';
import Button from '../Components/Button';
import NoSessionSet from '../Components/NoSessionSet';
import Loading from '../Components/Loading';

export default function AvoidanceAssessmentPage() {
  const { instance } = useParams<{ instance: string }>();
  const { appData } = useAppContext();
  const navigate = useNavigate();

  const {
    isLoading,
    error,
    data: avoidanceAssessment,
  } = useQuery({
    queryKey: ['avoidance-assessment', instance],
    queryFn: () => getAvoidanceAssessment(instance!),
  });

  if (isLoading) {
    return <Loading />;
  }

  if (error instanceof Error) {
    return <span>Error: {error.message}</span>;
  }

  if (!appData?.session) {
    return <NoSessionSet />;
  } else {
    // Special Case: If this is AA1, make sure the patient didn't do AA1 in the previous session
    // If they did, skip to Question 3
    if (instance === 'AA1') {
      const prevSession = appData?.patient?.sessions?.slice(-2, -1)[0];
      if (
        prevSession?.avoidanceAssessmentResponses?.find(
          (r) => r?.instance === 'AA1'
        )
      ) {
        navigate('/question/3');
      }
    }

    const d = getWeekNumber(new Date(appData?.session?.sessionDate));

    let patientType: 'continuous' | 'episodic' | 'both' = 'continuous';
    let AATitle: string = 'Avoidance Assessment';
    if (
      appData?.patient?.sessions?.flatMap((s) => s.continuousSymptoms).length! >
        0 &&
      appData?.patient?.sessions?.flatMap((s) => s.episodicSymptoms).length! ===
        0
    ) {
      patientType = 'continuous';
      AATitle = 'Avoidance Assessment for Continuous Symptoms';
    } else if (
      appData?.patient?.sessions?.flatMap((s) => s.episodicSymptoms).length! >
        0 &&
      appData?.patient?.sessions?.flatMap((s) => s.continuousSymptoms)
        .length! === 0
    ) {
      patientType = 'episodic';
      AATitle = 'Avoidance Assessment for Episodic Symptoms';
    } else if (
      appData?.patient?.sessions?.flatMap((s) => s.episodicSymptoms).length! >
        0 &&
      appData?.patient?.sessions?.flatMap((s) => s.continuousSymptoms).length! >
        0
    ) {
      patientType = 'both';
      AATitle =
        'Avoidance Assessment for Both Continuous and Episodic Symptoms';
    }

    return (
      <main className="flex w-full flex-col items-center gap-10 px-4 font-figtree text-dark-blue">
        <div className="flex w-full items-center justify-between gap-4 rounded-2xl bg-white p-6 shadow-xl">
          <h1 className="text-2xl font-semibold">
            Session {appData?.session?.sessionNumber} &bull; Patient{' '}
            {appData?.patient?.patientId}-
            {[true, 'true', undefined].includes(appData?.patient?.isChild)
              ? 'C'
              : 'A'}
          </h1>
          <div className="flex items-center gap-2 text-sm text-grey">
            <Calendar size={18} />
            {d[0]}, week {d[1]}
          </div>
        </div>

        <div className="flex flex-col gap-8 rounded-t-xl bg-white p-8 shadow-xl shadow-slate-500">
          <div className="flex w-full justify-end">
            {avoidanceAssessment?.skipQuestion && (
              <Button
                type="button"
                onClick={() =>
                  navigate(`/question/${avoidanceAssessment?.skipQuestion}`)
                }
                variant={'dark'}
              >
                Skip to Question {avoidanceAssessment?.skipQuestion}{' '}
                <SkipForward size={16} />
              </Button>
            )}
          </div>

          <div className="flex w-full flex-col items-center gap-4 rounded-lg bg-lighter-salmon bg-opacity-50 px-12 py-6 text-center">
            <h2 className="text-balance text-2xl font-bold [text-wrap:balance]">
              {AATitle}
            </h2>
          </div>

          {avoidanceAssessment && (
            <>
              <AvoidanceAssessmentForm
                avoidanceAssessment={avoidanceAssessment!}
                patientType={patientType}
              />
              {appData?.debug && (
                <AvoidanceAssessmentConditions
                  avoidanceAssessment={avoidanceAssessment!}
                />
              )}
            </>
          )}
        </div>
      </main>
    );
  }
}
