import { GetTokenSilentlyVerboseResponse } from '@auth0/auth0-spa-js';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
export const axiosWithAuth = axios.create();

axiosWithAuth.interceptors.response.use(
  (response) => {
    return response;
  },
  (error: unknown) => {
    if (error instanceof AxiosError) {
      switch (error.response?.status) {
        default:
          return Promise.reject(error);
      }
    }
    return Promise.reject(error);
  }
);

export const authInterceptor = (
  getAccessTokenSilently: () => Promise<
    GetTokenSilentlyVerboseResponse | string
  >
) => {
  axiosWithAuth.interceptors.request.use(async (config: AxiosRequestConfig) => {
    if (!config.headers) return;
    const token = await getAccessTokenSilently();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  });
};
