import { useForm } from 'react-hook-form';
import { AppData, useAppContext } from '../Context/AppContext';
import { PlusCircle, RotateCcw } from 'react-feather';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getSymptoms } from '../Utils/queries';
import { Symptom } from '../Types/symptom';
import SubmitAndClearButtons from './SubmitAndClearButtons';
import { ContinuousSymptom } from '../Types/continuous-symptom';
import { EpisodicSymptom } from '../Types/episodic-symptom';
import {
  addModules,
  addSession,
  addSymptoms,
  updateHadFirstIncreasedSymptoms,
  updatePatientBoosterStart,
} from '../Utils/mutations';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { Session } from '../Types/session';
import { getLastSunday } from '../Utils/helpers';

export default function ReturnSymptomsForm() {
  const { appData, setStateAndLocalStorage } = useAppContext();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm();

  const navigate = useNavigate();

  const {
    isLoading,
    error,
    data: symptoms,
  } = useQuery({
    queryKey: ['symptoms'],
    queryFn: getSymptoms,
  });

  const previousEpisodicSymptoms =
    appData?.patient?.sessions?.flatMap(
      (session) => session.episodicSymptoms
    ) ?? [];

  const previousContinuousSymptoms =
    appData?.patient?.sessions?.flatMap(
      (session) => session.continuousSymptoms
    ) ?? [];

  const previousSymptoms = [
    ...previousEpisodicSymptoms,
    ...previousContinuousSymptoms,
  ];

  const symptomMutation = useMutation({
    mutationFn: (params: {
      continuousSymptoms: ContinuousSymptom[];
      episodicSymptoms: EpisodicSymptom[];
      sessionId: string;
    }) =>
      addSymptoms(
        params.sessionId,
        params.continuousSymptoms,
        params.episodicSymptoms
      ),
    onSuccess: async (res, params) => {
      const currentSymptoms = [
        ...params.continuousSymptoms,
        ...params.episodicSymptoms,
      ];

      // Special Case: The first time the patient reports new or increased symptoms,
      if (appData?.patient?.hadFirstIncreasedSymptoms !== true) {
        const increasedSeverityCount = currentSymptoms.filter(
          (symptom) => symptom.increasedSeverity
        ).length;

        if (
          increasedSeverityCount > 0 ||
          currentSymptoms.length > previousSymptoms.length
        ) {
          // add Extinction Burst module (113) & Review Plan with Make Your Own Plan (114) module
          moduleMutation.mutate({
            modules: [113, 114],
            params,
          });
        } else {
          navigateToNextStep(params);
        }
      } else {
        navigateToNextStep(params);
      }
    },
  });

  const moduleMutation = useMutation({
    mutationFn: (variables: {
      modules: number[];
      params: {
        continuousSymptoms: ContinuousSymptom[];
        episodicSymptoms: EpisodicSymptom[];
        sessionId: string;
      };
    }) => addModules(appData!.session!._id, variables.modules),
    onSuccess: async (data, variables) => {
      let newModules = new Set(appData?.session?.modules || []);

      variables.modules.forEach((module) => newModules.add(module));

      await updateHadFirstIncreasedSymptoms(appData!.patient!._id, true);

      setStateAndLocalStorage({
        ...appData,
        patient: {
          ...appData?.patient,
          hadFirstIncreasedSymptoms: true,
        },
        session: {
          ...appData?.session,
          modules: Array.from(newModules),
        },
      } as AppData);

      navigateToNextStep(variables.params);
    },
  });

  const sessionMutation = useMutation({
    mutationFn: (data: { newSession: Partial<Session>; formData: any }) =>
      addSession(data.newSession),
    onSuccess: async (res, data) => {
      const continuousSymptoms =
        Object.entries(data.formData)
          .filter(([key, value]) =>
            (value as string).toString().startsWith('continuous - ')
          )
          .map(([key, value]) => ({
            _id: key,
            name: (value as string).replace('continuous - ', ''),
            increasedSeverity:
              data.formData[`${key}-increasedSeverity`] === 'true'
                ? true
                : undefined,
          })) ?? [];

      const episodicSymptoms =
        Object.entries(data.formData)
          .filter(([key, value]) =>
            (value as string).toString().startsWith('episodic - ')
          )
          .map(([key, value]) => ({
            _id: key,
            name: (value as string).replace('episodic - ', ''),
            increasedSeverity:
              data.formData[`${key}-increasedSeverity`] === 'true'
                ? true
                : undefined,
          })) ?? [];

      setStateAndLocalStorage({
        ...appData,
        session: {
          ...appData?.session,
          continuousSymptoms,
          episodicSymptoms,
          _id: res.sessionId,
        },
        patient: {
          ...appData?.patient,
          sessions: [
            ...(appData?.patient?.sessions ?? []),
            {
              ...appData?.session,
              continuousSymptoms,
              episodicSymptoms,
              _id: res.sessionId,
            },
          ],
        },
      } as AppData);

      symptomMutation.mutate({
        continuousSymptoms,
        episodicSymptoms,
        sessionId: res.sessionId,
      });
    },
  });

  const boosterModuleMutation = useMutation({
    mutationFn: () => addModules(appData!.session!._id, [120]),
    onSuccess: () => {
      setStateAndLocalStorage({
        ...appData,
        session: {
          ...appData?.session,
          modules: [...(appData?.session?.modules ?? []), 120],
        },
      } as AppData);
      navigate('/module-review');
    },
  });

  const boosterMutation = useMutation({
    mutationFn: (data: {
      patientId: string;
      boosterStart: number;
      formData: any;
    }) => updatePatientBoosterStart(data.patientId, data.boosterStart),
    onSuccess: (_, data) => {
      sessionMutation.mutate({
        newSession: {
          sessionNumber: appData!.patient!.sessions.length + 1,
          patientId: appData!.patient!._id,
          sessionDate: getLastSunday(),
        },
        formData: data.formData,
      });
    },
  });

  const formSubmit = (data: any) => {
    appData?.patient?.boosterStart
      ? boosterMutation.mutate({
          patientId: appData!.patient!._id,
          boosterStart: appData!.patient!.boosterStart,
          formData: data,
        })
      : sessionMutation.mutate({
          newSession: {
            sessionNumber: appData!.patient!.sessions.length + 1,
            patientId: appData!.patient!._id,
            sessionDate: getLastSunday(),
          },
          formData: data,
        });
  };

  const navigateToNextStep = (params: {
    continuousSymptoms: ContinuousSymptom[];
    episodicSymptoms: EpisodicSymptom[];
    sessionId: string;
  }) => {
    if (params.episodicSymptoms.length > 0) {
      // episodic symptoms
      navigate('/intake/episodic');
    } else if (
      // IF this is the first Booster session (without episodic symptoms)
      appData?.session?.sessionNumber === appData?.patient?.boosterStart
    ) {
      // add the Booster Start module () and go to Module Review
      boosterModuleMutation.mutate();
    } else if (
      // IF the session is after the first Booster session (without episodic symptoms)
      appData?.patient?.boosterStart &&
      appData?.session?.sessionNumber! > appData?.patient?.boosterStart
    ) {
      // IF they have NO symptoms, go to question 22
      // IF they have symptoms, go to question 20
      if (params.continuousSymptoms.length > 0) {
        navigate('/question/20');
      } else {
        const firstBoosterNoSymptoms = !appData?.patient?.sessions
          .slice(appData?.patient?.boosterStart, -1)
          .some(
            (session) =>
              session.continuousSymptoms.length === 0 &&
              session.episodicSymptoms.length === 0
          );
        if (firstBoosterNoSymptoms) {
          navigate('/avoidance-assessment/AA4');
        } else {
          navigate('/question/22');
        }
      }
    } else if (params.continuousSymptoms.length > 0) {
      // continuous symptoms
      navigate('/question/1');
    } else {
      // no symptoms
      const firstTimeNoSymptoms = !appData?.patient?.sessions
        .slice(0, -1)
        .some(
          (session) =>
            session.continuousSymptoms.length === 0 &&
            session.episodicSymptoms.length === 0
        );
      if (firstTimeNoSymptoms) {
        navigate('/avoidance-assessment/AA2');
      } else {
        navigate('/question/8');
      }
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error instanceof Error) {
    return <main>Error: {error.message}</main>;
  }

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <div className="flex flex-col items-center gap-4 rounded-lg bg-lighter-salmon bg-opacity-50 px-12 py-6 text-center">
        <h3 className="text-lg font-bold">Question</h3>
        <h2 className="text-balance text-3xl font-bold [text-wrap:balance]">
          What were the patient’s primary symptoms since their last visit (e.g.
          If the patient experiences loss of consciousness as part of their
          seizure-like symptoms, only choose seizure-like symptoms below)?
        </h2>
      </div>
      <div className="flex items-center gap-2 py-8">
        <RotateCcw />
        <h2 className="text-xl font-semibold">Previously Reported</h2>
      </div>
      <div className="mt-8 flex items-end justify-end gap-4 px-2 text-center">
        <label className="w-20">Increased Severity?</label>
        <label className="w-16">Episodic</label>
        <label className="w-24">Continuous</label>
        <label className="w-8">No</label>
      </div>
      <ol className="list-inside list-decimal text-xl font-semibold marker:relative">
        {symptoms
          ?.filter((symptom: Symptom) =>
            previousSymptoms.map((s) => s._id).includes(symptom._id)
          )
          .map((symptom: Symptom, i: number) => (
            <li
              key={symptom._id}
              className="flex items-center gap-4 border-b border-grey px-2 py-4 last:border-0"
            >
              <div className="font-bold">{i + 1}.</div>
              <div className="flex-1">{symptom.name}</div>
              <label className="w-20 text-center">
                <input
                  type="checkbox"
                  {...register(`${symptom._id}-increasedSeverity`)}
                  value="true"
                />
              </label>
              <label className="w-16 text-center">
                <input
                  type="radio"
                  {...register(symptom._id)}
                  value={`episodic - ${symptom.name}`}
                />
              </label>
              <label className="w-24 text-center">
                <input
                  type="radio"
                  {...register(symptom._id)}
                  value={`continuous - ${symptom.name}`}
                />
              </label>
              <label className="w-8 text-center">
                <input
                  type="radio"
                  {...register(symptom._id)}
                  value={`no`}
                  defaultChecked
                />
              </label>
            </li>
          ))}
      </ol>
      <div className="flex items-center gap-2 py-8">
        <PlusCircle />
        <h2 className="text-xl font-semibold">New Symptoms</h2>
      </div>
      <div className="mt-8 flex items-end justify-end gap-4 px-2 text-center">
        <label className="w-16">Episodic</label>
        <label className="w-24">Continuous</label>
        <label className="w-8">No</label>
      </div>
      <ol className="list-inside list-decimal text-xl font-semibold marker:relative">
        {symptoms
          ?.filter(
            (symptom: Symptom) =>
              !previousSymptoms.map((s) => s._id).includes(symptom._id)
          )
          .map((symptom: Symptom, i: number) => (
            <li
              key={symptom._id}
              className="flex items-center gap-4 border-b border-grey px-2 py-4 last:border-0"
            >
              <div className="font-bold">{i + 1}.</div>
              <div className="flex-1">{symptom.name}</div>
              <label className="w-16 text-center">
                <input
                  type="radio"
                  {...register(symptom._id)}
                  value={`episodic - ${symptom.name}`}
                />
              </label>
              <label className="w-24 text-center">
                <input
                  type="radio"
                  {...register(symptom._id)}
                  value={`continuous - ${symptom.name}`}
                />
              </label>
              <label className="w-8 text-center">
                <input
                  type="radio"
                  {...register(symptom._id)}
                  value={`no`}
                  defaultChecked
                />
              </label>
            </li>
          ))}
      </ol>
      <SubmitAndClearButtons disabled={isSubmitting || isLoading || !isValid}>
        Save Responses + Continue
      </SubmitAndClearButtons>
    </form>
  );
}
