import clsx from 'clsx';

interface ButtonProps {
  children: React.ReactNode;
  variant: 'primary' | 'secondary' | 'dark';
  disabled?: boolean;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  type?: 'button' | 'submit' | 'reset';
  size?: 'sm' | 'md' | 'lg';
  rest?: React.HTMLProps<HTMLButtonElement>;
}
export default function Button({
  children,
  variant,
  disabled,
  onClick,
  type,
  size,
  ...rest
}: ButtonProps) {
  return (
    <button
      className={clsx(
        'flex items-center justify-center gap-2 rounded-full border font-figtree print:hidden',
        {
          'bg-dark-blue text-light hover:bg-link-blue focus:bg-light-blue focus:text-dark-blue active:bg-blue active:text-dark-blue disabled:bg-grey':
            variant === 'primary',
          'border-blue bg-white text-dark-blue hover:bg-light-blue focus:bg-blue active:bg-lighter-blue active:text-light disabled:text-grey':
            variant === 'secondary',
          'bg-salmon text-dark-blue hover:bg-light-salmon focus:bg-light active:bg-red active:text-light disabled:bg-grey disabled:text-light':
            variant === 'dark',
          'text-2xl': size === 'lg',
          'text-base': size === 'md',
          'px-4 py-2 text-base normal-case': size === 'sm',
          'px-6 py-4 font-bold uppercase': size !== 'sm',
        }
      )}
      disabled={disabled}
      onClick={onClick}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
}
