import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from '../Context/AppContext';
import { Link } from 'react-router-dom';

export default function StatusBar() {
  const { appData, setStateAndLocalStorage } = useAppContext();
  const { isAuthenticated, isLoading, user } = useAuth0();

  const handleChange = (event: { target: { checked: boolean } }) => {
    setStateAndLocalStorage({
      patient: appData?.patient ?? null,
      session: appData?.session ?? null,
      debug: event.target.checked,
    });
  };

  if (!isLoading && isAuthenticated) {
    if (user?.['https://app.reactfnd.health/roles']?.includes('admin')) {
      return (
        <div className="flex gap-4 rounded-b-xl bg-white px-4 py-2 text-sm text-slate-800 shadow shadow-slate-400 print:hidden">
          <h3 className="mr-auto">
            <Link to="/admin" className="hover:underline">
              Admin
            </Link>
          </h3>
          <p>
            <label htmlFor="debugCheckbox">Debug:</label>{' '}
            <input
              id="debugCheckbox"
              type="checkbox"
              defaultChecked={appData?.debug ?? false}
              onChange={handleChange}
              className="!h-4 !w-4 !border-slate-400"
            />
          </p>
          <p>
            Patient:{' '}
            <span className="font-semibold">
              {appData?.patient?.patientId ?? 'none'}
            </span>
          </p>
          <p>
            Session:{' '}
            <span className="font-semibold">
              {appData?.session?.sessionNumber ?? 'none'}
            </span>
          </p>
        </div>
      );
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
}
