import { useAuth0 } from '@auth0/auth0-react';
import Button from '../Components/Button';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getAppHomePage } from '../Utils/queries';
import Loading from '../Components/Loading';
import { PortableText } from '@portabletext/react';

const SideBar = () => {
  const { loginWithRedirect, isAuthenticated, user, isLoading } = useAuth0();
  const navigate = useNavigate();

  return (
    <>
      {!isLoading && (
        <div
          className={clsx(
            'relative flex w-1/3 flex-col gap-28 rounded-3xl bg-lighter-blue p-8 text-center text-white',
            "after:absolute after:bottom-0 after:left-0 after:right-0 after:h-32 after:rounded-t-[3rem] after:bg-[#B0DCE5] after:bg-opacity-20 after:content-['']"
          )}
        >
          {isAuthenticated ? (
            <>
              <div>
                <h3 className="font-dm_serif text-3xl">Welcome back,</h3>
                <h3 className="truncate font-dm_serif text-3xl">
                  {user?.name}!
                </h3>
                <p>Start your session</p>
              </div>

              <Button
                variant="dark"
                size="lg"
                onClick={() => navigate('/dashboard')}
              >
                Start
              </Button>
            </>
          ) : (
            <>
              <div>
                <h3 className="font-dm_serif text-3xl">Welcome back!</h3>
                <p>Log in to your account</p>
              </div>

              <Button
                variant="dark"
                size="lg"
                onClick={() => loginWithRedirect()}
              >
                <div className="flex flex-col">
                  <div>Log In</div>
                  <div className="text-base">or Sign Up</div>
                </div>
              </Button>
            </>
          )}
          <div className="opacity-70">
            <p>
              By continuing, you agree to ReACT's{' '}
              <a
                href="https://reactfnd.health/terms-of-service"
                className="underline underline-offset-4"
              >
                Terms of Use
              </a>
              .
            </p>
          </div>

          <div className="z-20">
            <h4 className="font-dm_serif text-2xl">Interested in ReACT?</h4>
            <p className="text-lg">
              <a
                href="https://reactfnd.health/#contactForm"
                className="underline underline-offset-4"
              >
                Contact Us
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default function HomePage() {
  const {
    isLoading,
    error,
    data: home,
  } = useQuery({
    queryKey: ['appHomePage'],
    queryFn: getAppHomePage,
  });

  if (isLoading) {
    return <Loading />;
  }

  if (error instanceof Error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <main className="flex items-start gap-8 rounded-xl bg-white p-8 font-figtree text-dark-blue shadow-xl shadow-slate-500">
      <div className="flex-1">
        <h2 className="text-center font-dm_serif text-5xl leading-normal">
          {home?.title}
        </h2>
        <div className="flex justify-center">
          <img src={home?.image} alt="ReACT app" />
        </div>
        <div className="portable-text text-xl !leading-tight">
          <PortableText value={home?.body!} />
        </div>
      </div>
      <SideBar />
    </main>
  );
}
