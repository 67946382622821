import { Dialog, Listbox } from '@headlessui/react';
import { ChevronDown, Plus, X } from 'react-feather';
import SubmitAndClearButtons from './SubmitAndClearButtons';
import { useForm } from 'react-hook-form';
import { Gain } from '../Pages/RetrainingGains';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { AppData, useAppContext } from '../Context/AppContext';
import { getRetrainingGains } from '../Utils/queries';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { addRetrainingGain } from '../Utils/mutations';
import Loading from './Loading';

const emptyGain: Partial<Gain> = { _id: '', body: '' };

export default function AddGainModal({
  isOpen,
  setIsOpen,
  retrainingGains,
  setRetrainingGains,
}: {
  isOpen: boolean;
  setIsOpen: (x: boolean) => void;
  retrainingGains: Partial<Gain>[];
  setRetrainingGains: (x: Partial<Gain>[]) => void;
}) {
  const { appData, setStateAndLocalStorage } = useAppContext();

  const queryClient = useQueryClient();

  const { isLoading, error, data } = useQuery({
    queryKey: ['retrainingGains'],
    queryFn: getRetrainingGains,
  });

  const mutation = useMutation({
    mutationFn: (data: { gain: string; session: number }) =>
      addRetrainingGain(appData?.patient?._id!, data.gain, data.session),
    onSuccess: async (res, data) => {
      queryClient.invalidateQueries({ queryKey: ['patient'] });
      setStateAndLocalStorage({
        ...appData,
        patient: {
          ...appData?.patient,
          retrainingGains: res.patient.retrainingGains,
        },
      } as AppData);
      setRetrainingGains(res.patient.retrainingGains);
      setIsOpen(false);
      setValue('gain', '');
    },
  });

  const [selectedCannedGain, setSelectedCannedGain] =
    useState<Partial<Gain>>(emptyGain);

  const { register, handleSubmit, setValue } = useForm();

  const formSubmit = (data: any) => {
    mutation.mutate({
      gain: data.gain,
      session: appData?.session?.sessionNumber!,
    });
  };

  useEffect(() => {
    if (selectedCannedGain) {
      setValue('gain', selectedCannedGain.body);
    }
  }, [selectedCannedGain, setValue]);

  if (isLoading) {
    return <Loading />;
  }

  if (error instanceof Error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      className="relative z-50 font-figtree text-dark-blue"
    >
      <div className="fixed inset-0 bg-black/50" aria-hidden="true" />
      <div className="fixed inset-0 flex w-screen items-center justify-center p-4">
        <Dialog.Panel className="flex w-full max-w-4xl flex-col gap-4 rounded-xl bg-white p-8 shadow-xl">
          <div className="flex items-center justify-between gap-4 text-2xl font-semibold">
            <Dialog.Title className="flex items-center gap-2">
              <div className="rounded-full bg-light-salmon p-1.5">
                <Plus />
              </div>
              <span>Add a Gain</span>
            </Dialog.Title>
            <button
              className="rounded-lg bg-grey p-4"
              onClick={() => setIsOpen(false)}
              type="button"
            >
              <X />
            </button>
          </div>
          <Dialog.Description className="text-lg">
            {data?.modalInstructions}
          </Dialog.Description>
          <form
            onSubmit={handleSubmit(formSubmit)}
            className="flex flex-col gap-4"
          >
            <div className="relative">
              <Listbox
                value={selectedCannedGain}
                onChange={setSelectedCannedGain}
              >
                <Listbox.Button className="flex w-full items-center justify-between rounded-xl border border-dark-blue p-4 text-xl font-semibold">
                  Choose a common Gain
                  <ChevronDown />
                </Listbox.Button>
                <Listbox.Options className="absolute z-10 max-h-[400px] w-full overflow-y-auto rounded-b-xl bg-white py-4 shadow-xl shadow-slate-500">
                  {data?.gainCategories.map((category) => (
                    <div key={category._id}>
                      <h5 className="py-4 text-center text-xl font-bold underline underline-offset-4">
                        {category.name}
                      </h5>
                      {data?.gains
                        .filter((gain) => gain.category === category._id)
                        .map((gain) => (
                          <Listbox.Option
                            key={gain._id}
                            value={gain}
                            className={clsx(
                              `ui-active:bg-blue ui-not-active:bg-transparent`,
                              `flex cursor-default select-none items-center gap-4 px-8 py-3 text-lg`,
                              `ui-selected:font-bold ui-not-selected:font-semibold`
                            )}
                          >
                            {gain.body}
                          </Listbox.Option>
                        ))}
                    </div>
                  ))}
                </Listbox.Options>
              </Listbox>
            </div>
            <div className="mb-20">
              <textarea
                {...register('gain', { required: true })}
                className="w-full p-4 text-xl font-semibold"
                placeholder="Add your own Gain."
                rows={5}
              ></textarea>
            </div>
            <p className="text-base">{data?.modalWarning}</p>
            <SubmitAndClearButtons disabled={false}>Save</SubmitAndClearButtons>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
