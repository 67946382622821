import { useAuth0 } from '@auth0/auth0-react';

export default function ProtectedPage() {
  const { loginWithRedirect } = useAuth0();

  return (
    <main className="flex flex-col items-center justify-center gap-4 py-20 font-figtree text-dark-blue">
      <h1 className="text-3xl font-semibold">Protected Page</h1>
      <p>
        Please{' '}
        <button
          type="button"
          onClick={() => loginWithRedirect()}
          className="text-slate-800 hover:underline"
        >
          log in
        </button>{' '}
        to access this page.
      </p>
    </main>
  );
}
