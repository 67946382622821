import { Link } from 'react-router-dom';
import { AvoidanceAssessment } from '../Types/avoidance-assessment';
import { Question } from '../Types/question';
import { getAvoidanceAssessments, getQuestions } from '../Utils/queries';
import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '../Context/AppContext';
import { CheckCircle, Circle, File } from 'react-feather';
import NoSessionSet from '../Components/NoSessionSet';
import Loading from '../Components/Loading';
import { useAuth0 } from '@auth0/auth0-react';

export default function QuestionsPage() {
  const { isAuthenticated, isLoading, user } = useAuth0();

  const { appData } = useAppContext();

  const {
    isLoading: questionsIsLoading,
    error: questionsError,
    data: questions,
  } = useQuery({
    queryKey: ['questions'],
    queryFn: getQuestions,
  });

  const {
    isLoading: avoidanceAssessmentsIsLoading,
    error: avoidanceAssessmentsError,
    data: avoidanceAssessments,
  } = useQuery({
    queryKey: ['avoidance-assessments'],
    queryFn: getAvoidanceAssessments,
  });

  if (questionsIsLoading || avoidanceAssessmentsIsLoading) {
    return <Loading />;
  }

  if (questionsError instanceof Error) {
    return <span>Error: {questionsError.message}</span>;
  }

  if (avoidanceAssessmentsError instanceof Error) {
    return <span>Error: {avoidanceAssessmentsError.message}</span>;
  }

  const moduleCount = appData?.session?.modules?.length ?? 0;

  if (
    !isLoading &&
    isAuthenticated &&
    !questionsIsLoading &&
    !avoidanceAssessmentsIsLoading
  ) {
    if (user?.['https://app.reactfnd.health/roles']?.includes('admin')) {
      if (!appData?.session) {
        return <NoSessionSet />;
      } else {
        return (
          <main className="rounded-xl bg-white p-8 shadow-xl shadow-slate-500">
            <div className="flex gap-4">
              <div className="flex flex-col items-center gap-4">
                <h1 className="text-xl font-semibold">Questions</h1>
                <ul className="flex flex-col gap-4">
                  {questions?.map((question: Question) => {
                    const answered = appData?.session?.responses?.some((r) => {
                      return r.question === question.key;
                    });

                    return (
                      <Link
                        to={`/question/${question.key}`}
                        key={question._id}
                        className="rounded-2xl bg-yellow-300 p-4 transition-shadow hover:bg-yellow-400 hover:shadow"
                      >
                        <li className="flex justify-between gap-4">
                          <div>
                            <strong>{question.key}</strong>: {question.question}
                          </div>
                          <div>{answered ? <CheckCircle /> : <Circle />}</div>
                        </li>
                      </Link>
                    );
                  })}
                </ul>
              </div>
              <div className="flex flex-col items-center gap-4">
                <h1 className="text-xl font-semibold">Session Intake</h1>
                <Link
                  to={`/intake`}
                  className="relative w-full rounded-2xl bg-green-600 px-4 py-10 text-center font-semibold text-white transition-shadow hover:bg-green-700 hover:shadow"
                >
                  Session Intake
                </Link>
                <h1 className="mt-8 text-xl font-semibold">
                  Avoidance Assessments
                </h1>
                <ul className="flex flex-col gap-4">
                  {avoidanceAssessments?.map(
                    (assessment: AvoidanceAssessment) => {
                      const thisInstancesAnswers =
                        appData?.session?.avoidanceAssessmentResponses?.find(
                          (response) =>
                            response.instance === assessment.instance
                        );

                      const answered = thisInstancesAnswers !== undefined;

                      return (
                        <Link
                          to={`/avoidance-assessment/${assessment.instance}`}
                          key={assessment._id}
                          className="rounded-2xl bg-green-300 p-4 transition-shadow hover:bg-green-400 hover:shadow"
                        >
                          <li className="flex justify-between gap-4">
                            <div>
                              <strong>{assessment.instance}</strong>
                            </div>
                            <div>{answered ? <CheckCircle /> : <Circle />}</div>
                          </li>
                        </Link>
                      );
                    }
                  )}
                </ul>
                <h1 className="mt-8 text-xl font-semibold">Module Review</h1>
                <Link
                  to={`/module-review`}
                  className="bg-blue-300 hover:bg-blue-400 relative w-full rounded-2xl px-4 py-20 text-center transition-shadow hover:shadow"
                >
                  Module Review
                  <div className="text-blue-900 absolute bottom-0 right-0 flex items-center gap-2 p-4 font-semibold">
                    {moduleCount} <File />
                  </div>
                </Link>
              </div>
            </div>
          </main>
        );
      }
    } else {
      return <>You are not an admin.</>;
    }
  } else {
    return <></>;
  }
}
