import { Link, useNavigate } from 'react-router-dom';
import PatientSelector from '../Components/PatientSelector';
import PatientsList from '../Components/PatientsList';
import SessionSelector from '../Components/SessionSelector';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import {
  getDashboardStats,
  getPatient,
  getPatients,
  getSession,
} from '../Utils/queries';
import ClearSessionButton from '../Components/ClearSessionButton';
import MenuButton from '../Components/MenuButton';
import {
  ChevronLeft,
  ChevronRight,
  Clipboard,
  Compass,
  Play,
  Plus,
  RotateCw,
  Smile,
  ThumbsUp,
} from 'react-feather';
import IntakeButton from '../Components/IntakeButton';
import Button from '../Components/Button';
import { AppData, useAppContext } from '../Context/AppContext';
import Loading from '../Components/Loading';
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { Pagination } from 'react-headless-pagination';
import { getLastSunday } from '../Utils/helpers';
import useDebounce from '../Utils/useDebounce';

const PATIENTS_PER_PAGE = 10;

export default function DashboardPage() {
  const limit = PATIENTS_PER_PAGE;

  const [page, setPage] = useState(0);

  const [query, setQuery] = useState('');

  const debouncedQuery = useDebounce(query, 500);

  const handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
    setPage(0);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  const {
    isLoading,
    error,
    data: patientsData,
  } = useQuery({
    queryKey: ['patients', { page, limit, query: debouncedQuery }],
    queryFn: () => getPatients(page + 1, limit, debouncedQuery),
  });

  const { data: stats } = useQuery({
    queryKey: ['stats'],
    queryFn: getDashboardStats,
  });

  const navigate = useNavigate();

  const { appData, setStateAndLocalStorage } = useAppContext();

  const queryClient = useQueryClient();

  useEffect(() => {
    if (!appData?.debug && (appData?.patient || appData?.session)) {
      setStateAndLocalStorage({
        ...appData,
        patient: null,
        session: null,
      } as AppData);
    }
  });

  if (isLoading) {
    return <Loading />;
  }

  if (error instanceof Error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <main className="mb-20 flex flex-col gap-8 font-figtree text-dark-blue">
      <div className="flex gap-8">
        <div className="flex-1 rounded-xl bg-light-blue p-4 shadow-lg">
          <h3 className="text-4xl font-bold">{stats?.totalPatientsCount}</h3>
          <p>No. of patients currently in care</p>
        </div>
        <div className="flex-1 rounded-xl bg-lighter-salmon p-4 shadow-lg">
          <h3 className="text-4xl font-bold">
            {stats?.graduatedPatientsCount}
          </h3>
          <p>No. of patients graduated</p>
        </div>
        <div className="flex-1 rounded-xl bg-white p-4 shadow-lg">
          <h3 className="text-4xl font-bold">{stats?.totalSessionsCount}</h3>
          <p>Total sessions completed</p>
        </div>
      </div>
      <div className="flex w-full items-center justify-end gap-4">
        <h2 className="mr-auto text-2xl font-semibold">Patient Activity</h2>
        <input
          type="search"
          placeholder="Search patients"
          className="rounded-full border-none px-8 py-3.5 text-lg font-semibold focus:ring focus:ring-inset focus:ring-dark-blue"
          value={query}
          onChange={handleQueryChange}
        />
        <Button
          type="button"
          variant="primary"
          onClick={() => navigate('/patients/add')}
        >
          <Plus size={16} /> Intake New Patient
        </Button>
      </div>
      {patientsData?.patients?.length === 0 ? (
        <div className="flex min-h-[15rem] flex-col items-center justify-center gap-4">
          <h2 className="text-2xl font-semibold">No patients found</h2>
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          {patientsData?.patients?.map((patient) => {
            const lastSessionNumber =
              patient.sessions![patient?.sessions!.length - 1]?.sessionNumber ??
              0;
            const weeksSinceLastSession =
              Math.floor(
                (Date.now() -
                  new Date(
                    patient.sessions![patient?.sessions!.length - 1]
                      ?.sessionDate!
                  ).getTime()) /
                  (1000 * 60 * 60 * 24 * 7)
              ) ?? 0;
            return (
              <div
                key={patient._id}
                className={clsx(
                  'flex items-center justify-start gap-8 rounded-xl p-8 text-lg font-semibold shadow-lg',
                  {
                    'bg-lighter-salmon': patient.isGraduated,
                    'bg-white': !patient.isGraduated,
                  }
                )}
              >
                <div className="mr-auto flex w-1/5 flex-col">
                  <Link
                    to={`/patient/${patient._id}`}
                    className="hover:underline"
                  >
                    Patient {patient.patientId}-{patient?.isChild ? 'C' : 'A'}
                  </Link>
                  <div className="text-base font-normal">
                    {patient.isGraduated ? (
                      patient.boosterStart ? (
                        <span className="italic">In Booster</span>
                      ) : (
                        <span className="italic">Graduated</span>
                      )
                    ) : (
                      <>
                        {lastSessionNumber > 0 ? lastSessionNumber : 'No'}{' '}
                        Session
                        {lastSessionNumber !== 1 && 's'}
                      </>
                    )}
                  </div>
                  <div className="text-sm font-normal italic">
                    {weeksSinceLastSession > 0 ? (
                      <>
                        {weeksSinceLastSession} week
                        {weeksSinceLastSession > 1 && 's'} ago
                      </>
                    ) : (
                      <>this week</>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap gap-4">
                  {patient.isGraduated ? (
                    <Button
                      type="button"
                      variant="primary"
                      size="sm"
                      onClick={async () => {
                        const fullPatient = await getPatient(patient._id!);
                        const newSessionNumber =
                          fullPatient!.sessions?.length + 1;
                        const newSession = {
                          sessionNumber: newSessionNumber,
                          patientId: fullPatient!._id,
                          sessionDate: getLastSunday(),
                        };
                        fullPatient?.boosterStart
                          ? setStateAndLocalStorage({
                              ...appData,
                              patient: fullPatient,
                              session: newSession,
                            } as AppData)
                          : setStateAndLocalStorage({
                              ...appData,
                              patient: {
                                ...fullPatient,
                                boosterStart: newSessionNumber,
                              },
                              session: newSession,
                            } as AppData);
                        navigate('/intake');
                      }}
                    >
                      <div className="flex items-center gap-2">
                        <ThumbsUp />
                        Start Booster
                      </div>
                    </Button>
                  ) : (
                    <Button
                      type="button"
                      variant="dark"
                      size="sm"
                      onClick={async () => {
                        const fullPatient = await getPatient(patient._id!);
                        const newSession = {
                          sessionNumber: fullPatient!.sessions?.length + 1,
                          patientId: fullPatient!._id,
                          sessionDate: getLastSunday(),
                        };
                        setStateAndLocalStorage({
                          ...appData,
                          patient: fullPatient,
                          session: newSession,
                        } as AppData);
                        navigate('/intake');
                      }}
                      disabled={patient.isGraduated}
                    >
                      <div className="flex items-center gap-2">
                        <Play />
                        Start Session
                      </div>
                    </Button>
                  )}
                  <Button
                    type="button"
                    variant="secondary"
                    size="sm"
                    onClick={async () => {
                      const fullPatient = await getPatient(patient._id!);
                      const fullSession = await getSession(
                        fullPatient.sessions[fullPatient.sessions.length - 1]
                          ._id!
                      );
                      setStateAndLocalStorage({
                        patient: fullPatient,
                        session: fullSession,
                        debug: appData?.debug ?? false,
                      } as AppData);
                      navigate('/module-review');
                    }}
                    disabled={lastSessionNumber === 0}
                  >
                    <div className="flex items-center gap-2">
                      <Clipboard />
                      Module Review
                    </div>
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    size="sm"
                    onClick={async () => {
                      const fullPatient = await getPatient(patient._id!);
                      const fullSession = await getSession(
                        fullPatient.sessions[fullPatient.sessions.length - 1]
                          ._id!
                      );
                      setStateAndLocalStorage({
                        patient: fullPatient,
                        session: fullSession,
                        debug: appData?.debug ?? false,
                      } as AppData);
                      navigate('/module-review/the-plan');
                    }}
                    disabled={lastSessionNumber === 0}
                  >
                    <div className="flex items-center gap-2">
                      <Compass />
                      The Plan
                    </div>
                  </Button>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={async () => {
                      const fullPatient = await getPatient(patient._id!);
                      const fullSession = await getSession(
                        fullPatient.sessions[fullPatient.sessions.length - 1]
                          ._id!
                      );
                      setStateAndLocalStorage({
                        patient: fullPatient,
                        session: fullSession,
                        debug: appData?.debug ?? false,
                      } as AppData);
                      navigate('/module-review/retraining-gains');
                    }}
                    disabled={lastSessionNumber === 0}
                  >
                    <div className="flex items-center gap-2">
                      <Smile />
                      Retraining Gains
                    </div>
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      )}
      {Number(patientsData?.totalPages) > 1 && (
        <Pagination
          currentPage={page}
          totalPages={Number(patientsData?.totalPages ?? 1)}
          setCurrentPage={handlePageChange}
          truncableText="..."
          edgePageCount={2}
          middlePagesSiblingCount={2}
          className="flex justify-center gap-4 text-lg font-bold"
        >
          <Pagination.PrevButton className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-transparent hover:bg-white disabled:cursor-default disabled:opacity-50 disabled:hover:bg-transparent">
            <ChevronLeft />
          </Pagination.PrevButton>

          <nav className="flex flex-grow justify-center">
            <ul className="flex items-center gap-1">
              <Pagination.PageButton
                className="flex h-10 w-10 items-center justify-center rounded-full px-4 py-2"
                activeClassName="bg-dark-blue text-white"
                inactiveClassName="cursor-pointer bg-transparent hover:bg-white disabled:opacity-60"
              />
            </ul>
          </nav>

          <Pagination.NextButton className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-transparent hover:bg-white disabled:cursor-default disabled:opacity-50 disabled:hover:bg-transparent">
            <ChevronRight />
          </Pagination.NextButton>
        </Pagination>
      )}
      {appData?.debug && (
        <div className="flex flex-col items-center justify-center gap-4 bg-white p-8 shadow-xl shadow-slate-500">
          <>
            <div className="mb-12 flex w-full items-end justify-center gap-4 rounded-2xl bg-green-100 py-8">
              <button
                type="button"
                className="flex items-center gap-2 rounded-full border border-slate-600 bg-transparent px-4 py-2 font-semibold text-slate-600 hover:text-slate-800 hover:shadow disabled:opacity-60"
                onClick={async () => {
                  await queryClient.invalidateQueries({
                    queryKey: ['patients', { page, limit }],
                  });
                }}
                disabled={isLoading}
              >
                <RotateCw />
              </button>
              <PatientSelector patients={patientsData?.patients ?? []} />
              <SessionSelector />
              <IntakeButton />
              <MenuButton />
              <ClearSessionButton />
            </div>
            <PatientsList patients={patientsData?.patients ?? []} />
          </>
        </div>
      )}
    </main>
  );
}
