import { ContinuousSymptom } from '../Types/continuous-symptom';
import { EpisodicSymptom } from '../Types/episodic-symptom';
import { Patient } from '../Types/patient';
import { Session } from '../Types/session';
import { axiosWithAuth } from './axios';
import { getPatient, getSession } from './queries';

export const deleteSession = async (id: string) => {
  const { data } = await axiosWithAuth.delete(
    `${process.env.REACT_APP_API_ROOT}/sessions?id=${id}`
  );
  return data;
};

export const deletePatient = async (id: string) => {
  const { data } = await axiosWithAuth.delete(
    `${process.env.REACT_APP_API_ROOT}/patients?id=${id}`
  );
  return data;
};

export const addSession = async (session: Partial<Session>) => {
  const { data } = await axiosWithAuth.post(
    `${process.env.REACT_APP_API_ROOT}/sessions`,
    session
  );
  return data;
};

export const addPatient = async (patient: Partial<Patient>) => {
  const { data } = await axiosWithAuth.post(
    `${process.env.REACT_APP_API_ROOT}/patients`,
    patient
  );
  return data;
};

export const updatePatientBoosterStart = async (
  patientId: string,
  boosterStart: number
) => {
  const oldPatient = await getPatient(patientId);
  const newPatient = {
    ...oldPatient,
    boosterStart,
  };

  const { data } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/patients/${patientId}`,
    newPatient
  );

  return data;
};

export const updateHadFirstIncreasedSymptoms = async (
  patientId: string,
  hadFirstIncreasedSymptoms: boolean
) => {
  const oldPatient = await getPatient(patientId);
  const newPatient = {
    ...oldPatient,
    hadFirstIncreasedSymptoms,
  };

  const { data } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/patients/${patientId}`,
    newPatient
  );

  return data;
};

export const updateHadFirstBoosterIncreasedSymptoms = async (
  patientId: string,
  hadFirstBoosterIncreasedSymptoms: boolean
) => {
  const oldPatient = await getPatient(patientId);
  const newPatient = {
    ...oldPatient,
    hadFirstBoosterIncreasedSymptoms,
  };

  const { data } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/patients/${patientId}`,
    newPatient
  );

  return data;
};

export const addResponse = async (
  sessionId: string,
  question: number,
  answer: string
) => {
  const oldSession = await getSession(sessionId);

  const newSession = {
    ...oldSession,
    responses: [...oldSession.responses, { question, answer }],
  };

  const { data: res } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/sessions/${sessionId}`,
    newSession
  );

  return res;
};

export const addMultipleResponses = async (
  sessionId: string,
  responses: { question: number | string; answer: string }[]
) => {
  const oldSession = await getSession(sessionId);

  const newSession = {
    ...oldSession,
    responses: [...oldSession.responses, ...responses],
  };

  const { data: res } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/sessions/${sessionId}`,
    newSession
  );

  return res;
};

export const addAvoidanceAssessmentResponse = async (
  sessionId: string,
  instance: string,
  answers: string
) => {
  const oldSession = await getSession(sessionId);

  const newSession = {
    ...oldSession,
    avoidanceAssessmentResponses: [
      ...oldSession.avoidanceAssessmentResponses,
      { instance, answers },
    ],
  };

  const { data: res } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/sessions/${sessionId}`,
    newSession
  );

  return res;
};

export const addModules = async (sessionId: string, modules: number[]) => {
  const oldSession = await getSession(sessionId);

  let newModules = new Set(oldSession.modules || []);

  modules.forEach((module) => newModules.add(module));

  const newSession = {
    ...oldSession,
    modules: Array.from(newModules),
  };

  const { data: res } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/sessions/${sessionId}`,
    newSession
  );

  return res;
};

export const addSymptoms = async (
  sessionId: string,
  continuousSymptoms: ContinuousSymptom[],
  episodicSymptoms: EpisodicSymptom[],
  fndEpisodesCount?: number
) => {
  const oldSession = await getSession(sessionId);

  const newSession = {
    ...oldSession,
    continuousSymptoms,
    episodicSymptoms,
    fndEpisodesCount,
  };

  const { data: res } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/sessions/${sessionId}`,
    newSession
  );

  return res;
};

export const addCompletedModules = async (
  patientId: string,
  completedModules: number[],
  sessionNumber: number
) => {
  const oldPatient = await getPatient(patientId);

  let newModules = new Set(oldPatient.modulesCompleted || []);

  completedModules.forEach((module) =>
    newModules.add({ module, session: sessionNumber })
  );

  // If the Graduation Module (110) is set to completed, set Patient's isGraduated to true
  const newPatient = completedModules.includes(110)
    ? {
        ...oldPatient,
        isGraduated: true,
        modulesCompleted: Array.from(newModules),
      }
    : {
        ...oldPatient,
        modulesCompleted: Array.from(newModules),
      };

  const { data: res } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/patients/${patientId}`,
    newPatient
  );

  return res;
};

export const removeCompletedModule = async (
  patientId: string,
  completedModule: number,
  sessionNumber: number
) => {
  const oldPatient = await getPatient(patientId);

  // If the Graduation Module (110) is being removed, set Patient's isGraduated to false
  const newPatient =
    completedModule === 110
      ? {
          ...oldPatient,
          isGraduated: false,
          modulesCompleted: oldPatient.modulesCompleted.filter(
            (module) =>
              module.module !== completedModule &&
              module.session !== sessionNumber
          ),
        }
      : {
          ...oldPatient,
          modulesCompleted: oldPatient.modulesCompleted.filter(
            (module) =>
              module.module !== completedModule &&
              module.session !== sessionNumber
          ),
        };

  const { data: res } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/patients/${patientId}`,
    newPatient
  );

  return res;
};

export const completeSession = async (sessionId: string) => {
  const oldSession = await getSession(sessionId);

  const newSession = {
    ...oldSession,
    sessionComplete: true,
  };

  const { data: res } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/sessions/${sessionId}`,
    newSession
  );

  return res;
};

export const savePatientThePlan = async (
  patientId: string,
  thePlan: string
) => {
  const oldPatient = await getPatient(patientId);

  const newPatient = {
    ...oldPatient,
    thePlan,
  };

  const { data: res } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/patients/${patientId}`,
    newPatient
  );

  return res;
};

export const addRetrainingGain = async (
  patientId: string,
  body: string,
  session: number
) => {
  const oldPatient = await getPatient(patientId);

  const newPatient = {
    ...oldPatient,
    retrainingGains: [...oldPatient.retrainingGains, { body, session }],
  };

  const { data: res } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/patients/${patientId}`,
    newPatient
  );

  return res;
};

export const removeRetrainingGain = async (patientId: string, _id: string) => {
  const oldPatient = await getPatient(patientId);

  const newPatient = {
    ...oldPatient,
    retrainingGains: oldPatient.retrainingGains.filter(
      (gain) => gain._id !== _id
    ),
  };

  const { data: res } = await axiosWithAuth.put(
    `${process.env.REACT_APP_API_ROOT}/patients/${patientId}`,
    newPatient
  );

  return res;
};
