import { useAppContext } from '../Context/AppContext';
import { Calendar } from 'react-feather';
import SymptomsForm from '../Components/SymptomsForm';
import ReturnSymptomsForm from '../Components/ReturnSymptomsForm';
import { getWeekNumber } from '../Utils/helpers';
import { useNavigate } from 'react-router-dom';

export default function IntakePage() {
  const { appData } = useAppContext();

  const navigate = useNavigate();

  if (!appData?.session || !appData?.patient) navigate('/dashboard');

  const d = getWeekNumber(new Date(appData?.session?.sessionDate!));

  return (
    <main className="flex flex-col gap-8 font-figtree text-dark-blue">
      <div className="flex items-center justify-between rounded-2xl bg-white p-6 shadow-xl">
        <h1 className="text-2xl font-semibold">
          Starting Session {appData?.session?.sessionNumber} &bull; Patient{' '}
          {appData?.patient?.patientId}-
          {[true, 'true', undefined].includes(appData?.patient?.isChild)
            ? 'C'
            : 'A'}
        </h1>
        <div className="flex items-center gap-2 text-sm text-grey">
          <Calendar size={18} />
          {d[0]}, week {d[1]}
        </div>
      </div>
      <div className="rounded-t-xl bg-white p-8 shadow-xl shadow-slate-500">
        {appData?.session?.sessionNumber === 1 ? (
          <SymptomsForm />
        ) : (
          <ReturnSymptomsForm />
        )}
      </div>
    </main>
  );
}
