import { BookOpen, Compass, Smile, Plus, Save } from 'react-feather';
import Button from './Button';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './Accordion';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getDocumentLibrary } from '../Utils/queries';
import Loading from './Loading';
import { addModules } from '../Utils/mutations';
import { AppData, useAppContext } from '../Context/AppContext';
import SessionExport from './SessionExport';

export default function ModuleReviewSidebar() {
  const navigate = useNavigate();

  const { appData, setStateAndLocalStorage } = useAppContext();

  const { isLoading, error, data } = useQuery({
    queryKey: ['documentLibrary'],
    queryFn: getDocumentLibrary,
  });

  const moduleMutation = useMutation({
    mutationFn: (params: { modules: number[] }) =>
      addModules(appData!.session!._id, params.modules),
    onSuccess: (res, params) => {
      setStateAndLocalStorage({
        ...appData,
        session: {
          ...appData?.session,
          modules: params.modules,
        },
      } as AppData);
    },
  });

  const handleAddGradModule = () => {
    window.confirm(
      'Are you sure you want to add the Graduation Module?\n\nThis action can not be undone.'
    ) &&
      moduleMutation.mutate({
        modules: [110],
      });
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error instanceof Error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="z-20 flex w-80 flex-col gap-4 bg-white p-4 shadow-xl shadow-slate-500">
      <Button
        type="button"
        variant="secondary"
        onClick={() => navigate('/module-review/the-plan/')}
      >
        <div className="flex w-full gap-2">
          <Compass />
          <div className="normal-case">The Plan</div>
        </div>
      </Button>
      <Button
        type="button"
        variant="secondary"
        onClick={() => navigate('/module-review/retraining-gains')}
      >
        <div className="flex w-full gap-2">
          <Smile />
          <div className="normal-case">Retraining Gains</div>
        </div>
      </Button>
      <Accordion type="single" collapsible className="text-dark-blue">
        <AccordionItem value="item-1" className="flex flex-col gap-4 border-0">
          <AccordionTrigger className="flex items-center justify-start gap-2 px-6 font-semibold">
            <BookOpen className="!rotate-0" />
            <div className="mr-auto">Documents</div>
          </AccordionTrigger>
          <AccordionContent>
            <ul className="flex flex-col gap-4">
              {data?.documents.map((document) => (
                <li key={document.url}>
                  <a
                    href={document.url}
                    target="_blank"
                    rel="noreferrer"
                    className="hover:underline"
                  >
                    {document.originalFilename}
                  </a>
                </li>
              ))}
            </ul>
          </AccordionContent>
        </AccordionItem>
        <AccordionItem value="item-2" className="flex flex-col gap-4 border-0">
          <AccordionTrigger className="flex items-center justify-start gap-2 px-6 font-semibold">
            <Save className="!rotate-0" />
            <div className="mr-auto">Export</div>
          </AccordionTrigger>
          <AccordionContent>
            <SessionExport />
          </AccordionContent>
        </AccordionItem>
      </Accordion>
      <Button variant="secondary" size="sm" onClick={handleAddGradModule}>
        <div className="flex w-full items-center gap-2">
          <Plus />
          <div className="normal-case">Graduation Module</div>
        </div>
      </Button>
    </div>
  );
}
