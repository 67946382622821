import clsx from 'clsx';

export default function YesNoRadioGroup({
  questionId,
  register,
}: {
  questionId: number | string;
  register: any;
}) {
  const btnClasses = clsx(
    'p-4 w-[4.25rem] text-dark-blue font-semibold text-center text-sm uppercase bg-white rounded-lg border-2 border-salmon',
    'peer-checked:bg-dark-blue peer-checked:text-white peer-checked:border-transparent'
  );

  return (
    <div className="flex flex-shrink-0 items-center gap-4">
      <label>
        <input
          {...register(`q-${questionId}`, { required: true })}
          type="radio"
          value="yes"
          className="peer !hidden"
        />
        <div className={btnClasses}>Yes</div>
      </label>

      <label>
        <input
          {...register(`q-${questionId}`, { required: true })}
          type="radio"
          value="no"
          className="peer !hidden"
        />
        <div className={btnClasses}>No</div>
      </label>
    </div>
  );
}
