import { ArrowLeft, Clipboard } from 'react-feather';
import ModuleDisplay from '../Components/ModuleDisplay';
import ModuleReviewSidebar from '../Components/ModuleReviewSidebar';
import { useAppContext } from '../Context/AppContext';
import NoSessionSet from '../Components/NoSessionSet';
import { Link } from 'react-router-dom';

export default function ModuleReviewPage() {
  const { appData } = useAppContext();

  if (!appData?.session) {
    return <NoSessionSet />;
  } else {
    return (
      <main className="flex w-full flex-1 flex-col gap-4 font-figtree text-dark-blue">
        <Link
          to="/dashboard"
          className="mb-8 flex items-center gap-2 print:hidden"
        >
          <ArrowLeft size={30} />
          <h3 className="text-xl">Dashboard</h3>
        </Link>
        <div className="flex items-center gap-2 text-4xl">
          <Clipboard size={40} />
          <h1 className="font-bold">Module Review:</h1>
          <h3 className="italic">
            Session {appData?.session?.sessionNumber}, Patient{' '}
            {appData?.patient?.patientId}-
            {[true, 'true', undefined].includes(appData.patient?.isChild)
              ? 'C'
              : 'A'}
          </h3>
        </div>
        <div className="flex w-full flex-1 overflow-hidden rounded-t-xl">
          <ModuleReviewSidebar />
          <ModuleDisplay />
        </div>
      </main>
    );
  }
}
