export default function SvgIcon({ name }: { name: 'symptoms' | 'response' }) {
  switch (name) {
    case 'symptoms':
      return (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 22 23"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M4.42197 13.4035C2.45408 12.897 1 11.1106 1 8.98459C1 6.46472 3.04276 4.42197 5.56262 4.42197C5.75577 4.42197 5.94611 4.43397 6.13295 4.45727C6.83759 2.46364 8.4607 1 10.6956 1C12.6488 1 14.4481 2.10091 15.3156 3.70671C15.8057 3.43562 16.3695 3.28131 16.9693 3.28131C18.8592 3.28131 20.3912 4.81338 20.3912 6.70328C20.3912 7.89354 19.7836 8.94186 18.8615 9.55492C20.0882 9.94698 21 11.0963 21 12.4531C21 14.1332 19.638 15.4951 17.958 15.4951C16.2779 15.4951 15.1951 14.5211 14.6879 13.5472C13.5649 14.1284 12.1834 13.6891 11.6022 12.5662M4.42197 13.4035C4.42197 15.0835 5.78392 16.5892 7.46398 16.5892C9.2892 16.5892 13.6472 16.4074 13.6472 18.841V21.2746M4.42197 13.4035C4.42197 11.7234 5.78392 10.5052 7.46398 10.5052M8.83609 6.70776C9.46832 5.6127 10.8686 5.23751 11.9636 5.86974C13.0587 6.50197 13.4339 7.90222 12.8016 8.99728"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );

    case 'response':
      return (
        <svg
          width="100%"
          height="100%"
          viewBox="0 0 26 12"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.5145 0.142507C1.20557 -0.0428512 0.82081 -0.0477054 0.507301 0.1298C0.193792 0.307306 0 0.639728 0 1L8.9407e-07 10.9507C8.9407e-07 11.3109 0.193793 11.6434 0.507302 11.8209C0.820811 11.9984 1.20557 11.9935 1.5145 11.8082L9.80672 6.83283C10.1079 6.6521 10.2922 6.3266 10.2922 5.97533C10.2922 5.62407 10.1079 5.29856 9.80672 5.11784L1.5145 0.142507ZM12.6091 1.01998e-07C13.1613 0.0001154 13.6089 0.447787 13.6089 1L13.6089 10.9507C13.6089 11.503 13.1612 11.9507 12.6089 11.9507C12.0566 11.9507 11.6089 11.503 11.6089 10.9507L11.6089 1C11.6089 0.447715 12.0566 1.01998e-07 12.6089 1.01998e-07C12.609 1.01998e-07 12.609 8.70965e-08 12.6091 1.01998e-07ZM2 9.18448L2 2.76619L7.34857 5.97533L2 9.18448ZM25.218 1C25.218 0.639729 25.0242 0.307306 24.7107 0.129801C24.3972 -0.0477047 24.0124 -0.0428505 23.7035 0.142508L15.4113 5.11784C15.1101 5.29857 14.9258 5.62407 14.9258 5.97534C14.9258 6.3266 15.1101 6.6521 15.4113 6.83283L23.7035 11.8082C24.0124 11.9935 24.3972 11.9984 24.7107 11.8209C25.0242 11.6434 25.218 11.3109 25.218 10.9507V1ZM23.218 9.18448L17.8694 5.97534L23.218 2.76619V9.18448Z"
            fill="currentColor"
          />
        </svg>
      );

    default:
      return <></>;
  }
}
