import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { addResponse } from '../Utils/mutations';
import { AppData, useAppContext } from '../Context/AppContext';
import { useNavigate } from 'react-router-dom';
import { AvoidanceAssessment } from '../Types/avoidance-assessment';
import { Question } from '../Types/question';
import Button from './Button';

export default function NumberForm({
  questionId,
  nextStepType,
  nextStep,
}: {
  questionId: number;
  nextStepType: string;
  nextStep?: Question | AvoidanceAssessment;
}) {
  const navigate = useNavigate();

  const { appData, setStateAndLocalStorage } = useAppContext();

  const mutation = useMutation({
    mutationFn: (answer: string) =>
      addResponse(appData!.session!._id, questionId, answer),
    onSuccess: async (data, answer) => {
      setStateAndLocalStorage({
        ...appData,
        session: {
          ...appData?.session,
          responses: [
            ...(appData?.session?.responses || []),
            { question: questionId, answer },
          ],
        },
      } as AppData);
      switch (nextStepType) {
        case 'Question':
          navigate(`/question/${(nextStep as Question).key}`);
          break;
        case 'Avoidance Assessment':
          navigate(
            `/avoidance-assessment/${
              (nextStep as AvoidanceAssessment).instance
            }`
          );
          break;
        case 'Module Review':
          navigate(`/module-review`);
          break;
        default:
          return false;
      }
    },
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data: any) => {
    if (!errors[`q-${questionId}`]) {
      mutation.mutate(data[`q-${questionId}`]);
    } else {
      console.error('💥 Errors: ');
      console.error(errors);
    }
  };

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex items-center gap-4"
      >
        <input
          type="number"
          {...register(`q-${questionId}`, { required: true })}
          min={0}
        />
        <Button type="submit" variant="primary">
          Next
        </Button>
      </form>
    </>
  );
}
