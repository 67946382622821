import { useQuery } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import { getPatient } from '../Utils/queries';
import Loading from '../Components/Loading';
import { AlertTriangle, ArrowLeft, User } from 'react-feather';
import { states } from '../Utils/constants';
import { getWeekNumber } from '../Utils/helpers';

export default function PatientPage() {
  const { patientId } = useParams<{ patientId: string }>();

  const {
    isLoading,
    error,
    data: patient,
  } = useQuery({
    queryKey: ['patient', patientId],
    queryFn: () => getPatient(patientId!),
  });

  if (isLoading) {
    return <Loading />;
  }

  if (error instanceof Error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <main className="flex w-full flex-1 flex-col gap-4 font-figtree text-dark-blue">
      <Link
        to="/dashboard"
        className="mb-8 flex items-center gap-2 print:hidden"
      >
        <ArrowLeft size={30} />
        <h3 className="text-xl">Dashboard</h3>
      </Link>
      <div className="flex items-center gap-2">
        <User size={40} />
        <h1 className="text-4xl font-bold">Patient {patient?.patientId}</h1>
      </div>
      <div className="flex w-full flex-1 flex-col gap-8 rounded-t-xl bg-white p-8 pb-40 shadow-xl shadow-slate-500 print:bg-transparent print:shadow-none">
        <h3 className="text-3xl font-semibold">Demographics</h3>
        <div className="rounded-2xl bg-dark-blue p-2">
          <table className="table-demographics table-auto">
            <tbody>
              <tr>
                <th>Age</th>
                <td>{patient?.age}</td>
              </tr>
              <tr>
                <th>Treatment</th>
                <td>
                  {patient?.isChild === undefined || patient?.isChild
                    ? 'Child'
                    : 'Adult'}
                </td>
              </tr>
              <tr>
                <th>Sex</th>
                <td>{patient?.sex}</td>
              </tr>
              <tr>
                <th>Gender</th>
                <td>{patient?.gender}</td>
              </tr>
              <tr>
                <th>Race</th>
                <td>{patient?.race}</td>
              </tr>
              <tr>
                <th>Ethnicity</th>
                <td>{patient?.ethnicity}</td>
              </tr>
              <tr>
                <th>State</th>
                <td>
                  {
                    states.find((state) => state.value === patient?.state)
                      ?.label
                  }
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="text-3xl font-semibold">Intake Answers</h3>
        <div className="rounded-2xl bg-dark-blue p-2">
          <table className="table-intake table-auto">
            <tbody>
              <tr>
                <th className="w-2/3">Timeframe FND symptoms first began</th>
                <td>{patient?.fndBeginTimeframe}</td>
              </tr>
              <tr>
                <th>Timeframe FND was first diagnosed</th>
                <td>{patient?.fndDiagnoseTimeframe}</td>
              </tr>
              <tr>
                <th>Comorbid neurological conditions / functional disorders</th>
                <td>{patient?.comorbidNeurologicalConditions}</td>
              </tr>
              <tr>
                <th>
                  Family history of neurological conditions / functional
                  disorders
                </th>
                <td>{patient?.familyNeurologicalConditions}</td>
              </tr>
              <tr>
                <th>Comorbid psychiatric or mood conditions</th>
                <td>{patient?.comorbidPsychiatricConditions}</td>
              </tr>
              <tr>
                <th>Family history of psychiatric or mood conditions</th>
                <td>{patient?.familyPsychiatricConditions}</td>
              </tr>
              <tr>
                <th>What medications are they taking?</th>
                <td>{patient?.medications}</td>
              </tr>
              <tr>
                <th>Have you had treatment for FND before?</th>
                <td>{patient?.previousTreatment}</td>
              </tr>
              <tr>
                <th>If yes, how many times?</th>
                <td>{patient?.previousTreatmentCount}</td>
              </tr>
              <tr>
                <th>What kinds of FND treatment did you have?</th>
                <td>
                  <ul className="list-inside list-disc">
                    {patient?.previousTreatmentTypeInpatientPsychiatric !==
                      'false' && <li>Inpatient Psychiatric</li>}
                    {patient?.previousTreatmentTypeInpatientRehabilitation !==
                      'false' && <li>Inpatient Rehabilitation</li>}
                    {patient?.previousTreatmentTypeOutpatientTherapy !==
                      'false' && <li>Outpatient Therapy</li>}
                    {patient?.previousTreatmentTypeMedication !== 'false' && (
                      <li>Medication</li>
                    )}
                    {patient?.previousTreatmentTypeOther !== 'false' && (
                      <li>Other</li>
                    )}
                  </ul>
                </td>
              </tr>
              <tr>
                <th>What have they been told causes FND?</th>
                <td>
                  <ul className="list-inside list-disc">
                    {patient?.fndCauseTrauma !== 'false' && <li>Trauma</li>}
                    {patient?.fndCauseStress !== 'false' && (
                      <li>Stress and/or Mood Disorders</li>
                    )}
                    {patient?.fndCauseAbuse !== 'false' && <li>Abuse</li>}
                    {patient?.fndCauseChange !== 'false' && (
                      <li>
                        A change in functioning between the brain and body
                      </li>
                    )}
                    {patient?.fndCauseOther !== 'false' && <li>Other</li>}
                  </ul>
                </td>
              </tr>
              <tr>
                <th>Are they currently participating in in-person school?</th>
                <td>{patient?.inSchool}</td>
              </tr>
              <tr>
                <th>If no, did they stop school because of FND symptoms?</th>
                <td>{patient?.fndStoppedSchool}</td>
              </tr>
              <tr>
                <th>If yes, do they want to return to in-person school?</th>
                <td>{patient?.wantToReturnToSchool}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="text-3xl font-semibold">Reported Symptoms</h3>
        <div className="rounded-2xl bg-dark-blue p-2">
          <table className="table-symptoms table-auto">
            <thead>
              <tr>
                <th>Session</th>
                <th>Episodic Symptoms</th>
                <th>Continuous Symptoms</th>
              </tr>
            </thead>
            <tbody>
              {patient?.sessions.map((session) => {
                const d = getWeekNumber(new Date(session.sessionDate));
                return (
                  <tr
                    key={session._id}
                    className={
                      session.sessionNumber >= patient.boosterStart
                        ? 'bg-light-salmon'
                        : ''
                    }
                  >
                    <td>
                      <strong>{session.sessionNumber}</strong>: {d[0]}, week{' '}
                      {d[1]}
                    </td>
                    <td>
                      {session.episodicSymptoms.length > 0 && (
                        <ul>
                          {session.episodicSymptoms.map((symptom) => (
                            <li className="flex items-center gap-2">
                              <span>&bull;</span>
                              {symptom.name}
                              {symptom.increasedSeverity && (
                                <span title="Increased Severity Reported">
                                  <AlertTriangle />
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </td>
                    <td>
                      {session.continuousSymptoms.length > 0 && (
                        <ul>
                          {session.continuousSymptoms.map((symptom) => (
                            <li className="flex items-center gap-2">
                              <span>&bull;</span>
                              {symptom.name}
                              {symptom.increasedSeverity && (
                                <span title="Increased Severity Reported">
                                  <AlertTriangle />
                                </span>
                              )}
                            </li>
                          ))}
                        </ul>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
}
