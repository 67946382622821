import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AppData, useAppContext } from '../Context/AppContext';
import { addResponse } from '../Utils/mutations';

export default function ResponseButton({
  questionKey,
  answer,
}: {
  questionKey: number;
  answer: string;
}) {
  const { appData, setStateAndLocalStorage } = useAppContext();

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => addResponse(appData!.session!._id, questionKey, answer),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['question', questionKey],
      });
    },
  });

  const handleResponse = async (questionKey: number, answer: string) => {
    mutation.mutate();
    setStateAndLocalStorage({
      ...appData,
      session: {
        ...appData?.session,
        responses: [
          ...(appData?.session?.responses || []),
          { question: questionKey, answer },
        ],
      },
    } as AppData);
  };

  return (
    <button
      type="button"
      onClick={() => handleResponse(questionKey, answer)}
      className="bg-blue-500 hover:bg-blue-600 flex flex-col items-center gap-2 rounded-lg p-4 text-white shadow transition-all hover:shadow-lg"
    >
      Record
      <span>
        {questionKey}: {answer}
      </span>
    </button>
  );
}
