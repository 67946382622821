import { useQuery } from '@tanstack/react-query';
import { AppData, useAppContext } from '../Context/AppContext';
import { ChangeEventHandler, useState } from 'react';
import { getSession } from '../Utils/queries';
import { getWeekNumber } from '../Utils/helpers';

export default function SessionSelector() {
  const { appData, setStateAndLocalStorage } = useAppContext();

  const sessions = appData?.patient?.sessions || [];

  const [selectedSession, setSelectedSession] = useState<string>();

  const handleSessionSelection: ChangeEventHandler<HTMLSelectElement> = async (
    e
  ) => {
    setSelectedSession(e.target.value);
  };

  useQuery({
    queryKey: ['session', selectedSession],
    queryFn: async () => {
      if (!selectedSession) return null;
      const data = await getSession(selectedSession);
      setStateAndLocalStorage({
        patient: appData!.patient,
        session: data,
        debug: appData?.debug ?? false,
      } as AppData);
      return data;
    },
  });

  return (
    <fieldset className="flex flex-col gap-2">
      <label htmlFor="session">Session</label>
      <select
        id="session"
        disabled={appData?.patient == null}
        onChange={handleSessionSelection}
        value={appData?.session?._id}
        className="w-64 rounded-full"
      >
        <option>Select a Session</option>
        {sessions.map((session) => {
          const d = getWeekNumber(new Date(session.sessionDate));
          return (
            <option key={session._id} value={session._id}>
              {d[0]}, week {d[1]}: Session {session.sessionNumber}
            </option>
          );
        })}
      </select>
    </fieldset>
  );
}
