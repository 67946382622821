import { useQuery } from '@tanstack/react-query';
import { useAppContext } from '../Context/AppContext';
import {
  getAAQuestions,
  getModulesByIds,
  getQuestions,
} from '../Utils/queries';
import Button from './Button';

export default function SessionExport() {
  const { appData } = useAppContext();

  const totalSymptomCount =
    (appData?.session?.continuousSymptoms?.length ?? 0) +
    (appData?.session?.episodicSymptoms?.length ?? 0);

  const completedModuleIds =
    appData?.patient?.modulesCompleted
      ?.filter((m) => m.session === appData.session?.sessionNumber)
      ?.map((m) => m.module) ?? [];

  const { data: fullModules } = useQuery({
    queryKey: ['modules', completedModuleIds],
    queryFn: () => getModulesByIds(completedModuleIds),
  });

  const { data: questions } = useQuery({
    queryKey: ['questions'],
    queryFn: () => getQuestions(),
  });

  const { data: aaQuestions } = useQuery({
    queryKey: ['aaQuestions'],
    queryFn: () => getAAQuestions(),
  });

  let patientType: 'continuous' | 'episodic' | 'both' = 'continuous';
  let AATitle: string = 'Avoidance Assessment';
  if (
    appData?.patient?.sessions?.flatMap((s) => s.continuousSymptoms).length! >
      0 &&
    appData?.patient?.sessions?.flatMap((s) => s.episodicSymptoms).length! === 0
  ) {
    patientType = 'continuous';
    AATitle = 'Avoidance Assessment for Continuous Symptoms';
  } else if (
    appData?.patient?.sessions?.flatMap((s) => s.episodicSymptoms).length! >
      0 &&
    appData?.patient?.sessions?.flatMap((s) => s.continuousSymptoms).length! ===
      0
  ) {
    patientType = 'episodic';
    AATitle = 'Avoidance Assessment for Episodic Symptoms';
  } else if (
    appData?.patient?.sessions?.flatMap((s) => s.episodicSymptoms).length! >
      0 &&
    appData?.patient?.sessions?.flatMap((s) => s.continuousSymptoms).length! > 0
  ) {
    patientType = 'both';
    AATitle = 'Avoidance Assessment for Both Continuous and Episodic Symptoms';
  }

  const exportText = `Patient ${appData?.patient?.patientId}-${
    [true, 'true', undefined].includes(appData?.patient?.isChild) ? 'C' : 'A'
  }, Session ${appData?.session?.sessionNumber}: Patient reported ${
    totalSymptomCount > 0 ? totalSymptomCount : 'no'
  } symptom${totalSymptomCount !== 1 ? 's' : ''}.\n\n${
    appData?.session?.continuousSymptoms.length
      ? `Reported Continuous Symptoms: ${appData?.session?.continuousSymptoms
          .map((s) => s.name)
          .join(', ')}.`
      : 'No reported Continuous Symptoms.'
  }\n\n${
    appData?.session?.episodicSymptoms.length
      ? `Reported Episodic Symptoms: ${appData?.session?.episodicSymptoms
          .map((s) => s.name)
          .join(', ')}.`
      : 'No reported Episodic Symptoms.'
  }\n\n${
    appData?.session?.responses?.length
      ? appData?.session?.responses
          ?.map(
            (r) =>
              `- ${
                typeof r.question === 'number'
                  ? [true, 'true', undefined].includes(
                      appData?.patient?.isChild
                    )
                    ? questions?.find((q) => q.key === r.question)?.question
                    : questions?.find((q) => q.key === r.question)
                        ?.questionAdult ??
                      questions?.find((q) => q.key === r.question)?.question
                  : [true, 'true', undefined].includes(
                      appData?.patient?.isChild
                    )
                  ? questions?.find(
                      (q) =>
                        q.key === parseInt((r.question as string).split('-')[1])
                    )?.subquestions?.[
                      parseInt((r.question as string).split('-')[2]) - 1
                    ]
                  : questions?.find(
                      (q) =>
                        q.key === parseInt((r.question as string).split('-')[1])
                    )?.subquestionsAdult?.[
                      parseInt((r.question as string).split('-')[2]) - 1
                    ] ??
                    questions?.find(
                      (q) =>
                        q.key === parseInt((r.question as string).split('-')[1])
                    )?.subquestions?.[
                      parseInt((r.question as string).split('-')[2]) - 1
                    ]
              } [${r.answer}]`
          )
          .join('\n')
      : 'No regular questions were answered this session.'
  }\n\n${
    appData?.session?.avoidanceAssessmentResponses?.length
      ? appData?.session?.avoidanceAssessmentResponses
          ?.map(
            (r) =>
              `${AATitle} (${
                r.instance
              }) was completed with the following answers:\n${Object.keys(
                JSON.parse(r.answers)
              )
                .sort(
                  (a, b) =>
                    parseInt(a.split('-')[1]) - parseInt(b.split('-')[1])
                )
                .map((k) => {
                  const aaQuestion = aaQuestions?.find(
                    (q) => q.key === parseInt(k.split('-')[1])
                  )?.[
                    `${patientType}${
                      [true, 'true', undefined].includes(
                        appData?.patient?.isChild
                      )
                        ? ''
                        : 'Adult'
                    }`
                  ];
                  if (aaQuestion) {
                    return `- ${aaQuestion} [${JSON.parse(r.answers)[k]}]`;
                  } else {
                    return '';
                  }
                })
                .filter((s) => s !== '')
                .join('\n')}`
          )
          .join('\n\n')
      : 'No avoidance assessments were completed this session.'
  }\n\n${
    completedModuleIds.length
      ? 'Modules completed this session: ' +
        fullModules
          ?.map((m) =>
            [true, 'true', undefined].includes(appData?.patient?.isChild)
              ? m.title
              : m.titleAdult ?? m.title
          )
          .join(', ') +
        '.'
      : 'No modules were completed this session.'
  }`;

  return (
    <div className="flex flex-col gap-4">
      <textarea
        rows={10}
        value={exportText}
        readOnly
        className="w-full rounded-xl border-light-salmon bg-lighter-salmon p-2 selection:bg-salmon selection:text-white focus:border-salmon focus:outline-none focus:ring-2 focus:ring-salmon"
      />
      <Button
        variant="dark"
        size="sm"
        onClick={() => {
          navigator.clipboard.writeText(exportText);
        }}
      >
        <div className="normal-case">Copy Export Text</div>
      </Button>
    </div>
  );
}
