import { Square } from 'react-feather';
import { useAppContext } from '../Context/AppContext';

export default function ClearSessionButton() {
  const { appData, setStateAndLocalStorage } = useAppContext();

  return (
    <button
      type="button"
      className="flex items-center gap-2 rounded-full border border-slate-600 bg-transparent px-4 py-2 font-semibold text-slate-600 hover:text-slate-800 hover:shadow disabled:opacity-60"
      onClick={() => {
        setStateAndLocalStorage({
          patient: null,
          session: null,
          debug: appData?.debug ?? false,
        });
      }}
      disabled={!appData?.patient && !appData?.session}
    >
      Clear Session <Square size={16} fill="currentColor" />
    </button>
  );
}
