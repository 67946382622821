import { Link } from 'react-router-dom';

export default function NoSessionSet() {
  return (
    <main className="flex flex-col items-center justify-center gap-4 py-20 font-figtree text-dark-blue">
      <h1 className="text-3xl font-semibold">No Session</h1>
      <p>
        Please{' '}
        <Link to={'/dashboard'} className="underline">
          select a patient & session
        </Link>
        .
      </p>
    </main>
  );
}
