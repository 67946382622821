import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from '@auth0/auth0-react';
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom';
import Home from './Pages/Home';
import Menu from './Pages/Menu';
import { AppProvider } from './Context/AppContext';
import StatusBar from './Components/StatusBar';
import Layout from './Components/Layout';
import Question from './Pages/Question';
import AddPatient from './Pages/AddPatient';
import AvoidanceAssessment from './Pages/AvoidanceAssessment';
import ModuleReview from './Pages/ModuleReview';
import UserNotActive from './Components/UserNotActive';
import ProtectedPage from './Components/ProtectedPage';
import Intake from './Pages/Intake';
import EpisodicIntake from './Pages/EpisodicIntake';
import Header from './Components/Header';
import ThePlan from './Pages/ThePlan';
import RetrainingGains from './Pages/RetrainingGains';
import Admin from './Pages/Admin';
import Dashboard from './Pages/Dashboard';
import Patient from './Pages/Patient';
import Protected from './Components/Protected';

const ProtectedRoute = ({ component, ...args }: any) => {
  const Component = withAuthenticationRequired(component, args);
  const { user, isAuthenticated, isLoading } = useAuth0();
  if (!isLoading && isAuthenticated) {
    if (user?.['https://app.reactfnd.health/roles']?.includes('active')) {
      return <Component />;
    } else {
      return <UserNotActive />;
    }
  } else {
    return <ProtectedPage />;
  }
};

const Auth0ProviderWithRedirectCallback = ({ children, ...props }: any) => {
  return (
    <Auth0Provider
      domain={props.domain}
      clientId={props.clientId}
      authorizationParams={{
        redirect_uri: `${window.location.origin}/dashboard`,
        audience: 'https://localhost:5000',
      }}
      {...props}
    >
      {children}
    </Auth0Provider>
  );
};

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppProvider>
        <Auth0ProviderWithRedirectCallback
          domain={process.env.REACT_APP_AUTH0_DOMAIN!}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        >
          <ScrollToTop />
          <Layout>
            <StatusBar />
            <Header />
            <Routes>
              <Route path="/" element={<Home />} />
            </Routes>
            <Protected>
              <Routes>
                <Route
                  path="admin"
                  element={<ProtectedRoute component={Admin} />}
                />
                <Route
                  path="/dashboard"
                  element={<ProtectedRoute component={Dashboard} />}
                />
                <Route
                  path="/menu"
                  element={<ProtectedRoute component={Menu} />}
                />
                <Route
                  path="/intake"
                  element={<ProtectedRoute component={Intake} />}
                />
                <Route
                  path="/intake/episodic"
                  element={<ProtectedRoute component={EpisodicIntake} />}
                />
                <Route
                  path="/question/:questionId"
                  element={<ProtectedRoute component={Question} />}
                />
                <Route
                  path="/avoidance-assessment/:instance"
                  element={<ProtectedRoute component={AvoidanceAssessment} />}
                />
                <Route
                  path="/patients/add"
                  element={<ProtectedRoute component={AddPatient} />}
                />
                <Route
                  path="/patient/:patientId"
                  element={<ProtectedRoute component={Patient} />}
                />
                <Route
                  path="/module-review"
                  element={<ProtectedRoute component={ModuleReview} />}
                />
                <Route
                  path="/module-review/the-plan"
                  element={<ProtectedRoute component={ThePlan} />}
                />
                <Route
                  path="/module-review/retraining-gains"
                  element={<ProtectedRoute component={RetrainingGains} />}
                />
              </Routes>
            </Protected>
          </Layout>
        </Auth0ProviderWithRedirectCallback>
      </AppProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
