import { useForm } from 'react-hook-form';
import { AppData, useAppContext } from '../Context/AppContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import { EpisodicSymptom } from '../Types/episodic-symptom';
import { addModules, addSession, addSymptoms } from '../Utils/mutations';
import { getSymptoms } from '../Utils/queries';
import { Symptom } from '../Types/symptom';
import { ContinuousSymptom } from '../Types/continuous-symptom';
import SubmitAndClearButtons from './SubmitAndClearButtons';
import { useNavigate } from 'react-router-dom';
import Loading from './Loading';
import { Session } from '../Types/session';
import { getLastSunday } from '../Utils/helpers';

export default function SymptomsForm() {
  const {
    register,
    getValues,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm();

  const { appData, setStateAndLocalStorage } = useAppContext();

  const navigate = useNavigate();

  const {
    isLoading,
    error,
    data: symptoms,
  } = useQuery({
    queryKey: ['symptoms'],
    queryFn: getSymptoms,
  });

  const symptomMutation = useMutation({
    mutationFn: (params: {
      continuousSymptoms: ContinuousSymptom[];
      episodicSymptoms: EpisodicSymptom[];
      sessionId: string;
    }) =>
      addSymptoms(
        params.sessionId,
        params.continuousSymptoms,
        params.episodicSymptoms
      ),
    onSuccess: (res, params) => {
      moduleMutation.mutate({
        modules: [112, 104],
        episodic: params.episodicSymptoms.length > 0,
      });
    },
  });

  const moduleMutation = useMutation({
    mutationFn: (params: { modules: number[]; episodic: boolean }) =>
      addModules(appData!.session!._id, params.modules),
    onSuccess: (res, params) => {
      setStateAndLocalStorage({
        ...appData,
        session: {
          ...appData?.session,
          modules: params.modules,
        },
      } as AppData);
      if (params.episodic) {
        navigate('/intake/episodic');
      } else {
        navigate('/module-review');
      }
    },
  });

  const sessionMutation = useMutation({
    mutationFn: (data: { newSession: Partial<Session>; formData: any }) =>
      addSession(data.newSession),
    onSuccess: async (res, data) => {
      //
      const continuousSymptoms =
        Object.entries(data.formData)
          .filter(([key, value]) =>
            (value as string).startsWith('continuous - ')
          )
          .map(([key, value]) => ({
            _id: key,
            name: (value as string).replace('continuous - ', ''),
          })) ?? [];

      const episodicSymptoms =
        Object.entries(data.formData)
          .filter(([key, value]) => (value as string).startsWith('episodic - '))
          .map(([key, value]) => ({
            _id: key,
            name: (value as string).replace('episodic - ', ''),
          })) ?? [];

      setStateAndLocalStorage({
        ...appData,
        session: {
          ...appData?.session,
          continuousSymptoms,
          episodicSymptoms,
          _id: res.sessionId,
        },
        patient: {
          ...appData?.patient,
          sessions: [
            ...(appData?.patient?.sessions ?? []),
            {
              ...appData?.session,
              continuousSymptoms,
              episodicSymptoms,
              _id: res.sessionId,
            },
          ],
        },
      } as AppData);

      symptomMutation.mutate({
        continuousSymptoms,
        episodicSymptoms,
        sessionId: res.sessionId,
      });
    },
  });

  const formSubmit = (data: any) => {
    sessionMutation.mutate({
      newSession: {
        sessionNumber: 1,
        patientId: appData!.patient!._id,
        sessionDate: getLastSunday(),
      },
      formData: data,
    });
  };

  const atLeastOneSymptom = () => {
    return Object.entries(getValues()).some(
      ([_, value]) => !['no', undefined, null].includes(value)
    );
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error instanceof Error) {
    return <main>Error: {error.message}</main>;
  }

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <div className="flex flex-col items-center gap-4 rounded-lg bg-lighter-salmon bg-opacity-50 px-12 py-6 text-center">
        <h3 className="text-lg font-bold">Question</h3>
        <h2 className="text-balance text-3xl font-bold [text-wrap:balance]">
          What are the patient’s primary FND symptoms (e.g. If the patient
          experiences loss of consciousness as part of their seizure-like
          symptoms, only choose seizure-like symptoms below)?
        </h2>
      </div>
      <div className="mt-8 flex items-end justify-end gap-4 px-2 text-center">
        <label className="w-16">Episodic</label>
        <label className="w-24">Continuous</label>
        <label className="w-8">No</label>
      </div>
      <ol className="list-inside list-decimal text-xl font-semibold marker:relative">
        {symptoms?.map((symptom: Symptom, i: number) => (
          <li
            key={symptom._id}
            className="flex items-center gap-4 border-b border-grey px-2 py-4 last:border-0"
          >
            <div className="font-bold">{i + 1}.</div>
            <div className="flex-1">{symptom.name}</div>
            <label className="w-16 text-center">
              <input
                type="radio"
                {...register(symptom._id)}
                value={`episodic - ${symptom.name}`}
              />
            </label>
            <label className="w-24 text-center">
              <input
                type="radio"
                {...register(symptom._id)}
                value={`continuous - ${symptom.name}`}
              />
            </label>
            <label className="w-8 text-center">
              <input
                type="radio"
                {...register(symptom._id, { validate: atLeastOneSymptom })}
                value={`no`}
                defaultChecked
              />
            </label>
          </li>
        ))}
      </ol>
      <div className="pt-4 text-center italic">
        {!Object.entries(getValues()).some(
          ([_, value]) => !['no', undefined, null].includes(value)
        ) ? (
          <>At least one symptom must be reported.</>
        ) : (
          <>&nbsp;</>
        )}
      </div>
      <SubmitAndClearButtons disabled={isSubmitting || isLoading || !isValid}>
        Save Responses + Continue
      </SubmitAndClearButtons>
    </form>
  );
}
