import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { getExportData } from '../Utils/queries';
import CsvDownloadButton from 'react-json-to-csv';
import { Settings } from 'react-feather';

export default function AdminPage() {
  const { isAuthenticated, isLoading, user } = useAuth0();

  const { isLoading: queryIsLoading, data } = useQuery({
    queryKey: ['export'],
    queryFn: getExportData,
  });

  if (!isLoading && isAuthenticated && !queryIsLoading) {
    if (user?.['https://app.reactfnd.health/roles']?.includes('admin')) {
      return (
        <main className="flex w-full flex-1 flex-col gap-4 font-figtree text-dark-blue">
          <div className="flex items-center gap-2">
            <Settings size={40} />
            <h1 className="text-4xl font-bold">Admin</h1>
          </div>
          <div className="flex justify-center">
            <CsvDownloadButton
              data={data!}
              delimiter={'\t'}
              filename="react_fnd_export.csv"
              className="flex items-center justify-center gap-2 rounded-full border bg-dark-blue px-6 py-4 font-figtree text-base normal-case text-light hover:bg-link-blue focus:bg-light-blue focus:text-dark-blue active:bg-blue active:text-dark-blue disabled:bg-grey"
            >
              Download <em>Everything</em> in a CSV
            </CsvDownloadButton>
          </div>
        </main>
      );
    } else {
      return <>You are not an admin.</>;
    }
  } else {
    return <></>;
  }
}
