import YesNoRadioGroup from './YesNoRadioGroup';
import { useForm } from 'react-hook-form';
import clsx from 'clsx';
import { AppData, useAppContext } from '../Context/AppContext';
import { addAvoidanceAssessmentResponse, addModules } from '../Utils/mutations';
import { useNavigate } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { AvoidanceAssessment } from '../Types/avoidance-assessment';
import SubmitAndClearButtons from './SubmitAndClearButtons';

export default function AvoidanceAssessmentForm({
  avoidanceAssessment,
  patientType,
}: {
  avoidanceAssessment: AvoidanceAssessment;
  patientType: 'continuous' | 'episodic' | 'both';
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const navigate = useNavigate();

  const { appData, setStateAndLocalStorage } = useAppContext();

  const mutation = useMutation({
    mutationFn: (data) =>
      addAvoidanceAssessmentResponse(
        appData!.session!._id,
        avoidanceAssessment.instance,
        JSON.stringify(data)
      ),
    onSuccess: async () => {
      if (!appData?.debug) {
        // add the modules
        const thisInstancesAnswers =
          appData?.session?.avoidanceAssessmentResponses?.find(
            (response) => response.instance === avoidanceAssessment.instance
          );

        avoidanceAssessment.avoidanceAssessmentConditions.every(
          (condition, i) => {
            const conditionIsMet =
              condition.clauseType === 'AND'
                ? condition.avoidanceAnswers.every(
                    (avoidanceAnswer) =>
                      avoidanceAnswer.answer.toLowerCase() ===
                      JSON.parse(thisInstancesAnswers?.answers || '[]')[
                        `q-${avoidanceAnswer.question}`
                      ]
                  ) // Condition: AND; ALL of these answers happened in our responses
                : condition.avoidanceAnswers.some(
                    (avoidanceAnswer) =>
                      avoidanceAnswer.answer.toLowerCase() ===
                      JSON.parse(thisInstancesAnswers?.answers || '[]')[
                        `q-${avoidanceAnswer.question}`
                      ]
                  ); // Condition: OR; AT LEAST ONE of these answers happened in our responses

            if (conditionIsMet) {
              moduleMutation.mutate({
                modules: condition?.modules?.map((module) => module.key) ?? [],
                nextStepType: condition.nextStepType,
                nextQuestion: condition.nextQuestion,
              });
              // end the `every()` loop on the first condition that is met
              return false;
            } else {
              // continue the `every()` if until the condition has been met
              return true;
            }
          }
        );
      }
    },
  });

  const moduleMutation = useMutation({
    mutationFn: (params: {
      modules: number[];
      nextStepType: string;
      nextQuestion: string;
    }) => {
      let newModules = new Set(appData?.session?.modules || []);

      params.modules.forEach((module) => newModules.add(module));

      setStateAndLocalStorage({
        ...appData,
        session: {
          ...appData?.session,
          modules: Array.from(newModules),
        },
      } as AppData);
      return addModules(appData!.session!._id, params.modules);
    },
    onSuccess: async (res, params) => {
      switch (params.nextStepType) {
        case 'Question':
          navigate(`/question/${params.nextQuestion}`);
          break;
        case 'Module Review':
          navigate(`/module-review`);
          break;
        default:
          return false;
      }
    },
  });

  const onSubmit = async (data: any) => {
    setStateAndLocalStorage({
      ...appData,
      session: {
        ...appData?.session,
        avoidanceAssessmentResponses: [
          ...(appData?.session?.avoidanceAssessmentResponses || []),
          {
            instance: avoidanceAssessment.instance,
            answers: JSON.stringify(data),
          },
        ],
      },
    } as AppData);
    mutation.mutate(data);
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col items-center gap-8"
    >
      <ul className="flex w-full flex-col divide-y divide-dark-blue">
        {avoidanceAssessment.avoidanceQuestions?.map((question) => {
          const questionText = {
            continuous: question.continuous,
            continuousAdult: question.continuousAdult,
            episodic: question.episodic,
            episodicAdult: question.episodicAdult,
            both: question.both,
            bothAdult: question.bothAdult,
          }[
            `${patientType}${
              [true, 'true', undefined].includes(appData?.patient?.isChild)
                ? ''
                : 'Adult'
            }`
          ];
          if (!questionText) {
            return (
              <div key={`question-list-${question.key}`} className="!hidden">
                <input
                  {...register(`q-${question.key}`)}
                  type="hidden"
                  value="no"
                />
              </div>
            );
          } else {
            return (
              <li key={`question-list-${question.key}`}>
                <div
                  className={clsx(
                    'my-2 flex items-start gap-4 rounded-xl px-4 py-2',
                    {
                      'bg-salmon text-white': errors[`q-${question.key}`],
                    }
                  )}
                >
                  <div className="flex flex-1 items-baseline gap-4 py-2">
                    <strong className="text-xl">{question.key}:</strong>{' '}
                    <div className="text-lg">{questionText}</div>
                  </div>
                  <YesNoRadioGroup
                    questionId={question.key}
                    register={register}
                  />
                </div>
              </li>
            );
          }
        })}
      </ul>
      <SubmitAndClearButtons disabled={isSubmitting}>
        Save
      </SubmitAndClearButtons>
    </form>
  );
}
