import { ArrowLeft, ChevronRight, Circle, Smile, X } from 'react-feather';
import AddGainModal from '../Components/AddGainModal';
import { useEffect, useState } from 'react';
import { AppData, useAppContext } from '../Context/AppContext';
import { useMutation } from '@tanstack/react-query';
import { removeRetrainingGain } from '../Utils/mutations';
import NoSessionSet from '../Components/NoSessionSet';
import { Link } from 'react-router-dom';
import PrintButton from '../Components/PrintButton';
import { Copy } from 'react-feather';

export interface Gain {
  _id: string;
  body: string;
  session: number;
}

const CopyButton = ({ exportText }: { exportText: string }) => (
  <button
    type="button"
    className="flex items-center gap-2 text-lg font-semibold print:hidden"
    onClick={() => {
      navigator.clipboard.writeText(exportText);
    }}
  >
    <Copy />
    Copy
  </button>
);

export default function RetrainingGainsPage() {
  const { appData, setStateAndLocalStorage } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);

  const [retrainingGains, setRetrainingGains] = useState<Partial<Gain>[]>(
    appData?.patient?.retrainingGains ?? []
  );

  const mutation = useMutation({
    mutationFn: (data: { _id: string }) =>
      removeRetrainingGain(appData?.patient?._id!, data._id),
    onSuccess: async (res, data) => {
      setRetrainingGains((prev) =>
        prev.filter((gain) => gain._id !== data._id)
      );
      setStateAndLocalStorage({
        ...appData,
        patient: {
          ...appData?.patient,
          retrainingGains: appData?.patient?.retrainingGains?.filter(
            (gain) => gain._id !== data._id
          ),
        },
      } as AppData);
    },
  });

  useEffect(() => {
    setRetrainingGains(appData?.patient?.retrainingGains ?? []);
  }, [appData?.patient?.retrainingGains]);

  const exportText = `Retraining Gains:\n${
    retrainingGains.length > 0
      ? retrainingGains
          .map((gain) => `- ${gain.body} [Session ${gain.session}]`)
          .join('\n')
      : 'No gains listed.'
  }`;

  if (!appData?.session) {
    return <NoSessionSet />;
  } else {
    return (
      <main className="flex flex-1 flex-col gap-4 font-figtree text-dark-blue">
        <Link
          to="/module-review"
          className="mb-8 flex items-center gap-2 print:hidden"
        >
          <ArrowLeft size={30} />
          <h3 className="text-xl">Module Review</h3>
        </Link>
        <div className="flex items-baseline justify-end gap-6">
          <div className="mr-auto flex items-center gap-2 text-4xl print:text-2xl">
            <Smile size={40} />
            <h1 className="font-bold">Retraining Gains:</h1>
            <h3 className="italic">
              Patient {appData?.patient?.patientId}-
              {appData.patient?.isChild ? 'C' : 'A'}
            </h3>
          </div>
          <CopyButton exportText={exportText} />
          <PrintButton />
        </div>
        <div className="flex w-full flex-1 flex-col gap-8 rounded-t-xl bg-white p-8 pb-40 shadow-xl shadow-slate-500 print:bg-transparent print:shadow-none">
          <div className="flex items-center print:hidden">
            <div className="p-4 text-grey">
              <Circle />
            </div>
            <button
              type="button"
              onClick={() => setIsOpen(true)}
              className="flex flex-1 items-center justify-between rounded-xl bg-salmon px-8 py-4 text-2xl font-semibold uppercase"
            >
              <span>+ add gain</span>
              <ChevronRight />
            </button>
          </div>
          {retrainingGains.map((gain) => (
            <div key={gain._id} className="flex items-center">
              <div className="flex-none p-4 text-grey">
                <Smile />
              </div>
              <div className="flex flex-1 items-center justify-between gap-4 rounded-xl bg-white px-8 py-4 text-2xl font-semibold shadow-sm print:text-lg">
                <div className="mr-auto">{gain.body}</div>
                <div className="flex-none border-l border-dark-blue pl-4 text-lg print:text-sm">
                  Session {gain.session}
                </div>
                <div className="flex-none print:hidden">
                  <button
                    type="button"
                    className="p-4 text-grey hover:text-dark-blue"
                    onClick={() => mutation.mutate({ _id: gain._id! })}
                  >
                    <X />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <AddGainModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          retrainingGains={retrainingGains}
          setRetrainingGains={setRetrainingGains}
        />
      </main>
    );
  }
}
