import { ArrowRight } from 'react-feather';
import Button from './Button';

interface SubmitAndClearButtonsProps {
  disabled: boolean;
  children?: React.ReactNode;
}

export default function SubmitAndClearButtons({
  disabled,
  children,
}: SubmitAndClearButtonsProps) {
  return (
    <div className="flex items-center justify-center gap-4 py-12 print:hidden">
      <Button type="submit" variant="dark" size="lg" disabled={disabled}>
        {children} <ArrowRight />
      </Button>
      <button
        type="reset"
        className="font-bold uppercase text-grey"
        disabled={disabled}
      >
        Clear
      </button>
    </div>
  );
}
