import { Link } from 'react-router-dom';
import { Patient } from '../Types/patient';
import DeletePatientButton from './DeletePatientButton';
import DeleteSessionButton from './DeleteSessionButton';
import clsx from 'clsx';
import { useAppContext } from '../Context/AppContext';
import { getWeekNumber } from '../Utils/helpers';

export default function PatientsList({
  patients,
}: {
  patients: Partial<Patient>[];
}) {
  const { appData } = useAppContext();

  return (
    <>
      <div className="flex w-full flex-col gap-4">
        {patients.length > 0 ? (
          patients?.map((p: Partial<Patient>) => {
            const isActive = appData?.patient?._id === p._id;
            return (
              <div
                key={p._id}
                className={clsx(
                  'rounded-2xl border-2 bg-slate-50 p-4 text-slate-800 transition-shadow hover:shadow',
                  {
                    'border-green-600': isActive,
                    'border-transparent': !isActive,
                  }
                )}
              >
                <div className="flex items-baseline justify-between gap-4">
                  <h2 className="text-xl font-bold">Patient {p.patientId}</h2>
                  <div>{p.sex}</div>
                  <div>Age {p.age}</div>
                  <div className="ml-auto">
                    <DeletePatientButton id={p._id!} />
                  </div>
                </div>
                {p.sessions && p.sessions.length > 0 && (
                  <div className="mt-2 flex flex-wrap items-center justify-start gap-4">
                    <p className="font-semibold">Sessions:</p>
                    {p.sessions.map((s) => {
                      const isActiveSession =
                        isActive &&
                        s.sessionNumber === appData?.session?.sessionNumber;
                      const d = getWeekNumber(new Date(s.sessionDate));
                      return (
                        <div
                          key={s._id}
                          className={clsx(
                            'flex gap-4 rounded-full border-2 px-4 py-2 text-sm',
                            {
                              'bg-green-500': s.sessionComplete,
                              'bg-green-600': !s.sessionComplete,
                              'border-green-600 bg-white text-green-900':
                                isActiveSession,
                              'border-transparent text-white': !isActiveSession,
                            }
                          )}
                        >
                          <div className="font-bold">{s.sessionNumber}</div>
                          <div className={clsx({ italic: s.sessionComplete })}>
                            {d[0]}, week {d[1]}
                          </div>
                          <DeleteSessionButton id={s._id} />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="rounded-2xl bg-slate-50 px-4 py-20 text-center text-slate-800 transition-shadow hover:shadow">
            <h2 className="text-xl font-bold">No patients found</h2>
            <p>
              Please{' '}
              <Link to={'/patients/add'} className="text-slate-600 underline">
                add a new patient
              </Link>
              .
            </p>
          </div>
        )}
      </div>
    </>
  );
}
