import { Choice } from '../Types/choice';
import ResponseButton from './ResponseButton';
import { Question } from '../Types/question';
import clsx from 'clsx';
import AddModulesButton from './AddModulesButton';
import { Link } from 'react-router-dom';
import { AvoidanceAssessment } from '../Types/avoidance-assessment';
import { useAppContext } from '../Context/AppContext';

export default function ChoiceDisplayDebug({
  choice,
  question,
}: {
  choice: Choice;
  question: Question;
}) {
  const { appData } = useAppContext();

  const isCurrrentResponse = appData?.session?.responses?.some((r) => {
    return r.answer === choice.answer && r.question === question.key;
  });

  // Occurance
  const isAny = choice.occurance.includes('any');
  const hasPlus = choice.occurance.includes('+');
  const currentOccurance = parseInt(choice.occurance.replace('+', ''));
  const previousResponses =
    appData?.patient?.sessions
      .map((s) => s.responses)
      .flat()
      .filter((r) => {
        return r?.answer === choice.answer && r?.question === question.key;
      }).length || 0;
  const isCurrentOccurance = isAny
    ? true
    : hasPlus
    ? currentOccurance <= previousResponses + 1
    : currentOccurance === previousResponses + 1;

  // Dependency
  const dependencyFulfilled = appData?.session?.responses?.some((r) => {
    return (
      r.answer === choice?.choiceDependency?.answer &&
      r.question === parseInt(choice.choiceDependency.question as string)
    );
  });

  const hasDependencyOccurance =
    choice.choiceDependencyOccurance === 'none' ? false : true;

  const depedencyOccurance = choice.choiceDependencyOccurance.split(',');

  const previousDependencyResponses =
    appData?.patient?.sessions
      .map((s) => s.responses)
      .flat()
      .filter(
        (r) =>
          r?.answer === choice?.choiceDependency?.answer &&
          r?.question === parseInt(choice?.choiceDependency?.question as string)
      ).length || 0;

  // add same response from current session if applicable
  const currentDependencyResponse =
    appData?.session?.responses?.filter(
      (r) =>
        r?.answer === choice?.choiceDependency?.answer &&
        r?.question === parseInt(choice?.choiceDependency?.question as string)
    ).length || 0;

  const totalDependencyResponses =
    previousDependencyResponses + currentDependencyResponse;

  const dependencyOccuranceHasPlus = depedencyOccurance
    .slice(-1)[0]
    .includes('+');
  const dependencyOccuranceLimit = parseInt(
    depedencyOccurance.slice(-1)[0].replace('+', '')
  );

  const isCurrentDependencyOccurance =
    depedencyOccurance.includes(totalDependencyResponses.toString()) ||
    (dependencyOccuranceHasPlus &&
      totalDependencyResponses >= dependencyOccuranceLimit);

  const isCurrentDependency =
    !choice.choiceDependency ||
    (dependencyFulfilled && !hasDependencyOccurance) ||
    (dependencyFulfilled && isCurrentDependencyOccurance);

  const isCurrentAvoidanceAssessmentDependency =
    !choice.avoidanceAssessmentDependency ||
    appData?.session?.avoidanceAssessmentResponses?.some((aa) => {
      const dependencyIsMet =
        choice.avoidanceClauseType === 'AND'
          ? choice.avoidanceAnswers.every(
              (avoidanceAnswer) =>
                avoidanceAnswer.answer.toLowerCase() ===
                JSON.parse(aa.answers || '[]')[`q-${avoidanceAnswer.question}`]
            ) // Condition: AND; ALL of these answers happened in our responses
          : choice.avoidanceAnswers.some(
              (avoidanceAnswer) =>
                avoidanceAnswer.answer.toLowerCase() ===
                JSON.parse(aa.answers || '[]')[`q-${avoidanceAnswer.question}`]
            ); // Condition: OR; AT LEAST ONE of these answers happened in our responses

      return (
        aa.instance === choice.avoidanceAssessmentDependency && dependencyIsMet
      );
    });

  console.log(
    `Q#${question.key}:`,
    choice.answer,
    choice.occurance,
    choice.choiceDependency,
    choice.choiceDependencyOccurance,
    choice.avoidanceAssessmentDependency
  );
  console.log(
    `isCurrentOccurance: ${isCurrentOccurance}`,
    `isCurrentDependency: ${isCurrentDependency}`,
    `isCurrentDependencyOccurance: ${isCurrentDependencyOccurance}`,
    `isCurrentAvoidanceAssessmentDependency: ${isCurrentAvoidanceAssessmentDependency}`
  );

  return (
    <div
      key={choice._id}
      className={clsx(
        'flex w-[288px] flex-col gap-4 rounded-lg bg-white p-4 shadow transition-shadow hover:shadow-lg',
        {
          'opacity-40':
            !isCurrentOccurance ||
            !isCurrentDependency ||
            !isCurrentAvoidanceAssessmentDependency,
        }
      )}
    >
      <div className="flex items-center justify-between">
        <h3
          className={`${
            isCurrrentResponse ? 'underline' : ''
          } text-2xl font-extrabold`}
        >
          {choice.answer}
        </h3>
        <ResponseButton questionKey={question.key} answer={choice.answer} />
      </div>
      <div className="flex items-baseline justify-between gap-5">
        Occurance:{' '}
        <pre className={isCurrentOccurance ? 'font-bold underline' : undefined}>
          {choice.occurance}
        </pre>
      </div>
      Dependency:{' '}
      <pre
        className={clsx('text-right', {
          'text-xs': choice.choiceDependency,
          'font-bold underline': isCurrentDependency,
        })}
      >
        {!choice.choiceDependency
          ? 'none'
          : JSON.stringify(choice.choiceDependency)}
      </pre>
      <div className="flex items-baseline justify-between gap-5">
        Dependency Occurance:{' '}
        <pre
          className={clsx({
            'font-bold underline':
              isCurrentDependency && isCurrentDependencyOccurance,
          })}
        >
          {choice.choiceDependencyOccurance}
        </pre>
      </div>
      <div className="flex items-baseline justify-between gap-5">
        Avoidance Assessment Dependency:{' '}
        <pre
          className={clsx({
            'font-bold underline': isCurrentAvoidanceAssessmentDependency,
          })}
        >
          {choice.avoidanceAssessmentDependency ?? 'none'}
        </pre>
      </div>
      Next Step Type:{' '}
      <pre
        className={clsx(
          'ml-auto w-fit',
          {
            'bg-yellow-400': choice.nextStepType === 'Question',
            'bg-green-400': choice.nextStepType === 'Avoidance Assessment',
            'bg-gray-300': choice.nextStepType === 'Module Review',
          },
          'rounded px-2'
        )}
      >
        {choice.nextStepType}
      </pre>
      <div className="flex items-center justify-between gap-1">
        <span className="mr-auto">Modules:</span>
        <div className="flex flex-col items-end gap-1">
          {choice.modules && (
            <AddModulesButton
              modules={choice.modules.map((module) => module.key)}
            />
          )}
          <div className="flex gap-1">
            {choice.modules &&
              choice.modules.map((module) => (
                <div
                  key={`module-${module.key}`}
                  className="bg-blue-300 flex h-10 w-10 items-center justify-center rounded-full shadow"
                  title={module.title}
                >
                  {module.key}
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        Next Step:
        {choice.nextStep && choice.nextStep._type === 'question' && (
          <Link
            to={`/question/${(choice.nextStep as Question).key}`}
            className="flex h-10 w-10 items-center justify-center rounded-full bg-yellow-400 shadow hover:bg-yellow-500"
          >
            {(choice.nextStep as Question).key}
          </Link>
        )}
        {choice.nextStep && choice.nextStep._type === 'avoidanceAssessment' && (
          <Link
            to={`/avoidance-assessment/${
              (choice.nextStep as AvoidanceAssessment).instance
            }`}
            className="flex h-10 w-10 items-center justify-center rounded-full bg-green-400 shadow hover:bg-green-500"
          >
            {(choice.nextStep as AvoidanceAssessment).instance}
          </Link>
        )}
        {choice.nextStepType && choice.nextStepType === 'Module Review' && (
          <Link
            to={`/module-review`}
            className="flex items-center justify-center rounded-full bg-gray-300 px-4 py-2 shadow hover:bg-gray-400"
          >
            Module Review
          </Link>
        )}
      </div>
    </div>
  );
}
