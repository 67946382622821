import {
  CheckSquare,
  ChevronDown,
  ChevronRight,
  Command,
  Droplet,
  Square,
  X,
} from 'react-feather';
import React, { useEffect, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import Button from './Button';
import { changeThoughtType, thePlanType } from '../Pages/ThePlan';

export default function ChangeThoughts({
  thePlan,
  setThePlan,
  calmThoughts,
  controlThoughts,
}: {
  thePlan: thePlanType;
  setThePlan: React.Dispatch<React.SetStateAction<any>>;
  calmThoughts: string[];
  controlThoughts: string[];
}) {
  const [selectedCalmThoughts, setSelectedCalmThoughts] = useState<number[]>(
    []
  );
  const [otherCalmThought, setOtherCalmThought] = useState<string>('');
  const [selectedControlThoughts, setSelectedControlThoughts] = useState<
    number[]
  >([]);
  const [otherControlThought, setOtherControlThought] = useState<string>('');

  const handleAddChangeThought = () => {
    setThePlan((prevState: thePlanType) => {
      return {
        ...prevState,
        changeThoughts: prevState.changeThoughts
          ? JSON.stringify([
              ...JSON.parse(prevState.changeThoughts),
              {
                calmThoughts: selectedCalmThoughts,
                otherCalmThought,
                controlThoughts: selectedControlThoughts,
                otherControlThought,
              },
            ])
          : JSON.stringify([
              {
                calmThoughts: selectedCalmThoughts,
                otherCalmThought,
                controlThoughts: selectedControlThoughts,
                otherControlThought,
              },
            ]),
      };
    });
  };

  const handleRemoveChangeThought = (index: number) => {
    setThePlan((prevState: thePlanType) => {
      return {
        ...prevState,
        changeThoughts: JSON.stringify(
          JSON.parse(prevState.changeThoughts).filter(
            (_: any, i: number) => i !== index
          )
        ),
      };
    });
  };

  useEffect(() => {
    setSelectedCalmThoughts([]);
    setSelectedControlThoughts([]);
  }, [thePlan.changeThoughts]);

  return (
    <>
      <div className="flex items-center gap-8 px-4 py-2">
        <div className="flex w-2/5 items-center justify-center gap-2 text-2xl font-semibold print:text-lg">
          <div className="flex h-9 w-9 items-center justify-center rounded-full bg-light-salmon">
            <Droplet />
          </div>
          <h4>Calm Thoughts</h4>
        </div>
        <div className="flex flex-1 items-center justify-center gap-2 text-2xl font-semibold print:text-lg">
          <div className="flex h-9 w-9 items-center justify-center rounded-full bg-light-salmon">
            <Command />
          </div>
          <h4>Control Thoughts</h4>
        </div>
      </div>
      {thePlan.changeThoughts.length > 0 &&
        JSON.parse(thePlan.changeThoughts).length > 0 && (
          <div className="flex flex-col px-4 py-2 text-lg font-semibold print:text-base">
            {JSON.parse(thePlan.changeThoughts)?.map(
              (changeThought: changeThoughtType, index: number) => (
                <div
                  key={index}
                  className="flex w-full items-baseline gap-8 border-b border-dark-blue bg-white p-4"
                >
                  <div className="w-2/5">
                    <ul className="list-disc">
                      {changeThought?.calmThoughts?.map((calmThought) => (
                        <li key={calmThought}>
                          {calmThought < calmThoughts.length
                            ? calmThoughts.find(
                                (_, index) => index === calmThought
                              )
                            : changeThought.otherCalmThought}
                        </li>
                      ))}
                    </ul>
                  </div>

                  <div className="flex flex-1 flex-col gap-4">
                    <ul className="list-disc">
                      {changeThought.controlThoughts.map((controlThought) => (
                        <li key={controlThought}>
                          {controlThought < controlThoughts.length
                            ? controlThoughts.find(
                                (_, index) => index === controlThought
                              )
                            : changeThought.otherControlThought}
                        </li>
                      ))}
                    </ul>
                  </div>
                  <button
                    className="p-1 text-grey hover:text-dark-blue print:hidden"
                    onClick={() => handleRemoveChangeThought(index)}
                    type="button"
                  >
                    <X />
                  </button>
                </div>
              )
            )}
          </div>
        )}
      <div className="flex gap-8 px-4 py-2 print:hidden">
        <div className="relative flex w-2/5 flex-col gap-4">
          <Listbox
            value={selectedCalmThoughts}
            onChange={setSelectedCalmThoughts}
            multiple
          >
            <Listbox.Button className="flex w-full items-center justify-between rounded-xl border border-dark-blue p-4 text-lg font-semibold">
              <span className="block truncate">
                {selectedCalmThoughts.length > 0
                  ? `${selectedCalmThoughts.length} Selected Calm Thought${
                      selectedCalmThoughts.length > 1 ? 's' : ''
                    }`
                  : 'Select Calm Thought(s)'}
              </span>
              <ChevronDown />
            </Listbox.Button>
            <Transition>
              <Listbox.Options className="absolute z-10 max-h-[400px] w-full overflow-y-auto rounded-b-xl bg-white py-4 shadow-xl shadow-slate-500">
                {calmThoughts?.map((calmThought, index) => (
                  <Listbox.Option
                    key={`calmThought-${index}`}
                    value={index}
                    className={clsx(
                      `ui-active:bg-blue ui-not-active:bg-transparent`,
                      `flex cursor-default select-none items-center gap-4 px-8 py-3 text-lg`,
                      `ui-selected:font-bold ui-not-selected:font-semibold`
                    )}
                  >
                    <CheckSquare
                      aria-hidden="true"
                      className={clsx('ui-selected:block', 'hidden flex-none')}
                    />
                    <Square
                      aria-hidden="true"
                      className={clsx('ui-selected:hidden', 'block flex-none')}
                    />
                    <span className={clsx(`block`)}>{calmThought}</span>
                  </Listbox.Option>
                ))}
                <Listbox.Option
                  key={`calmThought-${calmThoughts.length}`}
                  value={calmThoughts.length}
                  className={clsx(
                    `ui-active:bg-blue ui-not-active:bg-transparent`,
                    `flex cursor-default select-none items-center gap-4 px-8 py-3 text-lg`,
                    `ui-selected:font-bold ui-not-selected:font-semibold`
                  )}
                >
                  Other
                </Listbox.Option>
              </Listbox.Options>
            </Transition>
          </Listbox>
          {selectedCalmThoughts?.includes(calmThoughts.length) && (
            <div>
              <input
                type="text"
                placeholder="Your own calm thought"
                className="w-full rounded-xl border border-dark-blue p-4 text-lg font-semibold"
                defaultValue={otherCalmThought}
                onChange={(e) => setOtherCalmThought(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className="relative flex flex-1 flex-col gap-4">
          <Listbox
            value={selectedControlThoughts}
            onChange={setSelectedControlThoughts}
            multiple
          >
            <Listbox.Button className="flex w-full items-center justify-between rounded-xl border border-dark-blue p-4 text-lg font-semibold">
              <span className="block truncate">
                {selectedControlThoughts.length > 0
                  ? `${
                      selectedControlThoughts.length
                    } Selected Control Thought${
                      selectedControlThoughts.length > 1 ? 's' : ''
                    }`
                  : 'Select Control Thought(s)'}
              </span>
              <ChevronRight />
            </Listbox.Button>
            <Transition>
              <Listbox.Options className="absolute z-10 max-h-[400px] w-full overflow-y-auto rounded-b-xl bg-white py-4 shadow-xl shadow-slate-500">
                {controlThoughts?.map((controlThought, index) => (
                  <Listbox.Option
                    key={`controlThought-${index}`}
                    value={index}
                    className={clsx(
                      `ui-active:bg-blue ui-not-active:bg-transparent`,
                      `flex cursor-default select-none items-center gap-4 px-8 py-3 text-lg`,
                      `ui-selected:font-bold ui-not-selected:font-semibold`
                    )}
                  >
                    <CheckSquare
                      aria-hidden="true"
                      className={clsx('ui-selected:block', 'hidden flex-none')}
                    />
                    <Square
                      aria-hidden="true"
                      className={clsx('ui-selected:hidden', 'block flex-none')}
                    />
                    <span className={clsx(`block`)}>{controlThought}</span>
                  </Listbox.Option>
                ))}
                <Listbox.Option
                  key={`controlThought-${controlThoughts.length}`}
                  value={controlThoughts.length}
                  className={clsx(
                    `ui-active:bg-blue ui-not-active:bg-transparent`,
                    `flex cursor-default select-none items-center gap-4 px-8 py-3 text-lg`,
                    `ui-selected:font-bold ui-not-selected:font-semibold`
                  )}
                >
                  <CheckSquare
                    aria-hidden="true"
                    className={clsx('ui-selected:block', 'hidden flex-none')}
                  />
                  <Square
                    aria-hidden="true"
                    className={clsx('ui-selected:hidden', 'block flex-none')}
                  />
                  Other
                </Listbox.Option>
              </Listbox.Options>
            </Transition>
          </Listbox>
          {selectedControlThoughts?.includes(controlThoughts.length) && (
            <div>
              <input
                type="text"
                placeholder="Your own control thought"
                className="w-full rounded-xl border border-dark-blue p-4 text-lg font-semibold"
                defaultValue={otherControlThought}
                onChange={(e) => setOtherControlThought(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
      {(selectedCalmThoughts.length > 0 ||
        selectedControlThoughts.length > 0) && (
        <Button
          variant="primary"
          size="sm"
          onClick={handleAddChangeThought}
          type="button"
        >
          + Add Change Thought
        </Button>
      )}
    </>
  );
}
