import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Patient } from '../Types/patient';
import { Session } from '../Types/session';
import React, {
  createContext,
  useState,
  useContext,
  FC,
  useEffect,
} from 'react';

export type AppData = {
  patient: Patient | null;
  session: Session | null;
  debug: boolean;
};

interface AppContextProps {
  appData: AppData | null;
  setStateAndLocalStorage: (appData: AppData | null) => void;
}

const AppContext = createContext<AppContextProps>({
  appData: null,
  setStateAndLocalStorage: () => null,
});

export const AppProvider: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const queryClient = new QueryClient();

  const [appData, setAppData] = useState<AppData | null>(null);

  const setStateAndLocalStorage = (appData: AppData | null) => {
    setAppData(appData);
    localStorage.setItem('appData', JSON.stringify(appData));
  };

  const value = {
    appData,
    setStateAndLocalStorage,
  };

  useEffect(() => {
    const appData = localStorage.getItem('appData');
    if (appData) {
      setAppData(JSON.parse(appData));
    }
  }, []);

  return (
    <AppContext.Provider value={value}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </AppContext.Provider>
  );
};

export const useAppContext = () => useContext(AppContext);
