import clsx from 'clsx';
import { Question } from '../Types/question';
import YesNoRadioGroup from './YesNoRadioGroup';
import { useForm } from 'react-hook-form';
import SubmitAndClearButtons from './SubmitAndClearButtons';
import { AppData, useAppContext } from '../Context/AppContext';
import { useMutation } from '@tanstack/react-query';
import { addModules, addMultipleResponses } from '../Utils/mutations';
import { Choice } from '../Types/choice';
import { useNavigate } from 'react-router-dom';
import { AvoidanceAssessment } from '../Types/avoidance-assessment';

interface MultiPartQuestionProps {
  question: Question;
  choices: Choice[];
}
export default function MultiPartQuestion({
  question,
  choices,
}: MultiPartQuestionProps) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm();

  const { appData, setStateAndLocalStorage } = useAppContext();

  const navigate = useNavigate();

  const goToNextStep = (params: {
    nextStepType: string;
    nextStep?: Question | AvoidanceAssessment;
  }) => {
    switch (params.nextStepType) {
      case 'Question':
        navigate(`/question/${(params.nextStep as Question).key}`);
        break;
      case 'Avoidance Assessment':
        navigate(
          `/avoidance-assessment/${
            (params.nextStep as AvoidanceAssessment).instance
          }`
        );
        break;
      case 'Module Review':
        navigate(`/module-review`);
        break;
      default:
        return false;
    }
  };

  const moduleMutation = useMutation({
    mutationFn: (variables: {
      modules: number[];
      nextStepType: string;
      nextStep?: Question | AvoidanceAssessment;
    }) => addModules(appData!.session!._id, variables.modules),
    onSuccess: async (data, variables) => {
      let newModules = new Set(appData?.session?.modules || []);

      variables.modules.forEach((module) => newModules.add(module));

      setStateAndLocalStorage({
        ...appData,
        session: {
          ...appData?.session,
          modules: Array.from(newModules),
        },
      } as AppData);

      goToNextStep({
        nextStepType: variables.nextStepType,
        nextStep: variables.nextStep,
      });
    },
  });

  const mutation = useMutation({
    mutationFn: (variables: { question: string | number; answer: string }[]) =>
      addMultipleResponses(appData!.session!._id, variables),
    onSuccess: async (data, variables) => {
      if (!appData?.debug) {
        // find the choice that cooresponds to the overall answer
        const overallAnswer = variables.find((v) => v.question === question.key)
          ?.answer;

        const chosenChoice = choices.find((choice) => {
          // Occurance
          const isAny = choice.occurance.includes('any');
          const hasPlus = choice.occurance.includes('+');
          const currentOccurance = parseInt(choice.occurance.replace('+', ''));
          const previousResponses =
            appData?.patient?.sessions
              .map((s) => s.responses)
              .flat()
              .filter((r) => {
                return (
                  r?.answer === choice.answer && r?.question === question.key
                );
              }).length || 0;
          const isCurrentOccurance = isAny
            ? true
            : hasPlus
            ? currentOccurance <= previousResponses + 1
            : currentOccurance === previousResponses + 1;

          // Dependency
          const dependencyFulfilled = appData?.session?.responses?.some((r) => {
            return (
              r.answer === choice?.choiceDependency?.answer &&
              r.question ===
                parseInt(choice.choiceDependency.question as string)
            );
          });

          const hasDependencyOccurance =
            choice.choiceDependencyOccurance === 'none' ? false : true;

          const depedencyOccurance =
            choice.choiceDependencyOccurance.split(',');

          const previousDependencyResponses =
            appData?.patient?.sessions
              .map((s) => s.responses)
              .flat()
              .filter(
                (r) =>
                  r?.answer === choice?.choiceDependency?.answer &&
                  r?.question ===
                    parseInt(choice?.choiceDependency?.question as string)
              ).length || 0;

          // add same response from current session if applicable
          const currentDependencyResponse =
            appData?.session?.responses?.filter(
              (r) =>
                r?.answer === choice?.choiceDependency?.answer &&
                r?.question ===
                  parseInt(choice?.choiceDependency?.question as string)
            ).length || 0;

          const totalDependencyResponses =
            previousDependencyResponses + currentDependencyResponse;

          const dependencyOccuranceHasPlus = depedencyOccurance
            .slice(-1)[0]
            .includes('+');
          const dependencyOccuranceLimit = parseInt(
            depedencyOccurance.slice(-1)[0].replace('+', '')
          );

          const isCurrentDependencyOccurance =
            depedencyOccurance.includes(totalDependencyResponses.toString()) ||
            (dependencyOccuranceHasPlus &&
              totalDependencyResponses >= dependencyOccuranceLimit);

          const isCurrentDependency =
            !choice.choiceDependency ||
            (dependencyFulfilled && !hasDependencyOccurance) ||
            (dependencyFulfilled && isCurrentDependencyOccurance);

          const isCurrentAvoidanceAssessmentDependency =
            !choice.avoidanceAssessmentDependency ||
            appData?.session?.avoidanceAssessmentResponses?.some((aa) => {
              const dependencyIsMet =
                choice.avoidanceClauseType === 'AND'
                  ? choice.avoidanceAnswers.every(
                      (avoidanceAnswer) =>
                        avoidanceAnswer.answer.toLowerCase() ===
                        JSON.parse(aa.answers || '[]')[
                          `q-${avoidanceAnswer.question}`
                        ]
                    ) // Condition: AND; ALL of these answers happened in our responses
                  : choice.avoidanceAnswers.some(
                      (avoidanceAnswer) =>
                        avoidanceAnswer.answer.toLowerCase() ===
                        JSON.parse(aa.answers || '[]')[
                          `q-${avoidanceAnswer.question}`
                        ]
                    ); // Condition: OR; AT LEAST ONE of these answers happened in our responses

              return (
                aa.instance === choice.avoidanceAssessmentDependency &&
                dependencyIsMet
              );
            });

          return (
            choice.answer.toLowerCase() === overallAnswer &&
            isCurrentOccurance &&
            isCurrentDependency &&
            isCurrentAvoidanceAssessmentDependency
          );
        });

        if (!chosenChoice) {
          console.error('No current matching choice found.');
          return;
        }

        // add the modules
        if (chosenChoice?.modules?.length > 0) {
          moduleMutation.mutate({
            modules: chosenChoice.modules.map((module) => module.key),
            nextStepType: chosenChoice.nextStepType,
            nextStep: chosenChoice.nextStep,
          });
        } else {
          goToNextStep({
            nextStepType: chosenChoice.nextStepType,
            nextStep: chosenChoice.nextStep,
          });
        }
      } else {
        console.warn('Success! Debug mode: proceed manually.');
      }
    },
  });

  const formSubmit = (data: any) => {
    const answers: { question: string | number; answer: string }[] =
      Object.entries(data).map(([key, value]) => ({
        question: key,
        answer: value as string,
      }));

    const overallAnswer = answers.some((answer) => answer.answer === 'yes')
      ? 'yes'
      : 'no';

    answers.unshift({ question: question.key, answer: overallAnswer });

    setStateAndLocalStorage({
      ...appData,
      session: {
        ...appData?.session,
        responses: [...(appData?.session?.responses || []), ...answers],
      },
    } as AppData);
    mutation.mutate(answers);
  };

  const SubQuestion = ({ q, i }: { q: string; i: number }) => (
    <li key={i}>
      <div
        className={clsx('my-2 flex items-start gap-4 rounded-xl px-4 py-2', {
          'bg-salmon text-white': errors[`q-${question.key}-${i + 1}`],
        })}
      >
        <div className="flex flex-1 items-baseline gap-4 py-2">
          <strong className="text-xl">{i + 1}:</strong>{' '}
          <div className="text-lg">{q}</div>
        </div>
        <YesNoRadioGroup
          questionId={`${question.key}-${i + 1}`}
          register={register}
        />
      </div>
    </li>
  );

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <ul>
        {appData?.patient?.isChild
          ? question?.subquestions?.map((q, i) => (
              <SubQuestion q={q} i={i} key={i} />
            ))
          : question?.subquestionsAdult?.map((q, i) => (
              <SubQuestion q={q} i={i} key={i} />
            )) ??
            question?.subquestions?.map((q, i) => (
              <SubQuestion q={q} i={i} key={i} />
            ))}
      </ul>
      <SubmitAndClearButtons disabled={isSubmitting}>
        Save
      </SubmitAndClearButtons>
    </form>
  );
}
