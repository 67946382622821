import { Calendar } from 'react-feather';
import { useAppContext } from '../Context/AppContext';
import { getWeekNumber } from '../Utils/helpers';
import EpisodicSymptomsForm from '../Components/EpisodicSymptomsForm';
import NoSessionSet from '../Components/NoSessionSet';

export default function EpisodicIntakePage() {
  const { appData } = useAppContext();

  if (!appData?.session) {
    return <NoSessionSet />;
  } else {
    const d = getWeekNumber(new Date(appData?.session?.sessionDate));

    return (
      <main className="flex flex-col gap-8 font-figtree text-dark-blue">
        <div className="flex items-center justify-between rounded-2xl bg-white p-6 shadow-xl">
          <h1 className="text-2xl font-semibold">
            Session {appData?.session?.sessionNumber} &bull; Patient{' '}
            {appData?.patient?.patientId}-
            {[true, 'true', undefined].includes(appData?.patient?.isChild)
              ? 'C'
              : 'A'}
          </h1>
          <div className="flex items-center gap-2 text-sm text-grey">
            <Calendar size={18} />
            {d[0]}, week {d[1]}
          </div>
        </div>
        <div className="rounded-t-xl bg-white p-8 shadow-xl shadow-slate-500">
          <EpisodicSymptomsForm />
        </div>
      </main>
    );
  }
}
