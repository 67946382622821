import { PortableTextBlock } from '@portabletext/types';
import { AvoidanceAssessment } from '../Types/avoidance-assessment';
import { Choice } from '../Types/choice';
import { Module } from '../Types/module';
import { Patient } from '../Types/patient';
import { Question } from '../Types/question';
import { Session } from '../Types/session';
import { Symptom } from '../Types/symptom';
import { axiosWithAuth } from './axios';
import axios from 'axios';
import { AvoidanceQuestion } from '../Types/avoidance-question';

export const getPatients = async (
  page?: number,
  limit?: number,
  query?: string
): Promise<{
  patients: Array<Partial<Patient>>;
  totalPages: Number;
  currentPage: Number;
}> => {
  const url = new URL(`${process.env.REACT_APP_API_ROOT}/patients`);
  if (page) url.searchParams.append('page', page.toString());
  if (limit) url.searchParams.append('limit', limit.toString());
  if (query) url.searchParams.append('q', query);
  const { data } = await axiosWithAuth.get(url.href);
  return data;
};

export const getQuestions = async (): Promise<Question[]> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/sanity/getQuestions`
  );
  return data;
};

export const getAAQuestions = async (): Promise<AvoidanceQuestion[]> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/sanity/getAAQuestions`
  );
  return data;
};

export const getAvoidanceAssessments = async (): Promise<
  AvoidanceAssessment[]
> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/sanity/getAvoidanceAssessments`
  );
  return data;
};

export const getAvoidanceAssessment = async (
  instance: string
): Promise<AvoidanceAssessment> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/sanity/getAvoidanceAssessment?instance=${instance}`
  );
  return data;
};

export const getQuestion = async (key: string): Promise<Question> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/sanity/getQuestion?key=${key}`
  );
  return data;
};

export const getChoicesByQuestion = async (
  questionKey: string
): Promise<Choice[]> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/sanity/getChoicesByQuestion?questionKey=${questionKey}`
  );
  return data;
};

export const getPatient = async (patientId: string): Promise<Patient> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/patients/${patientId}`
  );
  return data;
};

export const getSession = async (sessionId: string): Promise<Session> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/sessions/${sessionId}`
  );
  return data;
};

export const getModulesByIds = async (
  moduleIds: number[]
): Promise<Module[]> => {
  const { data } = await axiosWithAuth.get(
    `${
      process.env.REACT_APP_API_ROOT
    }/sanity/getModulesByIds?moduleIds=${encodeURIComponent(
      moduleIds.toString()
    )}`
  );
  return data;
};

export const getSymptoms = async (): Promise<Symptom[]> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/sanity/getSymptoms`
  );
  return data;
};

export const getThePlan = async (): Promise<{
  calmThoughts: string[];
  controlThoughts: string[];
  symptoms: {
    _id: string;
    body: string;
    continuous: boolean;
  }[];
  responses: {
    _id: string;
    body: string;
    continuous: boolean;
    symptom: string;
  }[];
}> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/sanity/getThePlan`
  );
  return data;
};

export const getRetrainingGains = async (): Promise<{
  gainCategories: {
    _id: string;
    name: string;
  }[];
  gains: {
    _id: string;
    body: string;
    category: string;
  }[];
  modalInstructions: string;
  modalWarning: string;
}> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/sanity/getRetrainingGains`
  );
  return data;
};

export const getAppHomePage = async (): Promise<{
  title: string;
  image: string;
  body: PortableTextBlock[];
}> => {
  const { data } = await axios.get(
    `${process.env.REACT_APP_API_ROOT}/sanity/getAppHomePage`
  );
  return data;
};

export const getDashboardStats = async (): Promise<{
  totalPatientsCount: number;
  graduatedPatientsCount: number;
  totalSessionsCount: number;
}> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/dashboard`
  );
  return data;
};

export const getDocumentLibrary = async (): Promise<{
  documents: {
    url: string;
    originalFilename: string;
  }[];
}> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/sanity/getDocumentLibrary`
  );
  return data;
};

export const getExportData = async (): Promise<Patient[]> => {
  const { data } = await axiosWithAuth.get(
    `${process.env.REACT_APP_API_ROOT}/export`
  );
  return data;
};
