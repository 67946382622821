export default function UserNotActive() {
  return (
    <main className="flex flex-col items-center justify-center gap-4 py-20 text-center">
      <h1 className="text-3xl font-semibold">User Account Not Active</h1>
      <p>
        This account exists, but it is not currently active. You will be able to
        access the ReACT Precision Treatment Tool after completing training and
        being given access by the ReACT team. To sign up for training, visit the{' '}
        <a href="https://reactfnd.health/providers" className="underline">
          For Providers
        </a>{' '}
        page.
      </p>
      <p>
        If you just signed up, please try{' '}
        <span
          className="cursor-pointer underline"
          onClick={() => window.location.reload()}
        >
          refreshing this page
        </span>{' '}
        to make sure your new account is activated.
      </p>
    </main>
  );
}
