import { useMutation, useQueryClient } from '@tanstack/react-query';
import { X } from 'react-feather';
import { deleteSession } from '../Utils/mutations';
import { useNavigate } from 'react-router-dom';

export default function DeleteSessionButton({ id }: { id: string }) {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: () => deleteSession(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['patients'] });
      navigate(0);
    },
  });

  return (
    <button
      type="button"
      className="hover:text-red-600 rounded-full p-0.5 font-bold hover:bg-white"
      title="Delete Session"
      onClick={() => {
        if (window.confirm('Are you sure you want to delete this session?')) {
          mutation.mutate();
        }
      }}
    >
      <X size={16} strokeWidth={3} />
    </button>
  );
}
