import { Loader } from 'react-feather';

interface LoadingProps {
  span?: boolean;
}

export default function Loading({ span }: LoadingProps) {
  const LoadingSpan = () => (
    <span className="animate-spin">
      <Loader size={20} />
    </span>
  );

  if (span) {
    return <LoadingSpan />;
  } else {
    return (
      <main className="flex w-full items-center justify-center py-20">
        <LoadingSpan />
      </main>
    );
  }
}
