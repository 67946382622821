import { Choice } from '../Types/choice';
import { Question } from '../Types/question';
import clsx from 'clsx';
import { AppData, useAppContext } from '../Context/AppContext';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addResponse, addModules } from '../Utils/mutations';
import { useNavigate } from 'react-router-dom';
import { AvoidanceAssessment } from '../Types/avoidance-assessment';

export default function ChoiceDisplay({
  choice,
  question,
}: {
  choice: Choice;
  question: Question;
}) {
  const { appData, setStateAndLocalStorage } = useAppContext();
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutation = useMutation({
    mutationFn: () =>
      addResponse(appData!.session!._id, question.key, choice.answer),
    onSuccess: async () => {
      setStateAndLocalStorage({
        ...appData,
        session: {
          ...appData?.session,
          responses: [
            ...(appData?.session?.responses || []),
            { question: question.key, answer: choice.answer },
          ],
        },
      } as AppData);
      await queryClient.invalidateQueries({
        queryKey: ['question', question.key],
      });
      switch (choice.nextStepType) {
        case 'Question':
          navigate(`/question/${(choice.nextStep as Question).key}`);
          break;
        case 'Avoidance Assessment':
          navigate(
            `/avoidance-assessment/${
              (choice.nextStep as AvoidanceAssessment).instance
            }`
          );
          break;
        case 'Module Review':
          navigate(`/module-review`);
          break;
        default:
          return false;
      }
    },
  });

  const moduleMutation = useMutation({
    mutationFn: () =>
      addModules(
        appData!.session!._id,
        choice.modules.map((module) => module.key)
      ),
    onSuccess: async () => {
      let newModules = new Set(appData?.session?.modules || []);

      choice.modules.forEach((module) => newModules.add(module.key));

      setStateAndLocalStorage({
        ...appData,
        session: {
          ...appData?.session,
          modules: Array.from(newModules),
        },
      } as AppData);
      mutation.mutate();
    },
  });

  // const isCurrrentResponse = appData?.session?.responses?.some((r) => {
  //   return r.answer === choice.answer && r.question === question.key;
  // });

  // Occurance
  const isAny = choice.occurance.includes('any');
  const hasPlus = choice.occurance.includes('+');
  const currentOccurance = parseInt(choice.occurance.replace('+', ''));
  const previousResponses =
    appData?.patient?.sessions
      .map((s) => s.responses)
      .flat()
      .filter((r) => {
        return r?.answer === choice.answer && r?.question === question.key;
      }).length || 0;
  const isCurrentOccurance = isAny
    ? true
    : hasPlus
    ? currentOccurance <= previousResponses + 1
    : currentOccurance === previousResponses + 1;

  // Dependency
  const dependencyFulfilled = appData?.session?.responses?.some((r) => {
    return (
      r.answer === choice?.choiceDependency?.answer &&
      r.question === parseInt(choice.choiceDependency.question as string)
    );
  });

  const hasDependencyOccurance =
    choice.choiceDependencyOccurance === 'none' ? false : true;

  const depedencyOccurance = choice.choiceDependencyOccurance.split(',');

  const previousDependencyResponses =
    appData?.patient?.sessions
      .map((s) => s.responses)
      .flat()
      .filter(
        (r) =>
          r?.answer === choice?.choiceDependency?.answer &&
          r?.question === parseInt(choice?.choiceDependency?.question as string)
      ).length || 0;

  // add same response from current session if applicable
  const currentDependencyResponse =
    appData?.session?.responses?.filter(
      (r) =>
        r?.answer === choice?.choiceDependency?.answer &&
        r?.question === parseInt(choice?.choiceDependency?.question as string)
    ).length || 0;

  const totalDependencyResponses =
    previousDependencyResponses + currentDependencyResponse;

  const dependencyOccuranceHasPlus = depedencyOccurance
    .slice(-1)[0]
    .includes('+');
  const dependencyOccuranceLimit = parseInt(
    depedencyOccurance.slice(-1)[0].replace('+', '')
  );

  const isCurrentDependencyOccurance =
    depedencyOccurance.includes(totalDependencyResponses.toString()) ||
    (dependencyOccuranceHasPlus &&
      totalDependencyResponses >= dependencyOccuranceLimit);

  const isCurrentDependency =
    !choice.choiceDependency ||
    (dependencyFulfilled && !hasDependencyOccurance) ||
    (dependencyFulfilled && isCurrentDependencyOccurance);

  const isCurrentAvoidanceAssessmentDependency =
    !choice.avoidanceAssessmentDependency ||
    appData?.session?.avoidanceAssessmentResponses?.some((aa) => {
      const dependencyIsMet =
        choice.avoidanceClauseType === 'AND'
          ? choice.avoidanceAnswers.every(
              (avoidanceAnswer) =>
                avoidanceAnswer.answer.toLowerCase() ===
                JSON.parse(aa.answers || '[]')[`q-${avoidanceAnswer.question}`]
            ) // Condition: AND; ALL of these answers happened in our responses
          : choice.avoidanceAnswers.some(
              (avoidanceAnswer) =>
                avoidanceAnswer.answer.toLowerCase() ===
                JSON.parse(aa.answers || '[]')[`q-${avoidanceAnswer.question}`]
            ); // Condition: OR; AT LEAST ONE of these answers happened in our responses

      return (
        aa.instance === choice.avoidanceAssessmentDependency && dependencyIsMet
      );
    });

  if (
    !isCurrentOccurance ||
    !isCurrentDependency ||
    !isCurrentAvoidanceAssessmentDependency
  )
    return null;

  const handleResponse = async (questionKey: number, answer: string) => {
    if (choice.modules?.length > 0) {
      moduleMutation.mutate();
    } else {
      mutation.mutate();
    }
  };

  return (
    <button
      type="button"
      key={choice._id}
      className={clsx(
        'w-40 rounded-lg bg-dark-blue bg-opacity-95 p-4 text-center uppercase text-white hover:bg-opacity-100 hover:shadow',
        'disabled:cursor-not-allowed disabled:opacity-80',
        'active:bg-opacity-80'
      )}
      onClick={() => {
        handleResponse(question.key, choice.answer);
      }}
      disabled={!mutation.isIdle || !moduleMutation.isIdle}
    >
      <h3 className="text-xl font-semibold">{choice.answer}</h3>
    </button>
  );
}
