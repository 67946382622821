import { Listbox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { useEffect, useState } from 'react';
import {
  CheckSquare,
  ChevronDown,
  ChevronRight,
  Clock,
  Square,
  X,
} from 'react-feather';
import SvgIcon from './SvgIcon';
import Button from './Button';
import { retrainType, thePlanType } from '../Pages/ThePlan';

const ListBoxOption = ({
  option,
}: {
  option: { _id: string; body: string };
}) => {
  return (
    <Listbox.Option
      value={option._id}
      className={clsx(
        `ui-active:bg-blue ui-not-active:bg-transparent`,
        `flex cursor-default select-none items-center gap-4 px-8 py-3 text-lg`,
        `ui-selected:font-bold ui-not-selected:font-semibold`
      )}
    >
      <span className={clsx(`block`)}>{option.body}</span>
    </Listbox.Option>
  );
};

export default function RetrainTheSymptoms({
  thePlan,
  setThePlan,
  symptoms,
  responses,
}: {
  thePlan: thePlanType;
  setThePlan: React.Dispatch<React.SetStateAction<any>>;
  symptoms: {
    _id: string;
    body: string;
    continuous: boolean;
  }[];
  responses: {
    _id: string;
    body: string;
    symptom: string;
    continuous: boolean;
  }[];
}) {
  const [selectedSymptom, setSelectedSymptom] = useState<string>('');
  const [otherSymptom, setOtherSymptom] = useState<string>('');
  const [selectedResponses, setSelectedResponses] = useState<string[]>([]);
  const [otherResponse, setOtherResponse] = useState<string>('');
  const [selectedWhen, setSelectedWhen] = useState<string>('');

  useEffect(() => {
    setSelectedResponses([]);
    setOtherSymptom('');
    setOtherResponse('');
    setSelectedWhen('');
  }, [selectedSymptom]);

  const handleAddRetrain = () => {
    setThePlan((prevState: thePlanType) => {
      return {
        ...prevState,
        retrains: prevState.retrains
          ? JSON.stringify([
              ...JSON.parse(prevState.retrains),
              {
                symptom: selectedSymptom,
                otherSymptom,
                responses: selectedResponses,
                otherResponse,
                when: selectedWhen,
              },
            ])
          : JSON.stringify([
              {
                symptom: selectedSymptom,
                otherSymptom,
                responses: selectedResponses,
                otherResponse,
                when: selectedWhen,
              },
            ]),
      };
    });
  };

  const handleRemoveRetrain = (index: number) => {
    setThePlan((prevState: thePlanType) => {
      return {
        ...prevState,
        retrains: JSON.stringify(
          JSON.parse(prevState.retrains).filter(
            (_: any, i: number) => i !== index
          )
        ),
      };
    });
  };

  useEffect(() => {
    setSelectedSymptom('');
    setOtherSymptom('');
    setSelectedResponses([]);
    setOtherResponse('');
    setSelectedWhen('');
    // setValue('when', '');
    // setValue('retrains', JSON.stringify(retrains));
    // TODO: set retrains in the parent component
  }, [thePlan.retrains]);

  const continuousSymptoms = symptoms.filter((symptom) => symptom.continuous);
  const episodicSymptoms = symptoms.filter((symptom) => !symptom.continuous);

  return (
    <>
      <div className="flex items-center gap-8 px-4 py-2">
        <div className="flex w-2/5 items-center justify-center gap-2 text-2xl font-semibold print:text-lg">
          <div className="h-9 w-9 rounded-full bg-light-salmon p-1.5">
            <SvgIcon name="symptoms" />
          </div>
          <h4>My Symptoms</h4>
        </div>
        <div className="flex flex-1 items-center justify-center gap-2 text-2xl font-semibold print:text-lg">
          <div className="h-9 w-9 rounded-full bg-light-salmon p-1.5">
            <SvgIcon name="response" />
          </div>
          <h4>Opposing Response</h4>
        </div>
      </div>
      {thePlan.retrains.length > 0 &&
        JSON.parse(thePlan.retrains).length > 0 && (
          <div className="flex flex-col px-4 py-2 text-lg font-semibold print:text-base">
            {JSON.parse(thePlan.retrains).map(
              (retrain: retrainType, index: number) => (
                <div
                  key={index}
                  className="flex w-full items-baseline gap-8 border-b border-dark-blue bg-white p-4"
                >
                  <div className="w-2/5">
                    {retrain.symptom === 'other'
                      ? retrain.otherSymptom
                      : symptoms.find(
                          (symptom) => symptom._id === retrain.symptom
                        )?.body}
                  </div>

                  <div className="flex flex-1 flex-col gap-4">
                    <ul className="list-disc">
                      {retrain.symptom === 'other' ? (
                        <li>{retrain.otherResponse}</li>
                      ) : (
                        retrain.responses.map((response) => (
                          <li key={response}>
                            {responses.find(
                              (responseItem) => responseItem._id === response
                            )?.body ?? ''}
                          </li>
                        ))
                      )}
                    </ul>
                    {retrain.when && (
                      <div className="flex items-center gap-2 font-normal italic">
                        <Clock />
                        <span>{retrain.when}</span>
                      </div>
                    )}
                  </div>
                  <button
                    className="p-1 text-grey hover:text-dark-blue print:hidden"
                    onClick={() => handleRemoveRetrain(index)}
                    type="button"
                  >
                    <X />
                  </button>
                </div>
              )
            )}
          </div>
        )}
      <div className="flex gap-8 px-4 py-2 print:hidden">
        <div className="relative flex w-2/5 flex-col gap-4">
          <Listbox value={selectedSymptom} onChange={setSelectedSymptom}>
            <Listbox.Button className="flex w-full items-center justify-between rounded-xl border border-dark-blue p-4 text-start text-lg font-semibold">
              {selectedSymptom === ''
                ? '+ Add Symptom'
                : selectedSymptom === 'other'
                ? 'Other'
                : symptoms.find((symptom) => symptom._id === selectedSymptom)
                    ?.body}
              <ChevronDown />
            </Listbox.Button>
            <Transition>
              <Listbox.Options className="absolute z-10 max-h-[400px] w-full overflow-y-auto rounded-b-xl bg-white py-4 shadow-xl shadow-slate-500">
                <h5 className="py-4 text-center text-xl font-bold underline underline-offset-4">
                  Episodic Symptoms
                </h5>
                {episodicSymptoms.map((symptom) => (
                  <ListBoxOption key={symptom._id} option={symptom} />
                ))}
                <ListBoxOption option={{ _id: 'other', body: 'Other' }} />
                <h5 className="py-4 text-center text-xl font-bold underline underline-offset-4">
                  Continuous Symptoms
                </h5>
                {continuousSymptoms.map((symptom) => (
                  <ListBoxOption key={symptom._id} option={symptom} />
                ))}
                <ListBoxOption option={{ _id: 'other', body: 'Other' }} />
              </Listbox.Options>
            </Transition>
          </Listbox>
          {selectedSymptom === 'other' && (
            <div>
              <input
                type="text"
                placeholder="Other Symptom"
                className="w-full rounded-xl border border-dark-blue p-4 text-lg font-semibold"
                defaultValue={otherSymptom}
                onChange={(e) => setOtherSymptom(e.target.value)}
              />
            </div>
          )}
        </div>
        <div className="relative flex flex-1 flex-col gap-4">
          <Listbox
            value={selectedResponses}
            onChange={setSelectedResponses}
            disabled={selectedSymptom === '' || selectedSymptom === 'other'}
            multiple
          >
            <Listbox.Button className="flex w-full items-center justify-between rounded-xl border border-dark-blue p-4 text-lg font-semibold disabled:text-grey">
              {selectedResponses.length > 0
                ? `${selectedResponses.length} Selected Response${
                    selectedResponses.length > 1 ? 's' : ''
                  }`
                : 'Select Response(s)'}
              <ChevronRight />
            </Listbox.Button>
            <Transition>
              <Listbox.Options className="absolute z-10 max-h-[400px] w-full overflow-y-auto rounded-b-xl bg-white py-4 shadow-xl shadow-slate-500">
                {responses
                  .filter((r) => r.symptom === selectedSymptom)
                  .map((response) => (
                    <Listbox.Option
                      key={response._id}
                      value={response._id}
                      className={clsx(
                        `ui-active:bg-blue ui-not-active:bg-transparent`,
                        `flex cursor-default select-none items-start gap-4 px-8 py-3 text-lg`,
                        `ui-selected:font-bold ui-not-selected:font-semibold`
                      )}
                    >
                      <CheckSquare
                        aria-hidden="true"
                        className={clsx(
                          'ui-selected:block',
                          'hidden flex-none'
                        )}
                      />
                      <Square
                        aria-hidden="true"
                        className={clsx(
                          'ui-selected:hidden',
                          'block flex-none'
                        )}
                      />
                      <span className={clsx(`block`)}>{response.body}</span>
                    </Listbox.Option>
                  ))}
              </Listbox.Options>
            </Transition>
          </Listbox>
          {selectedSymptom === 'other' && (
            <div>
              <input
                type="text"
                placeholder="Custom Response"
                className="w-full rounded-xl border border-dark-blue p-4 text-lg font-semibold"
                defaultValue={otherResponse}
                onChange={(e) => setOtherResponse(e.target.value)}
              />
            </div>
          )}
        </div>
      </div>
      {symptoms?.find((s) => selectedSymptom === s._id)?.continuous && (
        <div className="flex gap-8 px-4 py-2 print:hidden">
          <div className="relative w-2/5">
            <label className="flex justify-center gap-2 p-4 text-xl font-semibold">
              <Clock />
              <h3>When to Practice / How Often:</h3>
            </label>
          </div>
          <div className="relative flex-1">
            <input
              type="text"
              placeholder="Every day, 3 times per week, etc."
              className="w-full rounded-xl border border-dark-blue p-4 text-lg font-semibold"
              value={selectedWhen}
              onChange={(e) => setSelectedWhen(e.target.value)}
            />
          </div>
        </div>
      )}
      {(selectedSymptom !== '' || otherSymptom !== '') &&
        (selectedResponses.length > 0 || otherResponse !== '') && (
          <Button
            variant="primary"
            size="sm"
            onClick={handleAddRetrain}
            type="button"
          >
            + Add Symptom &amp; Responses
          </Button>
        )}
    </>
  );
}
