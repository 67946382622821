import { useMutation, useQueryClient } from '@tanstack/react-query';
import { XCircle } from 'react-feather';
import { deletePatient } from '../Utils/mutations';

export default function DeletePatientButton({ id }: { id: string }) {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => deletePatient(id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['patients'] });
    },
  });

  return (
    <button
      type="button"
      className="rounded-full font-bold text-red hover:bg-red hover:text-white"
      title="Delete Patient"
      onClick={() => {
        if (window.confirm('Are you sure you want to delete this patient?')) {
          mutation.mutate();
        }
      }}
    >
      <XCircle />
    </button>
  );
}
