import { useAuth0 } from '@auth0/auth0-react';
import { ReactNode, useEffect } from 'react';
import { authInterceptor } from '../Utils/axios';

export default function Protected({ children }: { children: ReactNode }) {
  const { getAccessTokenSilently } = useAuth0();

  // On app load, passes getAccessTokenSilently function to
  // custom axios instance. This function is used within the
  // custom instance to create an interceptor for getting access
  // tokens prior to fetch.
  useEffect(() => {
    authInterceptor(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  return <>{children}</>;
}
