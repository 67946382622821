import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../Context/AppContext';
import { Play } from 'react-feather';

export default function IntakeButton() {
  const { appData } = useAppContext();
  const navigate = useNavigate();
  return (
    <button
      type="button"
      className="flex items-center gap-2 rounded-full bg-green-600 px-4 py-2 font-semibold text-white hover:shadow disabled:opacity-60"
      disabled={!appData?.patient || !appData?.session}
      onClick={() => {
        navigate('/intake');
      }}
    >
      Intake <Play size={16} fill="currentColor" />
    </button>
  );
}
