import { useAppContext } from '../Context/AppContext';
import { AvoidanceAssessment } from '../Types/avoidance-assessment';
import AddModulesButton from './AddModulesButton';
import { Module } from '../Types/module';
import { Link } from 'react-router-dom';
import clsx from 'clsx';

export default function AvoidanceAssessmentConditions({
  avoidanceAssessment,
}: {
  avoidanceAssessment: AvoidanceAssessment;
}) {
  const { appData } = useAppContext();

  const thisInstancesAnswers =
    appData?.session?.avoidanceAssessmentResponses?.find(
      (response) => response.instance === avoidanceAssessment.instance
    );

  const hasBeenAnsweredThisSession = thisInstancesAnswers !== undefined;

  let conditionsAreMet: { index: number; conditionIsMet: boolean }[] = [];

  return (
    <>
      <h4 className="text-center text-xl">
        {avoidanceAssessment.instance} hasBeenAnsweredThisSession:{' '}
        {hasBeenAnsweredThisSession.toString()}
      </h4>
      <div className="flex w-full justify-evenly gap-8">
        {avoidanceAssessment.avoidanceAssessmentConditions.map(
          (condition, i) => {
            const conditionIsMet =
              condition.clauseType === 'AND'
                ? condition.avoidanceAnswers.every(
                    (avoidanceAnswer) =>
                      avoidanceAnswer.answer.toLowerCase() ===
                      JSON.parse(thisInstancesAnswers?.answers || '[]')[
                        `q-${avoidanceAnswer.question}`
                      ]
                  ) // Condition: AND; ALL of these answers happened in our responses
                : condition.avoidanceAnswers.some(
                    (avoidanceAnswer) =>
                      avoidanceAnswer.answer.toLowerCase() ===
                      JSON.parse(thisInstancesAnswers?.answers || '[]')[
                        `q-${avoidanceAnswer.question}`
                      ]
                  ); // Condition: OR; AT LEAST ONE of these answers happened in our responses

            conditionsAreMet.push({ index: i, conditionIsMet });

            const isFirstConditionMet =
              conditionsAreMet.find((condition) => condition.conditionIsMet)
                ?.index === i;

            return (
              <div
                key={condition._id}
                className={clsx(
                  'flex flex-1 flex-col gap-4 rounded-md bg-white p-4 shadow-md',
                  {
                    'opacity-40': !conditionIsMet && hasBeenAnsweredThisSession,
                    'border-2 border-green-500': isFirstConditionMet,
                  }
                )}
              >
                <h5 className="text-lg font-semibold">If you answer:</h5>
                <ul className="flex-1 list-inside list-disc">
                  {condition.avoidanceAnswers.map((answer: any) => (
                    <li key={`${answer.answer}-${answer.question}`}>
                      {answer.answer} to Question {answer.question}
                      {` `}
                      <strong>{condition.clauseType}</strong>
                    </li>
                  ))}
                </ul>
                <div className="flex flex-col items-end gap-1">
                  {condition.modules && (
                    <AddModulesButton
                      modules={condition.modules.map(
                        (module: Module) => module.key
                      )}
                    />
                  )}
                  <div className="flex gap-1">
                    {condition.modules &&
                      condition.modules.map((module: Module) => (
                        <div
                          key={`module-${module.key}`}
                          className="bg-blue-300 flex h-10 w-10 items-center justify-center rounded-full shadow"
                          title={module.title}
                        >
                          {module.key}
                        </div>
                      ))}
                  </div>
                </div>

                <div>
                  <h5 className="text-lg font-semibold">You go to:</h5>
                  {condition.nextStepType === 'Question' && (
                    <div className="flex items-center justify-center gap-4">
                      <h4>{condition.nextStepType}</h4>
                      <Link
                        to={`/question/${condition.nextQuestion}`}
                        className="flex h-10 w-10 items-center justify-center rounded-full bg-yellow-400 shadow hover:bg-yellow-500"
                      >
                        {condition.nextQuestion}
                      </Link>
                    </div>
                  )}
                  {condition.nextStepType === 'Module Review' && (
                    <Link
                      to={`/module-review`}
                      className="flex items-center justify-center rounded-full bg-gray-300 px-4 py-2 shadow hover:bg-gray-400"
                    >
                      Module Review
                    </Link>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>
    </>
  );
}
