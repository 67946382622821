import { useNavigate } from 'react-router-dom';
import { useAppContext } from '../Context/AppContext';

export default function MenuButton() {
  const { appData } = useAppContext();
  const navigate = useNavigate();
  return (
    <button
      type="button"
      className="flex items-center gap-2 rounded-full bg-slate-500 px-4 py-2 font-semibold text-white hover:shadow disabled:opacity-60"
      disabled={!appData?.patient || !appData?.session}
      onClick={() => {
        navigate('/menu');
      }}
    >
      Menu
    </button>
  );
}
