import { Printer } from 'react-feather';

export default function PrintButton() {
  return (
    <button
      type="button"
      onClick={() => window.print()}
      className="flex items-center gap-2 text-lg font-semibold print:hidden"
    >
      <Printer />
      Print
    </button>
  );
}
