import { useForm } from 'react-hook-form';
import { AppData, useAppContext } from '../Context/AppContext';
import SubmitAndClearButtons from './SubmitAndClearButtons';
import { useMutation } from '@tanstack/react-query';
import { EpisodicSymptom } from '../Types/episodic-symptom';
import { addModules, addSymptoms } from '../Utils/mutations';
import { useNavigate } from 'react-router-dom';

export default function EpisodicSymptomsForm() {
  const { appData, setStateAndLocalStorage } = useAppContext();

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm();

  const episodicSymptoms = appData?.session?.episodicSymptoms ?? [];

  const boosterModuleMutation = useMutation({
    mutationFn: () => addModules(appData!.session!._id, [120]),
    onSuccess: () => {
      setStateAndLocalStorage({
        ...appData,
        session: {
          ...appData?.session,
          modules: [...(appData?.session?.modules ?? []), 120],
        },
      } as AppData);
      navigate('/module-review');
    },
  });

  const mutation = useMutation({
    mutationFn: (params: { episodicSymptoms: EpisodicSymptom[] }) =>
      addSymptoms(
        appData!.session!._id,
        appData!.session!.continuousSymptoms,
        params.episodicSymptoms
      ),
    onSuccess: (res, params) => {
      if (appData?.session?.sessionNumber === 1) {
        navigate('/module-review');
      } else if (
        // IF this is the first Booster session (with episodic symptoms)
        appData?.session?.sessionNumber === appData?.patient?.boosterStart
      ) {
        // add the Booster Start module () and go to Module Review
        boosterModuleMutation.mutate();
      } else if (
        // IF the session is after the first Booster session (with episodic symptoms)
        appData?.patient?.boosterStart &&
        appData?.session?.sessionNumber! > appData?.patient?.boosterStart
      ) {
        // go to question 20
        navigate('/question/20');
      } else {
        navigate('/question/1');
      }
    },
  });

  const formSubmit = (data: any) => {
    const episodicSymptomsWithOccurances = episodicSymptoms.map((symptom) => ({
      ...symptom,
      occurances: data[symptom._id],
    }));

    setStateAndLocalStorage({
      ...appData,
      session: {
        ...appData?.session,
        episodicSymptoms: episodicSymptomsWithOccurances,
      },
    } as AppData);

    mutation.mutate({ episodicSymptoms: episodicSymptomsWithOccurances });
  };

  return (
    <form onSubmit={handleSubmit(formSubmit)}>
      <div className="flex flex-col items-center gap-4 rounded-lg bg-lighter-salmon bg-opacity-50 px-12 py-6 text-center">
        <h3 className="text-lg font-bold">Question</h3>
        <h2 className="text-balance text-3xl font-bold [text-wrap:balance]">
          {appData?.session?.sessionNumber === 1
            ? 'On average how many episodes do you have per week?'
            : 'How many times did the following indicated Episodic symptoms occur?'}
        </h2>
      </div>
      <div className="mt-8 flex items-end justify-end gap-4 px-2 text-center text-sm">
        {/* <label className="w-24">Previously</label> */}
        <label className="w-24">Occurrences</label>
      </div>
      <ol className="list-inside list-decimal text-xl font-semibold marker:relative">
        {episodicSymptoms.map((symptom, i) => (
          <li
            key={symptom._id}
            className="flex items-center gap-4 border-b border-grey px-2 py-4 last:border-0"
          >
            <div className="font-bold">{i + 1}.</div>
            <div className="flex-1">{symptom.name}</div>
            <label className="w-24 text-center">
              <input
                type="number"
                placeholder="Enter #"
                {...register(symptom._id, { required: true })}
                className="w-24"
                min={0}
                step={0.01}
              />
            </label>
          </li>
        ))}
      </ol>
      <SubmitAndClearButtons disabled={isSubmitting || !isValid}>
        Next
      </SubmitAndClearButtons>
    </form>
  );
}
