import { useNavigate } from 'react-router-dom';
import { addPatient } from '../Utils/mutations';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { Patient } from '../Types/patient';
import clsx from 'clsx';
import Button from '../Components/Button';
import { ArrowRight } from 'react-feather';
import { ReactNode } from 'react';
import { AppData, useAppContext } from '../Context/AppContext';
import { states } from '../Utils/constants';
import { getLastSunday } from '../Utils/helpers';

const labelClasses = clsx('font-semibold text-dark-blue text-xl');

export default function AddPatientPage() {
  const navigate = useNavigate();

  const { appData, setStateAndLocalStorage } = useAppContext();

  const {
    register,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const mutation = useMutation({
    mutationFn: (data: Partial<Patient>) => addPatient(data),
    onSuccess: async (res, data) => {
      setStateAndLocalStorage({
        ...appData,
        patient: {
          ...data,
          _id: res.patientId,
        },
        session: {
          sessionNumber: 1,
          patientId: res.patientId,
          sessionDate: getLastSunday(),
        },
      } as AppData);
      navigate('/intake');
    },
    onError: (error: any) => {
      console.log('error', error);
      if (
        error.response.status === 500 &&
        error.response.data.message.includes('duplicate key error')
      ) {
        alert('Patient ID already exists. Please enter a different ID.');
      }
    },
  });

  const formSubmit = async (data: Partial<Patient>) => {
    mutation.mutate(data);
  };

  const IntakeQuestion = ({
    questionLabel,
    errors,
    fieldName,
    children,
  }: {
    questionLabel: string | ReactNode;
    errors: any;
    fieldName: string;
    children: ReactNode;
  }) => (
    <div className="py-8">
      <div
        className={clsx('flex flex-col gap-4 rounded-xl bg-opacity-30 p-4', {
          'bg-salmon': errors,
        })}
      >
        <label htmlFor={fieldName} className={labelClasses}>
          {questionLabel}
        </label>
        {children}
      </div>
    </div>
  );

  return (
    <main className="rounded-t-xl bg-white font-figtree text-dark-blue shadow-xl shadow-slate-500">
      <form
        className="mx-auto flex w-full max-w-2xl flex-col divide-y divide-grey"
        onSubmit={handleSubmit(formSubmit)}
      >
        <IntakeQuestion
          questionLabel="Patient #"
          fieldName="patientId"
          errors={errors.patientId}
        >
          <input
            type="number"
            {...register('patientId', { required: true })}
            id="patientId"
            min={0}
          />
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="Age"
          fieldName="age"
          errors={errors.age || errors.isChild}
        >
          <input
            type="number"
            {...register('age', { required: true })}
            id="age"
            min={0}
          />
          <p>
            Please indicate if this patient will undergo treatment as a Child or
            an Adult.
          </p>
          <div className="flex items-center gap-4">
            <input
              type="radio"
              {...register('isChild', { required: true })}
              id="isChildTrue"
              value="true"
            />
            <label htmlFor="isChildTrue">
              <strong>Child</strong>: Parent/guardian and child will be seen
              individually and together during sessions.
            </label>
          </div>
          <div className="flex items-center gap-4">
            <input
              type="radio"
              {...register('isChild', { required: true })}
              id="isChildFalse"
              value="false"
            />
            <label htmlFor="isChildFalse">
              <strong>Adult</strong>: If the patient consents, family members
              will be included in parts of sessions, but family members will not
              be seen without the patient present.
            </label>
          </div>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="Sex assigned at birth"
          fieldName="sex"
          errors={errors.sex}
        >
          <div className="flex items-center gap-8">
            <div className="flex items-center gap-2">
              <label htmlFor="sexMale">Male</label>
              <input
                type="radio"
                {...register('sex', { required: true })}
                id="sexMale"
                value="Male"
              />
            </div>
            <div className="flex items-center gap-2">
              <label htmlFor="sexFemale">Female</label>
              <input
                type="radio"
                {...register('sex', { required: true })}
                id="sexFemale"
                value="Female"
              />
            </div>
            <div className="flex items-center gap-2">
              <label htmlFor="sexIntersex">Intersex</label>
              <input
                type="radio"
                {...register('sex', { required: true })}
                id="sexIntersex"
                value="Intersex"
              />
            </div>
          </div>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="Gender"
          fieldName="gender"
          errors={errors.gender}
        >
          <div className="flex flex-col items-start gap-4">
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('gender', { required: true })}
                id="genderMale"
                value="Male"
              />
              <label htmlFor="genderMale">Male</label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('gender', { required: true })}
                id="genderFemale"
                value="Female"
              />
              <label htmlFor="genderFemale">Female</label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('gender', { required: true })}
                id="genderFTM"
                value="Transgender man/trans man/female-to-male (FTM)"
              />
              <label htmlFor="genderFTM">
                Transgender man/trans man/female-to-male (FTM)
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('gender', { required: true })}
                id="genderMTF"
                value="Transgender women/trans woman/male-to-female (MTF)"
              />
              <label htmlFor="genderMTF">
                Transgender women/trans woman/male-to-female (MTF)
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('gender', { required: true })}
                id="genderGenderqueer"
                value="Genderqueer, gender nonconforming, neither exclusively male or female"
              />
              <label htmlFor="genderGenderqueer">
                Genderqueer, gender nonconforming, neither exclusively male or
                female
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('gender', { required: true })}
                id="genderOther"
                value="Other"
              />
              <div className="flex flex-col gap-2">
                <label htmlFor="genderOther">
                  Additional gender category (or other), please specify
                </label>
              </div>
              <input
                type="text"
                {...register('genderOther')}
                id="genderOther"
              />
            </div>
          </div>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="Race"
          fieldName="race"
          errors={errors.race}
        >
          <div className="flex flex-col items-start gap-4">
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('race', { required: true })}
                id="raceIndian"
                value="American Indian/Alaska Native"
              />
              <label htmlFor="raceIndian">American Indian/Alaska Native</label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('race', { required: true })}
                id="raceAsian"
                value="Asian"
              />
              <label htmlFor="raceAsian">Asian</label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('race', { required: true })}
                id="raceHawaiian"
                value="Native Hawaiian or Other Pacific Islander"
              />
              <label htmlFor="raceHawaiian">
                Native Hawaiian or Other Pacific Islander
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('race', { required: true })}
                id="raceBlack"
                value="Black or African American"
              />
              <label htmlFor="raceBlack">Black or African American</label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('race', { required: true })}
                id="raceWhite"
                value="White"
              />
              <label htmlFor="raceWhite">White</label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('race', { required: true })}
                id="raceMultiple"
                value="More than One Race"
              />
              <label htmlFor="raceMultiple">More than One Race</label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('race', { required: true })}
                id="raceUnknown"
                value="Unknown"
              />
              <label htmlFor="raceUnknown">Unknown</label>
            </div>
          </div>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="Ethnicity"
          fieldName="ethnicity"
          errors={errors.ethnicity}
        >
          <div className="flex flex-col items-start gap-4">
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('ethnicity', { required: true })}
                id="ethnicityHispanic"
                value="Hispanic or Latinx"
              />
              <label htmlFor="ethnicityHispanic">Hispanic or Latinx</label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="radio"
                {...register('ethnicity', { required: true })}
                id="ethnicityNotHispanic"
                value="Not Hispanic or Latinx"
              />
              <label htmlFor="ethnicityNotHispanic">
                Not Hispanic or Latinx
              </label>
            </div>
          </div>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="State"
          fieldName="state"
          errors={errors.state}
        >
          <select {...register('state', { required: true })} id="state">
            <option value="">Select a State</option>
            {states.map((s) => (
              <option key={`state-select-${s.value}`} value={s.value}>
                {s.label}
              </option>
            ))}
          </select>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="Time since FND symptoms first began"
          fieldName="fndBeginTimeframe"
          errors={errors.fndBeginTimeframe}
        >
          <input
            type="text"
            {...register('fndBeginTimeframe', { required: true })}
            id="fndBeginTimeframe"
            placeholder="2 years ago, 3 months ago, etc."
          />
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="Time since FND was first diagnosed"
          fieldName="fndDiagnoseTimeframe"
          errors={errors.fndDiagnoseTimeframe}
        >
          <input
            type="text"
            {...register('fndDiagnoseTimeframe', { required: true })}
            id="fndDiagnoseTimeframe"
            placeholder="1 year ago, last month, etc."
          />
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="List comorbid neurological conditions or other functional disorders"
          fieldName="comorbidNeurologicalConditions"
          errors={errors.comorbidNeurologicalConditions}
        >
          <textarea
            {...register('comorbidNeurologicalConditions')}
            id="comorbidNeurologicalConditions"
          ></textarea>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="List family history of neurological conditions or functional disorders"
          fieldName="familyNeurologicalConditions"
          errors={errors.familyNeurologicalConditions}
        >
          <textarea
            {...register('familyNeurologicalConditions')}
            id="familyNeurologicalConditions"
          ></textarea>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="List comorbid psychiatric or mood conditions"
          fieldName="comorbidPsychiatricConditions"
          errors={errors.comorbidPsychiatricConditions}
        >
          <textarea
            {...register('comorbidPsychiatricConditions')}
            id="comorbidPsychiatricConditions"
          ></textarea>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="List family history of psychiatric or mood conditions"
          fieldName="familyPsychiatricConditions"
          errors={errors.familyPsychiatricConditions}
        >
          <textarea
            {...register('familyPsychiatricConditions')}
            id="familyPsychiatricConditions"
          ></textarea>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="What medications are they taking?"
          fieldName="medications"
          errors={errors.medications}
        >
          <textarea {...register('medications')} id="medications"></textarea>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="Have they had treatment for FND before?"
          fieldName="previousTreatment"
          errors={errors.previousTreatment}
        >
          <div className="flex items-center gap-8">
            <div className="flex items-center gap-2">
              <label htmlFor="previousTreatmentYes">Yes</label>
              <input
                type="radio"
                {...register('previousTreatment', { required: true })}
                id="previousTreatmentYes"
                value="Yes"
              />
            </div>
            <div className="flex items-center gap-2">
              <label htmlFor="previousTreatmentNo">No</label>
              <input
                type="radio"
                {...register('previousTreatment', { required: true })}
                id="previousTreatmentNo"
                value="No"
              />
            </div>
          </div>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="If yes, how many times?"
          fieldName="previousTreatmentCount"
          errors={errors.previousTreatmentCount}
        >
          <input
            type="number"
            {...register('previousTreatmentCount')}
            id="previousTreatmentCount"
            min={0}
          />
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel={
            <>
              If yes, what kinds of FND treatment did they have?{' '}
              <em>(Choose all that apply)</em>
            </>
          }
          fieldName="previousTreatmentType"
          errors={errors.previousTreatmentType}
        >
          <div className="flex flex-col items-start gap-4">
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                {...register('previousTreatmentTypeInpatientPsychiatric')}
                id="previousTreatmentTypeInpatientPsychiatric"
                value="Inpatient Psychiatric"
              />
              <label htmlFor="previousTreatmentTypeInpatientPsychiatric">
                Inpatient Psychiatric
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                {...register('previousTreatmentTypeInpatientRehabilitation')}
                id="previousTreatmentTypeInpatientRehabilitation"
                value="Inpatient Rehabilitation"
              />
              <label htmlFor="previousTreatmentTypeInpatientRehabilitation">
                Inpatient Rehabilitation
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                {...register('previousTreatmentTypeOutpatientTherapy')}
                id="previousTreatmentTypeOutpatientTherapy"
                value="Outpatient Therapy"
              />
              <label htmlFor="previousTreatmentTypeOutpatientTherapy">
                Outpatient Therapy
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                {...register('previousTreatmentTypeMedication')}
                id="previousTreatmentTypeMedication"
                value="Medication"
              />
              <label htmlFor="previousTreatmentTypeMedication">
                Medication
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                {...register('previousTreatmentTypeOther')}
                id="previousTreatmentTypeOther"
                value="Other"
              />
              <label htmlFor="previousTreatmentTypeOther">Other</label>
            </div>
          </div>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel={
            <>
              What have they been told causes FND?{' '}
              <em>(Choose all that apply)</em>
            </>
          }
          fieldName="fndCauses"
          errors={errors.fndCauses}
        >
          <div className="flex flex-col items-start gap-4">
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                {...register('fndCauseTrauma')}
                id="fndCauseTrauma"
                value="Trauma"
              />
              <label htmlFor="fndCauseTrauma">Trauma</label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                {...register('fndCauseStress')}
                id="fndCauseStress"
                value="Stress and/or Mood Disorders"
              />
              <label htmlFor="fndCauseStress">
                Stress and/or Mood Disorders
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                {...register('fndCauseAbuse')}
                id="fndCauseAbuse"
                value="Abuse"
              />
              <label htmlFor="fndCauseAbuse">Abuse</label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                {...register('fndCauseChange')}
                id="fndCauseChange"
                value="A change in functioning between the brain and body"
              />
              <label htmlFor="fndCauseChange">
                A change in functioning between the brain and body
              </label>
            </div>
            <div className="flex items-center gap-4">
              <input
                type="checkbox"
                {...register('fndCauseOther')}
                id="fndCauseOther"
                value="Other"
              />
              <label htmlFor="fndCauseOther">Other</label>
            </div>
          </div>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="Are they currently participating in in-person school or work?"
          fieldName="inSchool"
          errors={errors.inSchool}
        >
          <div className="flex items-center gap-8">
            <div className="flex items-center gap-2">
              <label htmlFor="inSchoolYes">Yes</label>
              <input
                type="radio"
                {...register('inSchool', { required: true })}
                id="inSchoolYes"
                value="Yes"
              />
            </div>
            <div className="flex items-center gap-2">
              <label htmlFor="inSchoolNo">No</label>
              <input
                type="radio"
                {...register('inSchool')}
                id="inSchoolNo"
                value="No"
              />
            </div>
          </div>
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="If no, did they stop school or work because of FND symptoms?"
          fieldName="fndStoppedSchool"
          errors={errors.fndStoppedSchool}
        >
          <div className="flex items-center gap-8">
            <div className="flex items-center gap-2">
              <label htmlFor="fndStoppedSchoolYes">Yes</label>
              <input
                type="radio"
                {...register('fndStoppedSchool', {
                  validate: {
                    previous: (v) =>
                      v !== null ||
                      getValues('inSchool') !== 'No' ||
                      'Required when not participating in in-person school.',
                  },
                })}
                id="fndStoppedSchoolYes"
                value="Yes"
              />
            </div>
            <div className="flex items-center gap-2">
              <label htmlFor="fndStoppedSchoolNo">No</label>
              <input
                type="radio"
                {...register('fndStoppedSchool')}
                id="fndStoppedSchoolNo"
                value="No"
              />
            </div>
          </div>
          {errors.fndStoppedSchool?.message && (
            <div className="text-red-700 font-bold italic">
              {errors.fndStoppedSchool?.message.toString()}
            </div>
          )}
        </IntakeQuestion>

        <IntakeQuestion
          questionLabel="If yes, do they want to return to in-person school or work?"
          fieldName="wantToReturnToSchool"
          errors={errors.wantToReturnToSchool}
        >
          <div className="flex items-center gap-8">
            <div className="flex items-center gap-2">
              <label htmlFor="wantToReturnToSchoolYes">Yes</label>
              <input
                type="radio"
                {...register('wantToReturnToSchool', {
                  validate: {
                    previous: (v) =>
                      v !== null ||
                      getValues('fndStoppedSchool') !== 'Yes' ||
                      'Required when stopped school because of FND symptoms.',
                  },
                })}
                id="wantToReturnToSchoolYes"
                value="Yes"
              />
            </div>
            <div className="flex items-center gap-2">
              <label htmlFor="wantToReturnToSchoolNo">No</label>
              <input
                type="radio"
                {...register('wantToReturnToSchool')}
                id="wantToReturnToSchoolNo"
                value="No"
              />
            </div>
          </div>
          {errors.wantToReturnToSchool?.message && (
            <div className="text-red-700 font-bold italic">
              {errors.wantToReturnToSchool?.message.toString()}
            </div>
          )}
        </IntakeQuestion>

        <div className="flex items-center justify-center py-8">
          <Button variant="dark" size="lg" type="submit">
            Start Session <ArrowRight />
          </Button>
        </div>
      </form>
    </main>
  );
}
