import { AppData, useAppContext } from '../Context/AppContext';
import { addModules } from '../Utils/mutations';

export default function AddModulesButton({ modules }: { modules: number[] }) {
  const { appData, setStateAndLocalStorage } = useAppContext();

  const handleAddModules = async () => {
    await addModules(appData!.session!._id, modules);

    let newModules = new Set(appData?.session?.modules || []);

    modules.forEach((module) => newModules.add(module));

    setStateAndLocalStorage({
      ...appData,
      session: {
        ...appData?.session,
        modules: Array.from(newModules),
      },
    } as AppData);
  };

  return (
    <button
      onClick={handleAddModules}
      className="bg-blue-500 hover:bg-blue-600 rounded-full px-2 py-1 text-xs text-white transition-all hover:shadow"
      type="button"
    >
      Add These Modules:
    </button>
  );
}
