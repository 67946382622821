import { Patient } from '../Types/patient';
import { ChangeEventHandler, useState } from 'react';
import { AppData, useAppContext } from '../Context/AppContext';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getPatient } from '../Utils/queries';

export default function PatientSelector({
  patients,
}: {
  patients: Array<Partial<Patient>>;
}) {
  const { appData, setStateAndLocalStorage } = useAppContext();

  const [patientId, setPatientId] = useState<string>();

  const queryClient = useQueryClient();

  useQuery({
    queryKey: ['patient', patientId],
    queryFn: async () => {
      if (!patientId) return null;
      const data = await getPatient(patientId);
      setStateAndLocalStorage({
        patient: data,
        session: null,
        debug: appData?.debug ?? false,
      } as AppData);
      return data;
    },
  });

  const handlePatientSelection: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setPatientId(e.target.value);
    queryClient.invalidateQueries({ queryKey: ['patient'] });
  };

  return (
    <fieldset className="flex flex-col gap-2">
      <label htmlFor="patient">Patient</label>
      <select
        onChange={handlePatientSelection}
        value={appData?.patient?._id ?? 'Select a Patient'}
        id="patient"
        className="rounded-full"
      >
        <option>Select a Patient</option>
        {patients.map((patient) => (
          <option key={patient._id} value={patient._id}>
            Patient {patient.patientId}
          </option>
        ))}
      </select>
    </fieldset>
  );
}
