import { ArrowLeft, Compass } from 'react-feather';
import ChangeThoughts from '../Components/ChangeThoughts';
import RetrainTheSymptoms from '../Components/RetrainTheSymptoms';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import { savePatientThePlan } from '../Utils/mutations';
import { AppData, useAppContext } from '../Context/AppContext';
import { useQuery } from '@tanstack/react-query';
import { getThePlan } from '../Utils/queries';
import NoSessionSet from '../Components/NoSessionSet';
import Loading from '../Components/Loading';
import PrintButton from '../Components/PrintButton';
import { useEffect, useState } from 'react';
import { Copy } from 'react-feather';

export type changeThoughtType = {
  calmThoughts: number[];
  otherCalmThought: string;
  controlThoughts: number[];
  otherControlThought: string;
};

export type retrainType = {
  symptom: string;
  otherSymptom: string;
  responses: string[];
  otherResponse: string;
  when: string;
};

export type thePlanType = {
  changeThoughts: string;
  retrains: string;
};

const PlanHeading = ({ children }: any) => (
  <h3 className="rounded-lg bg-lighter-salmon bg-opacity-50 p-4 text-2xl font-semibold print:p-0 print:text-lg print:text-black print:underline">
    {children}
  </h3>
);

const CopyButton = ({ exportText }: { exportText: string }) => (
  <button
    type="button"
    className="flex items-center gap-2 text-lg font-semibold print:hidden"
    onClick={() => {
      navigator.clipboard.writeText(exportText);
    }}
  >
    <Copy />
    Copy
  </button>
);

export default function ThePlanPage() {
  const { isLoading, error, data } = useQuery({
    queryKey: ['thePlan'],
    queryFn: () => getThePlan(),
  });

  const { appData, setStateAndLocalStorage } = useAppContext();

  const mutation = useMutation({
    mutationFn: (data: string) =>
      savePatientThePlan(appData?.patient?._id!, data),
    onSuccess: (_, data) => {
      setStateAndLocalStorage({
        ...appData,
        patient: {
          ...appData?.patient,
          thePlan: data,
        },
      } as AppData);
    },
  });

  const [thePlan, setThePlan] = useState<thePlanType>(
    JSON.parse(appData?.patient?.thePlan ?? 'null') ?? {
      changeThoughts: '[]',
      retrains: '[]',
    }
  );

  useEffect(() => {
    mutation.mutate(JSON.stringify(thePlan));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thePlan]);

  const exportText = `Retraining Control of Symptoms
Sit
Calm Thoughts and Control Thoughts
${JSON.parse(thePlan?.changeThoughts ?? '[]')
  .map((ct: changeThoughtType) => {
    return `${ct.calmThoughts
      .map((c) => `- ${data?.calmThoughts[c] ?? ct.otherCalmThought}`)
      .join(' ')} | ${ct.controlThoughts
      .map((c) => `- ${data?.controlThoughts[c] ?? ct.otherControlThought}`)
      .join(' ')}`;
  })
  .join('\n')}
Opposing Response for Symptoms
${JSON.parse(thePlan?.retrains ?? '[]')
  .map((r: retrainType) => {
    return `- ${
      r.symptom === 'other'
        ? r.otherSymptom
        : data?.symptoms.find((s) => s._id === r.symptom)?.body
    } | ${
      r.symptom === 'other'
        ? r.otherResponse
        : r.responses
            .map((x) => data?.responses.find((y) => y._id === x)?.body)
            .join('- ')
    }${r.when && ` - When to Practice/How Often: ${r.when}`}`;
  })
  .join('\n')}
Breathe`;

  if (isLoading) {
    return <Loading />;
  }

  if (error instanceof Error) {
    return <span>Error: {error.message}</span>;
  }

  if (!appData?.session) {
    return <NoSessionSet />;
  } else {
    return (
      <main className="flex flex-1 flex-col gap-4 font-figtree text-dark-blue">
        <Link
          to="/module-review"
          className="mb-8 flex items-center gap-2 print:hidden"
        >
          <ArrowLeft size={30} />
          <h3 className="text-xl">Module Review</h3>
        </Link>
        <div className="flex items-baseline justify-end gap-6">
          <div className="mr-auto flex items-center gap-2 text-4xl print:text-2xl">
            <Compass size={40} />
            <h1 className="font-bold">The Plan:</h1>
            <h3 className="italic">
              Patient {appData?.patient?.patientId}-
              {appData.patient?.isChild ? 'C' : 'A'}
            </h3>
          </div>
          <CopyButton exportText={exportText} />
          <PrintButton />
        </div>
        <div className="flex w-full flex-1 flex-col gap-4 rounded-t-xl bg-white p-8 pb-40 shadow-xl shadow-slate-500 print:gap-2 print:bg-transparent print:shadow-none">
          <h2 className="p-4 text-3xl font-semibold print:p-0 print:text-xl">
            Regaining Control of Symptoms
          </h2>
          <PlanHeading>Sit</PlanHeading>
          <PlanHeading>Change Thoughts</PlanHeading>
          <ChangeThoughts
            thePlan={thePlan}
            setThePlan={setThePlan}
            calmThoughts={data?.calmThoughts!}
            controlThoughts={data?.controlThoughts!}
          />
          <PlanHeading>Retrain the Symptoms</PlanHeading>
          <RetrainTheSymptoms
            thePlan={thePlan}
            setThePlan={setThePlan}
            symptoms={data?.symptoms!}
            responses={data?.responses!}
          />
          <PlanHeading>Breathe</PlanHeading>
        </div>
      </main>
    );
  }
}
