import { useMutation, useQuery } from '@tanstack/react-query';
import { Module } from '../Types/module';
import { getModulesByIds } from '../Utils/queries';
import { PortableText, PortableTextComponents } from '@portabletext/react';
import { AppData, useAppContext } from '../Context/AppContext';
import { PortableTextBlock } from '@portabletext/types';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from './Accordion';
import { Clipboard } from 'react-feather';
import Loading from './Loading';
import Button from './Button';
import { addCompletedModules, removeCompletedModule } from '../Utils/mutations';
import { useAuth0 } from '@auth0/auth0-react';

export default function ModuleDisplay() {
  const { appData, setStateAndLocalStorage } = useAppContext();
  const { isAuthenticated, isLoading: isAuthLoading, user } = useAuth0();

  let isAdmin = false;

  if (!isAuthLoading && isAuthenticated) {
    isAdmin =
      user?.['https://app.reactfnd.health/roles']?.includes('admin') ?? false;
  }

  const moduleIds = appData?.session?.modules ?? [];
  const isContinuous =
    appData?.patient?.sessions?.flatMap((s) => s.continuousSymptoms).length! >
      0 &&
    appData?.patient?.sessions?.flatMap((s) => s.episodicSymptoms).length! ===
      0;

  const isEpisodic =
    appData?.patient?.sessions?.flatMap((s) => s.episodicSymptoms).length! >
      0 &&
    appData?.patient?.sessions?.flatMap((s) => s.continuousSymptoms).length! ===
      0;

  const isBoth =
    appData?.patient?.sessions?.flatMap((s) => s.episodicSymptoms).length! >
      0 &&
    appData?.patient?.sessions?.flatMap((s) => s.continuousSymptoms).length! >
      0;

  // Special Cases: Follow Up Modules
  // Motivator Module (103) & Exposure Module (101) &
  // Motivator Module for Booster (126) & Exposure Module for Booster (121)
  // Always show the Review version of these Modules in the session after each is marked as completed
  const followUpModules = [101, 103, 126, 121];

  followUpModules.forEach((moduleId) => {
    if (
      appData?.patient?.modulesCompleted?.find(
        (m) =>
          m.module === moduleId &&
          m.session === appData?.session?.sessionNumber! - 1
      ) !== undefined &&
      !moduleIds.includes(moduleId)
    ) {
      moduleIds.push(moduleId);
    }
  });

  // Special Case: Review Plan (102) & Review Plan for Patients With No Current Symptoms (111)
  // If the patient is assigned the Review Plan (102), if they have NO current symptoms,
  // replace the Review Plan (102) with the Review Plan for Patients With No Current Symptoms (111)
  const reviewModule = 102;
  const reviewModuleNoSymptoms = 111;
  if (
    moduleIds?.includes(reviewModule) &&
    appData?.session?.continuousSymptoms.length === 0 &&
    appData?.session?.episodicSymptoms.length === 0
  ) {
    moduleIds.splice(moduleIds.indexOf(reviewModule), 1);
    moduleIds.push(reviewModuleNoSymptoms);
  }

  // Special Case: Review Plan with Make Your Own Plan (114)
  // If the patient is assigned the Review Plan with Make Your Own Plan Module (114), check to see
  // if they have previously completed it, and if so, replace it with the Review Plan Module (102)
  // If they have both, remove the Review Plan (102)
  // Unless they have Review Plan for Patients With No Current Symptoms (111),
  // in which case, add or remove (111) instead of (102)
  const reviewPlanWithMYOPModule = 114;
  const reviewPlanModule = moduleIds.includes(111) ? 111 : 102;
  if (moduleIds.includes(reviewPlanWithMYOPModule)) {
    if (
      appData?.patient?.modulesCompleted?.find(
        (m) =>
          m.module === reviewPlanWithMYOPModule &&
          m.session < (appData?.session?.sessionNumber || 0)
      )
    ) {
      moduleIds.splice(moduleIds.indexOf(reviewPlanWithMYOPModule), 1);
      moduleIds.push(reviewPlanModule);
    } else {
      moduleIds.splice(moduleIds.indexOf(reviewPlanModule), 1);
    }
  }

  // Special Case: Patient has Review Plan for Patients With No Current Symptoms (111) assigned
  // AND this is NOT a booster session
  // AND does NOT have School Plan Module (104)
  // AND has not completed AA2 in the current session
  // AND If the patient has NOT completed the Review Plan with Make Your Own Plan (114)
  // AND has not completed the How to Create Your Own Plan (105)
  // ADD the How to Create Your Own Plan (105)
  if (
    moduleIds.includes(111) &&
    !appData?.patient?.boosterStart &&
    !moduleIds.includes(104) &&
    !moduleIds.includes(105) &&
    !appData?.session?.avoidanceAssessmentResponses?.find(
      (r) => r.instance === 'AA2'
    )
  ) {
    if (
      appData?.patient?.modulesCompleted?.find(
        (m) =>
          m.module === 114 && m.session < (appData?.session?.sessionNumber || 0)
      ) === undefined &&
      appData?.patient?.modulesCompleted?.find(
        (m) =>
          m.module === 105 && m.session < (appData?.session?.sessionNumber || 0)
      ) === undefined
    ) {
      moduleIds.push(105);
    }
  }

  // Special Case: Chain Generalization Modules (100, 108, 109) together upon completion
  // IF a (100) was completed in the previous session, add 108 (try to remove 100)
  if (
    appData?.patient?.modulesCompleted?.find(
      (m) =>
        m.module === 100 && m.session === appData?.session?.sessionNumber! - 1
    ) &&
    !moduleIds.includes(108)
  ) {
    moduleIds.push(108);
    if (moduleIds.indexOf(100) !== -1) {
      moduleIds.splice(moduleIds.indexOf(100), 1);
    }
  }
  // IF a (108) was completed in the previous session, add 109 (try to remove 100, 108)
  if (
    appData?.patient?.modulesCompleted?.find(
      (m) =>
        m.module === 108 && m.session === appData?.session?.sessionNumber! - 1
    ) &&
    !moduleIds.includes(109)
  ) {
    moduleIds.push(109);
    if (moduleIds.indexOf(100) !== -1) {
      moduleIds.splice(moduleIds.indexOf(100), 1);
    }
    if (moduleIds.indexOf(108) !== -1) {
      moduleIds.splice(moduleIds.indexOf(108), 1);
    }
  }
  // IF a (109) was completed in the previous session, add 109 again! (try to remove 100, 108)
  if (
    appData?.patient?.modulesCompleted?.find(
      (m) =>
        m.module === 109 && m.session === appData?.session?.sessionNumber! - 1
    ) &&
    !moduleIds.includes(109)
  ) {
    moduleIds.push(109);
    if (moduleIds.indexOf(100) !== -1) {
      moduleIds.splice(moduleIds.indexOf(100), 1);
    }
    if (moduleIds.indexOf(108) !== -1) {
      moduleIds.splice(moduleIds.indexOf(108), 1);
    }
  }

  // Special Case: Keep track of progress to assign proper Generalization Module (100, 108, 109)
  // IF assigned a (100) in the current session,
  // check IF (109) was ever completed, assign/swap to (109),
  // ELSE IF (108) was ever completed, assign/swap to (109),
  // ELSE IF (100) was ever completed, assign/swap to (108),
  // ELSE Leave it be as (100)!
  if (moduleIds.includes(100)) {
    if (
      appData?.patient?.modulesCompleted?.find(
        (m) => m.module === 109 && m.session < appData.session?.sessionNumber!
      )
    ) {
      moduleIds.push(109);
      moduleIds.splice(moduleIds.indexOf(100), 1);
    } else if (
      appData?.patient?.modulesCompleted?.find(
        (m) => m.module === 108 && m.session < appData.session?.sessionNumber!
      )
    ) {
      moduleIds.push(109);
      moduleIds.splice(moduleIds.indexOf(100), 1);
    } else if (
      appData?.patient?.modulesCompleted?.find(
        (m) => m.module === 100 && m.session < appData.session?.sessionNumber!
      )
    ) {
      moduleIds.push(108);
      moduleIds.splice(moduleIds.indexOf(100), 1);
    }
  }

  // Special Case: ADD End of Session Review (115) to EVERY session EXCEPT the first
  if (!moduleIds.includes(115) && appData?.session?.sessionNumber !== 1) {
    moduleIds.push(115);
  }

  // Special Case: For ONLY THE FIRST booster session:
  // ADD the School Plan for Booster (123),
  // AND REMOVE the End of Session Review (115)
  if (appData?.patient?.boosterStart === appData?.session?.sessionNumber) {
    if (!moduleIds.includes(123)) {
      moduleIds.push(123);
    }
    if (moduleIds.includes(115)) {
      moduleIds.splice(moduleIds.indexOf(115), 1);
    }
  }

  // Special Case: IF you have the Relapse Prevention and Graduation Module (110) assigned,
  // remove ALL OTHER MODULES
  if (moduleIds.includes(110)) {
    moduleIds.splice(0, moduleIds.length);
    moduleIds.push(110);
  }

  const {
    isLoading,
    error,
    data: fullModules,
  } = useQuery({
    queryKey: ['modules', moduleIds],
    queryFn: () => getModulesByIds(moduleIds),
  });

  const navigate = useNavigate();

  const customComponents: PortableTextComponents = {
    block: {
      continuous: ({ children }) => <div>{children}</div>,
      episodic: ({ children }) => <div>{children}</div>,
      both: ({ children }) => <div>{children}</div>,
      h3: (props: any) => (
        <h3 id={`h${props.node._key}`}>
          <a
            href={`#h${props.node._key}`}
            aria-hidden="true"
            tabIndex={-1}
            className="relative no-underline after:pl-1 after:font-bold hover:after:content-['#']"
          >
            {props.children}
          </a>
        </h3>
      ),
    },
  };

  const adminCustomComponents: PortableTextComponents = {
    block: {
      ...(customComponents.block as any),
      continuous: ({ children }) => (
        <div className="bg-green-200">{children}</div>
      ),
      episodic: ({ children }) => <div className="bg-blue-200">{children}</div>,
      both: ({ children }) => <div className="bg-yellow-200">{children}</div>,
    },
  };

  const filterBody = (body: PortableTextBlock[]) => {
    return body.filter((block) => {
      if (block.style === 'continuous' && isContinuous) {
        return true;
      } else if (block.style === 'episodic' && isEpisodic) {
        return true;
      } else if (block.style === 'both' && isBoth) {
        return true;
      } else if (
        ['continuous', 'episodic', 'both'].includes(block.style ?? '')
      ) {
        return false;
      } else {
        return true;
      }
    });
  };

  const addMutation = useMutation({
    mutationFn: (params: { completedModules: number[] }) =>
      addCompletedModules(
        appData?.patient?._id!,
        params.completedModules,
        appData?.session?.sessionNumber!
      ),
  });

  const removeMutation = useMutation({
    mutationFn: (params: { uncompletedModule: number }) =>
      removeCompletedModule(
        appData?.patient?._id!,
        params.uncompletedModule,
        appData?.session?.sessionNumber!
      ),
  });

  const handleToggleCompleted = (key: number, isCompleted: boolean) => {
    const completedModules = appData?.patient?.modulesCompleted ?? [];
    if (isCompleted) {
      const index = completedModules.findIndex(
        (m) => m.module === key && m.session === appData?.session?.sessionNumber
      );
      completedModules.splice(index, 1);
      removeMutation.mutate({ uncompletedModule: key });
    } else {
      completedModules.push({
        module: key,
        session: appData?.session?.sessionNumber!,
      });
      addMutation.mutate({ completedModules: [key] });
    }
    setStateAndLocalStorage({
      ...appData,
      patient: {
        ...appData?.patient,
        modulesCompleted: completedModules,
      },
    } as AppData);
  };

  const handleNavigateToDashboard = () => {
    // if some of the current modules are not completed, confirm that the user wants to leave
    const incompleteModules = fullModules?.filter(
      (m) =>
        appData?.patient?.modulesCompleted?.find(
          (c) =>
            c.module === m.key && c.session === appData?.session?.sessionNumber
        ) === undefined
    );
    if (incompleteModules && incompleteModules.length > 0) {
      if (
        window.confirm(
          `You have not completed all of the modules for this session. Are you sure you want to leave?`
        )
      ) {
        navigate('/dashboard');
      }
      return;
    } else {
      navigate('/dashboard');
    }
  };

  if (isLoading) {
    return <Loading />;
  }

  if (error instanceof Error) {
    return <span>Error: {error.message}</span>;
  }

  return (
    <div className="z-0 flex w-full min-w-[400px] flex-col gap-4 bg-white shadow-xl shadow-slate-500">
      <div className="flex items-center gap-2 px-8 py-4">
        <div className="flex h-10 w-10 items-center justify-center rounded-full bg-salmon">
          <Clipboard />
        </div>
        <h2 className="text-xl font-semibold">Modules</h2>
        <div className="ml-auto mr-5 text-grey">Complete?</div>
      </div>
      {fullModules && fullModules.length > 0 ? (
        <Accordion
          type="single"
          collapsible
          defaultValue={
            fullModules[0] ? `item-${fullModules[0]._id}` : undefined
          }
          className="flex flex-1 flex-col gap-4"
        >
          {fullModules?.map((module: Module) => {
            const isReview =
              (module.bodyReview ?? []).length > 0 &&
              appData?.patient?.modulesCompleted?.find(
                (m) =>
                  m.module === module.key &&
                  m.session < appData?.session?.sessionNumber!
              ) !== undefined;
            const isChild =
              appData?.patient?.isChild === 'false'
                ? false
                : Boolean(appData?.patient?.isChild);
            const filteredBody = filterBody(
              isReview
                ? isChild
                  ? module.bodyReview ?? []
                  : module.bodyReviewAdult ?? module.bodyReview ?? []
                : isChild
                ? module.body ?? []
                : module.bodyAdult ?? module.body ?? []
            );
            const isCompleted =
              appData?.patient?.modulesCompleted?.find(
                (m) =>
                  m.module === module.key &&
                  m.session === appData?.session?.sessionNumber
              ) !== undefined;
            return (
              <AccordionItem
                key={module._id}
                className="flex flex-col data-[state='open']:flex-1 data-[state='open']:grow-0"
                value={`item-${module._id}`}
              >
                <AccordionTrigger className="flex items-center gap-4 px-8 py-4 text-dark-blue">
                  <div className="flex flex-col">
                    <h3
                      className="text-2xl font-semibold"
                      title={module.key.toString()}
                    >
                      {isChild
                        ? module.title
                        : module.titleAdult ?? module.title}
                    </h3>
                    {module.subtitle && (
                      <h4 className="text-lg">{module.subtitle}</h4>
                    )}
                  </div>
                  {isReview && (
                    <div className="w-fit rounded bg-red px-2 py-1 text-sm font-semibold uppercase text-white">
                      <span>Review</span>
                    </div>
                  )}
                  <label className="ml-auto flex gap-4">
                    <input
                      type="checkbox"
                      name={module.key.toString()}
                      onChange={() => {
                        handleToggleCompleted(module.key, isCompleted);
                      }}
                      onClick={(e) => e.stopPropagation()}
                      checked={isCompleted}
                    />
                  </label>
                </AccordionTrigger>
                <AccordionContent className="AccordionContent max-h-[50vh] overflow-auto bg-gradient-to-br from-light/[0.31] to-salmon/[0.08] px-8 py-4 shadow-inner">
                  {module.directions && <p>{module.directions}</p>}
                  {filteredBody && (
                    <div className="portable-text !leading-tight">
                      <PortableText
                        value={filteredBody}
                        components={
                          isAdmin ? adminCustomComponents : customComponents
                        }
                      />
                    </div>
                  )}
                  {module.attachments && (
                    <div className="rounded-2xl border-4 border-salmon bg-lighter-salmon p-8 text-lg">
                      <h4 className="font-figtree text-2xl font-semibold">
                        Attachments
                      </h4>
                      <ul className="mt-4 list-inside list-disc">
                        {module.attachments.map((attachment) => (
                          <li key={attachment.originalFilename}>
                            <a
                              href={`${attachment.url}?dl=${attachment.originalFilename}`}
                              className="hover:underline"
                            >
                              {attachment.originalFilename}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  )}
                </AccordionContent>
              </AccordionItem>
            );
          })}
        </Accordion>
      ) : (
        <div className="py-4 text-center italic">
          No modules found for this session.
        </div>
      )}
      <div className="flex justify-center py-20">
        <Button
          type="button"
          variant="dark"
          size="lg"
          onClick={handleNavigateToDashboard}
        >
          Back to Dashboard
        </Button>
      </div>
    </div>
  );
}
