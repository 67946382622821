import { ChevronDown, LogOut, Menu } from 'react-feather';
import { useAuth0 } from '@auth0/auth0-react';
import { useAppContext } from '../Context/AppContext';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from './DropdownMenu';
import { Link, NavLink } from 'react-router-dom';
import Loading from './Loading';
import SvgLogo from './SvgLogo';

const navLinks = [
  { name: 'Home', path: '/' },
  { name: 'Dashboard', path: '/dashboard' },
  { name: 'Menu', path: '/menu', admin: true },
];

const HeaderSignOut = () => {
  const { user, isAuthenticated, isLoading, logout } = useAuth0();
  const { setStateAndLocalStorage } = useAppContext();

  if (isLoading) {
    return <Loading span />;
  }

  if (isAuthenticated) {
    return (
      <div className="flex items-center gap-2">
        <div>Welcome back {user?.name}</div>
        <DropdownMenu>
          <DropdownMenuTrigger className="rounded bg-[#EFEEED] p-0.5 hover:bg-[#C1C7CE]">
            <ChevronDown size={16} />
          </DropdownMenuTrigger>
          <DropdownMenuContent className="bg-white">
            <DropdownMenuItem
              onClick={() => {
                setStateAndLocalStorage(null);
                logout({ logoutParams: { returnTo: window.location.origin } });
              }}
            >
              <LogOut className="mr-2 h-4 w-4" />
              <span>Log Out</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  } else {
    return <div>Not authenticated</div>;
  }
};

export default function Header() {
  const { isAuthenticated, isLoading, user } = useAuth0();

  const navLinkClasses = ({
    isActive,
    isPending,
  }: {
    isActive: boolean;
    isPending: boolean;
  }) => `${isPending ? 'pending' : isActive ? 'font-semibold' : ''}`;

  return (
    <header className="flex items-center gap-8 border-b border-[#EBECF1] py-4 font-figtree text-xl font-semibold text-dark-blue">
      <DropdownMenu>
        <DropdownMenuTrigger className="rounded-2xl bg-[#EFEEED] px-4 py-3 hover:bg-[#C1C7CE] print:hidden">
          <Menu size={26} />
        </DropdownMenuTrigger>
        <DropdownMenuContent className="bg-white">
          {navLinks.map((link) => {
            if (link?.admin === true) {
              if (
                user?.['https://app.reactfnd.health/roles']?.includes('admin')
              ) {
                return (
                  <DropdownMenuItem key={link.path}>
                    <NavLink to={link.path} className={navLinkClasses}>
                      {link.name}
                    </NavLink>
                  </DropdownMenuItem>
                );
              } else {
                return false;
              }
            } else {
              return (
                <DropdownMenuItem key={link.path}>
                  <NavLink to={link.path} className={navLinkClasses}>
                    {link.name}
                  </NavLink>
                </DropdownMenuItem>
              );
            }
          })}
        </DropdownMenuContent>
      </DropdownMenu>
      <Link to={isAuthenticated ? '/dashboard' : '/'}>
        <div className="flex items-center gap-4">
          <div className="h-16 w-64">
            <SvgLogo />
          </div>
        </div>
      </Link>
      <div className="ml-auto print:hidden">
        {!isLoading && <>{isAuthenticated && <HeaderSignOut />}</>}
      </div>
    </header>
  );
}
