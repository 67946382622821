import { useNavigate, useParams } from 'react-router-dom';
import ChoiceDisplay from '../Components/ChoiceDisplay';
import { getChoicesByQuestion, getQuestion } from '../Utils/queries';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Choice } from '../Types/choice';
import ChoiceDisplayDebug from '../Components/ChoiceDisplayDebug';
import { AppData, useAppContext } from '../Context/AppContext';
import NumberForm from '../Components/NumberForm';
import { Calendar } from 'react-feather';
import { getWeekNumber } from '../Utils/helpers';
import MultiPartQuestion from '../Components/MultiPartQuestion';
import NoSessionSet from '../Components/NoSessionSet';
import Loading from '../Components/Loading';
import { addModules } from '../Utils/mutations';
import { useState } from 'react';

export default function QuestionPage() {
  const { questionId } = useParams<{ questionId: string }>();
  const { appData, setStateAndLocalStorage } = useAppContext();
  const navigate = useNavigate();
  const [hasSpecialCaseRun, setHasSpecialCaseRun] = useState(false);

  const moduleMutation = useMutation({
    mutationFn: () => addModules(appData!.session!._id, [102, 100]),
    onSuccess: async () => {
      console.log('mutation success');
      await setStateAndLocalStorage({
        ...appData,
        session: {
          ...appData?.session,
          modules: [...(appData?.session?.modules! ?? []), 102, 100],
        },
      } as AppData);
      await navigate('/module-review');
    },
  });

  // Special Case: Special Checks for Question 13 / AA3 Trigger
  // IF this is Question 13 AND the patient has never had an AA Answer for AA3
  // (IF the patient has completed Generalization Module 3 (109)
  // redirect them to 'AA3'
  // ELSE add modules (102, 100) redirect them to 'module-review')
  if (
    !hasSpecialCaseRun &&
    questionId === '13' &&
    !appData?.patient?.sessions
      .flatMap((s) => s?.avoidanceAssessmentResponses ?? [])
      .find((r) => r?.instance === 'AA3') &&
    !appData?.session?.avoidanceAssessmentResponses?.find(
      (r) => r?.instance === 'AA3'
    )
  ) {
    if (
      appData?.patient?.modulesCompleted?.find(
        (m) => m.module === 109 && m.session < appData.session?.sessionNumber!
      )
    ) {
      navigate('/avoidance-assessment/AA3');
    } else {
      console.log('About to mutate');
      // mutate the data then setStateAndLocalStorage, then navigate to Module Review
      moduleMutation.mutate();
      setHasSpecialCaseRun(true);
    }
  }

  const {
    isLoading: questionIsLoading,
    error: questionError,
    data: question,
  } = useQuery({
    queryKey: ['question', questionId],
    queryFn: () => getQuestion(questionId!),
  });

  const {
    isLoading: choicesIsLoading,
    error: choicesError,
    data: choices,
  } = useQuery({
    queryKey: ['choices', questionId],
    queryFn: () => getChoicesByQuestion(questionId!),
  });

  if (questionIsLoading || choicesIsLoading) {
    return <Loading />;
  }

  if (questionError instanceof Error) {
    return <span>Error: {questionError.message}</span>;
  }

  if (choicesError instanceof Error) {
    return <span>Error: {choicesError.message}</span>;
  }

  if (!appData?.session) {
    return <NoSessionSet />;
  } else {
    const d = getWeekNumber(new Date(appData?.session?.sessionDate!));

    return (
      <main className="flex w-full flex-col gap-8 px-4 font-figtree text-dark-blue">
        <div className="flex w-full items-center justify-between gap-4 rounded-2xl bg-white p-6 shadow-xl">
          <h1 className="text-2xl font-semibold">
            Session {appData?.session?.sessionNumber} &bull; Patient{' '}
            {appData?.patient?.patientId}-{appData.patient?.isChild ? 'C' : 'A'}
          </h1>
          <div className="flex items-center gap-2 text-sm text-grey">
            <Calendar size={18} />
            {d[0]}, week {d[1]}
          </div>
        </div>

        <div className="flex flex-col gap-8 rounded-xl bg-white p-8 shadow-xl shadow-slate-500">
          <div className="flex w-full flex-col items-center gap-4 rounded-lg bg-lighter-salmon bg-opacity-50 px-12 py-6 text-center">
            {question && ['Yes/No', 'Number'].includes(question?.type) && (
              <h3 className="text-lg font-bold">
                Question {appData.debug && question?.key}
              </h3>
            )}
            <h2 className="text-balance text-2xl font-bold [text-wrap:balance]">
              {appData?.patient?.isChild
                ? question?.question
                : question?.questionAdult ?? question?.question}
            </h2>
          </div>

          {question && question?.type === 'Yes/No' && (
            <div className="mx-auto flex flex-wrap justify-center gap-4">
              {choices?.map((choice: Choice) => (
                <ChoiceDisplay
                  key={choice._id}
                  choice={choice}
                  question={question}
                />
              ))}
            </div>
          )}

          {question && question?.type === 'Number' && (
            <div className="mx-auto flex flex-wrap justify-center gap-4">
              <NumberForm
                questionId={question.key}
                nextStepType={choices![0].nextStepType}
                nextStep={choices![0].nextStep}
              />
            </div>
          )}

          {question && question?.type === 'Multiple' && (
            <MultiPartQuestion question={question} choices={choices ?? []} />
          )}

          {question && appData?.debug && (
            <div className="mx-auto flex flex-wrap gap-4">
              {choices?.map((choice: Choice) => (
                <ChoiceDisplayDebug
                  key={choice._id}
                  choice={choice}
                  question={question}
                />
              ))}
            </div>
          )}
        </div>
      </main>
    );
  }
}
